import { MAP, DATA, CNL } from '../../App.config'
import axios from 'axios'
import { setFilteredData } from '../Reducers/filterReducer'
import store from '../store'

const downloadReport = async (startDate, endDate) => {   
    try {
        const newWindow = window.open(`${MAP.DOWNLOAD_RETAIL_REPORT}?dateFrom=${startDate}&dateTill=${endDate}`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null

    } catch (err) {
        console.error(err)
    }

}

// update data of a point
const updatePointData = async (uCode, params) => {

    return axios.post(DATA.UPDATE_POINT_DATE + uCode, params)
    .then(res => {
        return res
    })
    .catch (error => {
        console.error(error);
    })

}

// update data of a point
const updatePointDataRetail = async (uCode, params) => {

    return axios.post(DATA.UPDATE_POINT_DATA_RETAIL + uCode, params)
    .then(res => {
        return res
    })
    .catch (error => {
        console.error(error);
    })

}

// update data of a point CNL
const updatePointDataCnl = async (params) => {
    return axios.post(CNL.UPDATE_POINT_DATA, params)
    .then(res => {
        return res
    })
    .catch (error => {
        console.error(error);
    })
}

// fetch data for plotting map points
const fetchData = async (district, dateRange) => {
    const startDate = dateRange?.startDate
    const endDate = dateRange?.endDate
    let validatedDistrict = district
    const stringValidation = district?.includes("'")
    if(stringValidation) {
        validatedDistrict = district?.replace("'", "\\'")
    }

    return await axios.get(`${DATA.GET_PLACE_PLOTTING_DATA}${district ? '?dateFrom='+startDate+'&dateTill='+endDate+'&district='+ validatedDistrict : '?dateFrom='+startDate+'&dateTill='+endDate }`)
        .then(results => {
            if(results?.status === 200){
                return results?.data?.data ? results.data.data : {}
            }
            return []
        })
        .catch(err => { console.error(err) })
}


// fetch data for plotting map points
const fetchRetailData = async (district, dateRange) => {
    const startDate = dateRange?.startDate
    const endDate = dateRange?.endDate
    let validatedDistrict = district
    const stringValidation = district?.includes("'")
    if(stringValidation) {
        validatedDistrict = district?.replace("'", "\\'")
    }

    return await axios.get(`${DATA.GET_RETAIL_PLACE_PLOTTING_DATA}${district ? '?dateFrom='+startDate+'&dateTill='+endDate+'&district='+ validatedDistrict : '?dateFrom='+startDate+'&dateTill='+endDate }`)
        .then(results => {
            if(results?.status === 200){
                return results?.data?.data ? results.data.data : {}
            }
            return []
        })
        .catch(err => { console.error(err) })
}

// fetch data for plotting map points
const fetchCnlData = async (dateRange) => {
    const startDate = `${dateRange?.startDate}`
    const endDate = `${dateRange?.endDate}`
    const startDateType = store.getState().cnl?.startDateType

    // ?start_date=2023-02-06 00:00:00&end_date=2023-02-06 23:59:59 &include_filter_by_created_at='+startDateType
    let url = `${DATA.GET_CNL_DATA}${'?start_date='+startDate+'&end_date='+endDate+'&include_filter_by_created_at='+startDateType}`;
    return await axios.get(url)
        .then(results => {
            if(results?.status === 200){
                return results?.data?.outlets ? results.data.outlets : {}
            }
            return []
        })
        .catch(err => { console.error(err) })
}





// fetch data for plotting map points
const fetchRetailFilteredData = async (param) => {
    let startDate = param?.startDate
    let endDate = param?.endDate
    let territoryName = param?.territory_name ?? ''
    let routeName = param?.route_name ?? ''
    let marketName = param?.market_name ?? ''

    let url = `${DATA.GET_RETAIL_FILTERRED_DATA}${'?dateFrom='+startDate+'&dateTill='+endDate+'&territory_name='+ territoryName+'&route_name='+routeName+'&market_name='+marketName}`


    return await axios.get(url)
    .then(results => {
        if(results?.status === 200){
            return results?.data?.data ? results.data.data : {}
        }
        return []
    })
    .catch(err => { console.error(err) })
}

// fetch data for plotting map points
const fetchCnlFilteredData = async (param) => {
    let startDate = param?.start_date
    let endDate = param?.end_date
    let territoryId = param?.territory_id ?? ''
    let routeId = param?.route_id ?? ''
    let outletCategory = param?.outlet_category ?? ''
    let startDateType = param?.include_filter_by_created_at ?? ''

    let url = `${DATA.GET_CNL_DATA}${'?start_date='+startDate+'&end_date='+endDate+'&territory_id='+ territoryId+'&route_id='+routeId+'&outlet_category='+outletCategory+'&include_filter_by_created_at='+startDateType}`


    return await axios.get(url)
    .then(results => {
        console.log("results", results);
        if(results?.status === 200){
            return results?.data ? results.data : {}
        }
        return []
    })
    .catch(err => { console.error(err) })
}

// delete image
const  deleteImage = async (id) => {
    return await axios.post(`${DATA.DELETE_IMAGE}${id}`)
        .then(results => {
            if(results?.status === 200){
                return results?.message ? results.message : {}
            }
            return "Failed"
        })
        .catch(err => { console.error(err) })
}

// transform socket and loaded data in list form where loaded data is on top 
const stackLoadedAndSocketData = (loadedPoints, socketDataPoints, selectedDistrict, selectedMapperID, renderSinglePoint) => {

    let stackMessageData = []
    let stackMapData = []

    // if mapper is selected and we want to plot all map data. - usually this is for mapper panel
    if(selectedMapperID && !renderSinglePoint){
        // creating message list
        stackMessageData.push({ Message: null, uCode: null })
        loadedPoints?.forEach(item => {
            if(item?.mapData?.user_id === selectedMapperID){
                stackMessageData.push({ Message: item?.message, uCode: item?.mapData?.uCode ?? item?.mapData?.id  })
                stackMapData.push(item?.mapData )
            }
        })
        return { messageData: stackMessageData, mapData: stackMapData }
    }

    // renderng only one data. eg. by searching uCode from mapper panel or by clicking the socket task list - usually this is for mapper panel
    if(renderSinglePoint){
        socketDataPoints.forEach(item => {
            stackMessageData.push({ Message: item?.message, uCode: item?.mapData?.uCode ?? item?.mapData?.id })
            stackMapData.push(item?.mapData )
        })
        stackMessageData.push({ Message: null, uCode: null })
        loadedPoints?.forEach(item => {
            stackMessageData.push({ Message: item?.message, uCode: item?.mapData?.uCode ?? item?.mapData?.id })
            stackMapData.push(item?.mapData )
           
        })
        return { messageData: stackMessageData, mapData: stackMapData }
    }

    // if district is selected - usually this is for loader panel
    if(selectedDistrict && selectedDistrict !== 'All'){
        socketDataPoints?.forEach(item => {
            if(item?.mapData?.district?.toLowerCase() === selectedDistrict?.toLowerCase()){
                stackMessageData.push({ Message: item?.message,  uCode: item?.mapData?.uCode ?? item?.mapData?.id })
                stackMapData.push(item?.mapData )
            }
        })
        stackMessageData.push({ Message: null, uCode: null })
        loadedPoints?.forEach(item => {
            if(item?.mapData?.district?.toLowerCase() === selectedDistrict?.toLowerCase()){
                stackMessageData.push({ Message: item?.message,uCode: item?.mapData?.uCode ?? item?.mapData?.id  })
                stackMapData.push(item?.mapData )
            }
        })
        return { messageData: stackMessageData, mapData: stackMapData }
    }

    // if no filter is used 
    if(loadedPoints || socketDataPoints){
        socketDataPoints.forEach(item => {
            stackMessageData.push({ Message: item?.message, uCode: item?.mapData?.uCode ?? item?.mapData?.id  })
            stackMapData.push(item?.mapData )
            
        })
        stackMessageData.push({ Message: null, uCode: null })
        loadedPoints.forEach(item => {
            stackMessageData.push({ Message: item?.message, uCode: item?.mapData?.uCode ?? item?.mapData?.id  })
            stackMapData.push(item?.mapData )
        })

        return { messageData: stackMessageData, mapData: stackMapData }
    }
    return { messageData: stackMessageData, mapData: stackMapData }
}


// merging socket map data point with API loaded map data point
// const mergeAllPoints = (apiLoadedData, socketData, district) =>{
//     const mergedData = [
//         ...apiLoadedData,
//         ...socketData
//     ]
    
//     let dataToReturn = mergedData
    
//     if(district) {
//         dataToReturn = mergedData?.filter(item => item?.district === district)
//     }


//     return dataToReturn
// }




export { 
        downloadReport, 
        updatePointData, 
        updatePointDataRetail,
        updatePointDataCnl, 
        stackLoadedAndSocketData, 
        fetchData, 
        fetchRetailData, 
        fetchRetailFilteredData, 
        fetchCnlFilteredData,
        deleteImage,
        fetchCnlData
    }