import axios from 'axios'


// BASE API'S
import {BASE_API_URL} from '../App.config'
import {BASE_API, MAPPER, DATA} from '../App.config'

// importing reducers
import { setDataPlotter } from '../Redux/Reducers/mapperReducer'



// const BASE_API_URL = 'https://retail.bmapsbd.com/api'



// get place by uCode
export const fetchPlaceByUcode = async (uCode) => {
    try{ 
        // const results = await axios.get(BASE_API_URL+`/api/admin/get/retailplace/by/${uCode}`);
        const results = await axios.get(`${DATA.GET_PLACE_DATA}/${uCode}`);

        return results?.data ? results?.data : {}
    }
    catch(err){
        throw(err)
    }
}

// get retail place by uCode
export const fetchRetailPlaceByUcode = async (uCode) => {
    try{ 
        // const results = await axios.get(BASE_API_URL+`/api/admin/get/retailplace/by/${uCode}`);
        const results = await axios.get(`${DATA.GET_SINGLE_RETAIL_PLACE_DATA}/${uCode}`);

        return results?.data ? results?.data : {}
    }
    catch(err){
        throw(err)
    }
}

// get cnl place by uCode
export const fetchCnlPlaceByUcode = async (uCode) => {
    try{ 
        // const results = await axios.get(BASE_API_URL+`/api/admin/get/retailplace/by/${uCode}`);
        // {BASE_URL}/api/v1/outlets?outlet_id=1
        let url = `${DATA.GET_CNL_DATA}?outlet_id=${uCode}`
        //  single api call
        const results = await axios.get(url);
        return results?.data?.outlets[0] ? results?.data?.outlets[0] : {}
    }
    catch(err){
        throw(err)
    }
}


// fetch user/mapper info by ID
export const fetchUserByID = (userID) => {
    return dispatch => {

        const staticTempToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmJtYXBzYmQuY29tXC9hZG1pblwvbG9naW4iLCJpYXQiOjE2NTY1MDI5NDQsImV4cCI6NDI0ODUwMjk0NCwibmJmIjoxNjU2NTAyOTQ0LCJqdGkiOiJsZDRNeTB4aDNBTllZRkF6Iiwic3ViIjoxNjI0LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.zHNY4S6EfRcGq48cCS9lTXOeAIi0v1BXUJbrlaLuiIc'
        
        axios.get(MAPPER.FETCH_INDIVUDUAL_MAPPER_BY_ID + userID, { headers: { Authorization: `Bearer ${ staticTempToken }` } })
        .then(res => {
            if(res?.data?.data.length > 0){
                // return res.data.data[0]
                dispatch(setDataPlotter(res.data.data[0]))
            }
        })
        .catch(err => {
            console.error(err)
        })
    }

}

// All Mapper Info
export const fetchAllUserData = async () => {
    try{
        const results = await axios.get(BASE_API_URL+`/api/get/userlist`);
       
        return results.data.userlist ? results.data.userlist : null
    }
    catch(err){
        throw(err)
    }
}

// Allow-Restrict Mapper access
export const updateMapperAccessStatus = async (mapperID, accessType) => {
    try{
        
        await axios.post(BASE_API_URL+`/api/allow/disallow/mapping?mapper_id=${mapperID}&type=${accessType}`);
       
        // return results.data.userlist ? results.data.userlist : {}
    }
    catch(err){
        throw(err)
    }
}

// fetch data for individual mapper --- all data 
export const fetchIndividualUserPlace = async (uID, params) => {
    try{
        const { dateFrom, dateTill } = params
        const results = await axios.get(`${MAPPER.INDIVIDUAL_DATA}?uid=${uID}&dateFrom=${dateFrom}&dateTill=${dateTill}`)

        return results?.data?.data ? results.data.data : []
    }
    catch(err){
        throw(err)
    }
} 

// fetch data for individual mapper --- retail data only 
export const fetchIndividualUserRetailPlace = async (uID, params) => {
    try{
        const { dateFrom, dateTill } = params
        const results = await axios.get(`${MAPPER.INDIVIDUAL_RETAIL_DATA}?uid=${uID}&dateFrom=${dateFrom}&dateTill=${dateTill}`)

        return results?.data?.data ? results.data.data : []
    }
    catch(err){
        throw(err)
    }
} 

// Fetching Place Type
export const fetchPtype = async (uID) => {
    try{
        const results = await axios.get(`${BASE_API}/admin/place/get/type/`)
       
        return results.data ? results.data : {}
    }
    catch(err){
        throw(err)
    }
} 

// Fetching Sub Type
export const fetchSubType = async () => {
    try{
        const results = await axios.get(DATA.GET_ALL_SUBTYPE)
      
        return results?.data ? results.data : {}
    }
    catch(err){
        throw(err)
    }
} 


// Fetching all area
export const fetchAllArea = async () => {
    try{
        
        const results = await axios.get(`${BASE_API}/api/area`)
    
        return results.data ? results.data : {}
    }
    catch(err){
        throw(err)
    }
} 


// Fetching all Created Task
export const fetchAllTask = async () => {
    try{
        
        const results = await axios.get(`${ BASE_API }/get/data/tasks`)    

        return results.data ? results.data : {}
    }
    catch(err){
        throw(err)
    }
} 


// Fetching Task by ID
export const fetchTaskByID = async (taskID) => {
    try{
        const results = await axios.get(BASE_API +`/get/data/task/${taskID}`)

        return results.data.Tasks[0] ? results.data.Tasks[0] : {}
    }
    catch(err){
        throw(err)
    }
} 


