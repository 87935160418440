import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateLogData: null,
    updateLogStartDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`, // for loader
    updateLogEndDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`, // for loader
    selectedMapper: '',
}


const updateLogSlice = createSlice({
    name: 'updateLog',
    initialState,
    reducers: {
        setSelectedMapper: (state, action) => {
            state.selectedMapper = action.payload;
        },
        setUpdateLogStartDate: (state, action) => {
            state.updateLogStartDate = action.payload;
        },
        setUpdateLogEndDate: (state, action) => {
            state.updateLogEndDate = action.payload;
        },
        setUpdateLogData: (state, action) => {
            state.updateLogData = action.payload;
        },
    }
})

export const { 
    setUpdateLogStartDate,
    setUpdateLogEndtDate,
    setUpdateLogData
} = updateLogSlice.actions
export default updateLogSlice.reducer