import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeckGL from '@deck.gl/react';
import { StaticMap, MapContext } from 'react-map-gl';
import { Box, Container, Divider, Paper } from '@mui/material';
import { Typography } from '@mui/material';

import {
  Editor,
  EditingMode,
  // DrawLineStringMode,
  // DrawCircleByDiameterMode,
  // DrawRectangleMode,
  DrawPolygonMode,
} from 'react-map-gl-draw';

// MAP Style
import { MAP_STYLE } from '../App.config';

// Redux
import { connect } from 'react-redux';
import {setTaskCreationCoordinates} from '../Redux/Reducers/mapDataReducer';


const MODES = [
  // { id: 'drawPolyline', text: 'Draw Polyline', handler: DrawLineStringMode },
  { id: 'drawPolygon', text: 'Draw Polygon', handler: DrawPolygonMode },
  // { id: 'drawCircle', text: 'Draw Circle', handler: DrawCircleByDiameterMode },
  // { id: 'drawRectangle', text: 'Draw Rectangle', handler: DrawRectangleMode },
  { id: 'editing', text: 'Edit Feature', handler: EditingMode },
];

const mapStyle= MAP_STYLE.GEOSERVER_STYLE

const DEFAULT_VIEWPORT = {
  width: 800,
  height: 600,
  longitude: -122.45,
  latitude: 37.78,
  zoom: 13,
}

class TaskMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewState: {
        longitude: 90.3938010872331,
        latitude: 23.82707945251465,
        zoom: 14,
        pitch: 0,
        bearing: 0
      },
      viewport: DEFAULT_VIEWPORT,
      modeId: null,
      modeHandler: null,
      layers: [],
      areaCoordinates : [],
      refresh: false,
    }
    // this.setCoordinates = this.setCoordinates.bind(this)
    this.deleteCurrentDrawing = this.deleteCurrentDrawing.bind(this)
  }

  deleteCurrentDrawing(){
    const { dispatch } = this.props

    dispatch(setTaskCreationCoordinates(''));
    this.setState({
      refresh: !this.state.refresh
    })
  }

  _setCoordinates = (property) => {
    const { dispatch } = this.props
    let curentCoordinates = []
    // white creating
    if(property.editContext?.feature?.geometry?.coordinates[0]){
      curentCoordinates = property.editContext.feature.geometry.coordinates[0]
    }
    // while editing
    if(property?.data[0]?.geometry?.coordinates[0]){
      curentCoordinates = property?.data[0]?.geometry?.coordinates[0]
    }

    // polygon can be created with minimum of 2 sets of [lng lat] pair
    if(curentCoordinates.length > 2){
      // comma seperated array of lat long 
      const spaceSeperatedItems = curentCoordinates.map(item => (item.join(' ')))
      
      // comma seperated string
      const commaSeperatedCoordinates = spaceSeperatedItems.join(',')
  
      dispatch(setTaskCreationCoordinates(commaSeperatedCoordinates))
    }
  }
  
  // switching between map draw option
  _switchMode = (evt) => {
    const modeId = evt.target.value === this.state.modeId ? null : evt.target.value;
    const mode = MODES.find((m) => m.id === modeId);
    const modeHandler = mode ? new mode.handler() : null;
    this.setState({ modeId, modeHandler });
  };

  _renderToolbar = () => {
    return (
      <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1100, padding: '10px', maxWidth: '320px' }}>
          {
            MODES.map((mode) => (
              <button style={STYLES.buttonStyle} onClick={this._switchMode} key={mode.id} value={mode.id}>
              {mode.id === 'drawPolygon' ? 'Draw' : 'Edit'}
              </button>
            ))
          }
      </Box>
    );
  };

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  render() {
    const { modeHandler, viewState, layers, modeId } = this.state

    return (
        <Box sx={ STYLES.boxStyle }>
          <Container style={ STYLES.containerStyle }>
            <Typography variant={ 'subtitle1' } sx={ STYLES.mapTitle }>Draw Task Area</Typography>
            <Divider sx={{ marginBottom: '10px' }}/>
            <Container maxWidth={ '100%' } sx={{ padding: '10px' }}>
              <Paper elevation={ 3 }>
                <DeckGL
                  initialViewState = { viewState }
                  controller={{
                      doubleClickZoom: false
                    }}
                  layers={ layers }
                  ContextProvider={ MapContext.Provider }
                  style={ STYLES.mapViewport }
                      
                >
                  <StaticMap
                      mapStyle={mapStyle}
                  />
                
                    <Editor
                      // to make the lines/vertices easier to interact with
                      clickRadius={20}
                      mode={modeHandler}
                      style={ modeId ? { cursor: 'cell'} : '' }
                      featureStyle={({feature, state}) => {
                        return { 
                          stroke: 'rgb(0, 0, 255)',
                          fill: 'rgba(255,170,170, 50%)'
                       }
                      }}
                      onUpdate={(property) => this._setCoordinates(property)}
                    />
          
                    {this._renderToolbar()}
                </DeckGL>
              </Paper>
            </Container>
          </Container>
      </Box>
    );
  }
}

const STYLES = {

  boxStyle: { 
    padding: '12px', 
    borderRadius: '5px',
    backgroundColor: '#f4f4f4'
  },
  containerStyle: {
    position: 'relative', 
    maxWidth: '100%', 
    color: '#319688'
  },
  mapTitle: {
    marginTop: '0', 
    textAlign: 'center', 
    fontWeight: 'bold', 
    backgroundColor: '#ffffff', 
    padding: '5px',
    color: '#2ba08f'
  },
  mapViewport: {
    position: 'relative', 
    width: '100%',
    height: `${window.innerHeight - 165}px`,
    boxShadow: '0 0 3px #919191',
  },
  buttonStyle: {
    display: 'flex', 
    flexDirection: 'column', 
    borderRadius: '10px',
    backgroundColor: '#323F4D', 
    color: '#319688', 
    fontWeight: 'bold', 
    padding: '10px', 
    width: '55px', 
    alignItems: 'center'
  }
}

TaskMap.propTypes = {
  taskCreationCoordinates: PropTypes.string
}
TaskMap.defaultProps = {
  taskCreationCoordinates: []
}


const mapStateToProps = (state) => ({
  taskCreationCoordinates: state?.mapData?.taskCreationCoordinates ?? ''
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(TaskMap);