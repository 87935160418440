import React from "react";
import Select from "react-select";
import _isEqual from 'fast-deep-equal'
import '../App.css'

import { updatePointData } from '../Redux/Actions/mapDataActions'
import { Alert, AlertTitle, Typography, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Cancel, Send } from "@mui/icons-material";
import { ISSHOPOPENOPTIONS } from "../App.config";

class UpdateData extends React.PureComponent {
        state = {
            // 
            currentData: '',
            pTypeOption: [],
            subTypeOption: [],
            allSubTypeOption: [],
            currentSubTypeOption: [],
            // currentPtype: this.props.info.info.pType
            currentPtype: '',
            currentSubType: '',
            currentCustomerType: '',
            marketAccess: '',
            currentHatDay: '',
            showPTypeSubTypeWarning: false,
            isButtonLoading: false

        }

        componentDidMount(){
            const { info, pTypeOptionProp, allSubTypeOptionProp, currentSubTypeOptionProp } = this.props
            // const { pTypeOption } = this.state

            // Fetching all pType
            this.setState({currentData: info})

            this.setState({
                pTypeOption: pTypeOptionProp,
            })

            
            
            this.setState({
                allSubTypeOption: allSubTypeOptionProp,
                currentSubTypeOption: currentSubTypeOptionProp
                
            })
            
            // Fetching all sub type
          

        }

        componentDidUpdate(prevProps, prevState){
            const { currentSubTypeOptionProp, pTypeOptionProp } = this.props
            const { allSubTypeOption, currentData, currentSubTypeOption } = this.state
            
            let subTypeOptionArray = []

            //if currentSubtype option for selected pType changes
            if(!_isEqual(prevProps.currentSubTypeOptionProp, currentSubTypeOptionProp)){
                this.setState({
                    currentSubTypeOption: currentSubTypeOptionProp
                    
                })
            }

            // if pType option list updates
            if(!_isEqual(prevProps.pTypeOptionProp, pTypeOptionProp)){
                this.setState({
                    pTypeOption: pTypeOptionProp,
                })
            }
            
            // if pType is empty setting subtype options empty
            if(currentSubTypeOption?.length !== prevState.currentSubTypeOption?.length && (!currentData.pType || currentData?.pType?.length < 1)){
                this.setState({
                    currentSubTypeOption: []
                })
            }

            // checkingif pType gets changed, relavent subtype options is shown in dropdown
            if(prevState.currentData?.pType !== currentData?.pType){

                const pTypeArray = currentData.pType.slice().split(',');

                allSubTypeOption.forEach((subItem)=>(
                    pTypeArray.forEach(pTypeItem=> (
                        subItem.type === pTypeItem ? subTypeOptionArray.push({value: subItem.subtype, label: subItem.subtype }) : ''
                    ))
                ))

                this.setState({
                    currentSubTypeOption: subTypeOptionArray
                })
               
            }

        }




        // checking is there are any selected value for target pType in subtype dropdown field
        validatePTypeSubType(){
            const { currentSubTypeOption, currentData } = this.state

            const currentlySelectedSybType = currentData?.subType?.split(',')

            // matching hoy many selected subtype is matched with existing pType optipn
            let matchedSubtypeCount = 0

            currentlySelectedSybType?.forEach((option) => {
                const isFound = currentSubTypeOption?.find((item) => (item?.label === option.trim()))

                if(isFound){
                   ++matchedSubtypeCount
                }

            })


            // if length is equal meaning all subtype have respective pType.
            return currentlySelectedSybType?.length === matchedSubtypeCount
        }

        // setting pType 
        _setPtype = (option, property) => {
    
            const propertyName = property.name;

            let optionValue = '';


            option.forEach(item=> (
            
                item.value === 'clear' ? optionValue = '' : optionValue += item.value +',' 
                
            )) 

            const optionValueWithoutEndComma = optionValue.substring(0,optionValue.length-1)

            this.setState({
                currentData: {
                    ...this.state.currentData,
                    [propertyName]:  optionValueWithoutEndComma
                }
            })    

        }

        _setSubType = (option, property) => {
           
            const propertyName = property.name;

            let optionValue = '';

            option.map(item=> (
              
                item.value === 'clear' ? optionValue = '' : optionValue += item.value +","
                
            )) 

            const optionValueWithoutEndComma = optionValue.substring(0,optionValue.length-1)

            this.setState({
                currentData: {
                    ...this.state.currentData,
                    [propertyName]:  optionValueWithoutEndComma
                }
            })

        }

        _setIsShopOpen = (option, property) => {
            const propertyName = property.name;
            const value = option.value;

            this.setState({
                currentData: {
                    ...this.state.currentData,
                    [propertyName]:  value
                }
            })
        }


        // post point's update form data
        _postUpdatedData = async (e) => { 
            e.preventDefault();
            this.setState({
                isButtonLoading: true
            })

            // checking if seleted subtypes have respective pType
            const shouldUpdate = this.validatePTypeSubType()

            // checking if there is any subtype that do not has selected pType in pType dropdown field
            if(!shouldUpdate){
                this.setState({
                    showPTypeSubTypeWarning: true,
                    isButtonLoading: false
                })

                return
            }

            const {info, popupToast, updateInfo, dataUpdatePanelToggler } = this.props
            const { currentData } = this.state
            const {uCode} = info
            
            const params = {
                //** */ mandatory to update
                latitude: info.latitude,
                longitude: info.longitude,

                //** */ optinal field
                business_name: currentData.business_name,
                holding_number: currentData.holding_number,
                place_name: currentData.place_name,
                road_name_number: currentData.road_name_number,
                district: currentData.district,
                sub_district: currentData.sub_district,
                union: currentData.union,
                thana: currentData.thana,
                area: currentData.area,
                sub_area: currentData.sub_area,
                super_sub_area: currentData.super_sub_area,
                postCode: currentData.postCode,
                pType: currentData.pType,
                subType: currentData.subType,
                is_shop_open: currentData.is_shop_open,
            }
      
            // updating point data
            updatePointData(uCode, params)
            .then(res=> {
                if(res?.status !== 200){
                    // stop loading button from loading state
                    this.setState({
                        isButtonLoading: false
                    })
                    popupToast(true)

                    return
                }

                // stop loading button from loading state
                this.setState({
                    isButtonLoading: false
                })
                
                popupToast(true)
                updateInfo(currentData)
                dataUpdatePanelToggler()

                this.setState({
                    showPTypeSubTypeWarning: false
                })
            })
            .catch(err => {
                popupToast(false)
                this.setState({
                    isButtonLoading: false
                })
                console.error(err)
            })
        }
        

        // Update data of all input field excep dropdown
        _updateCurrentDataState = (property, event) => {

            this.setState(prevState => ({
                currentData: {
                    ...this.state.currentData,
                    [property]: event.target.value
                }

            }))
        }


        render(){
            const { currentData, currentSubTypeOption, pTypeOption, showPTypeSubTypeWarning, isButtonLoading } = this.state

            return (
                <div>
                    <form action="" method="">
                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Business Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('business_name', e)} type="text" name='shop-name' value={ currentData.business_name ?  currentData.business_name : ''} />
                        
                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">House No :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('holding_number', e)} type="text" name='holding-number' value={ currentData.holding_number ?  currentData.holding_number : ''} />
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="shop-name">Road no / name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('road_name_number', e)} type="text" name='road-name-number' value={ currentData.road_name_number ?  currentData.road_name_number : ''} />
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="shop-name">Place Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('place_name', e)} type="text" name='place-name' value={ currentData.place_name ?  currentData.place_name : ''} />
                       
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="district">District :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('district', e)} type="text" name='district' value={ currentData.district ? currentData.district : ''} placeholder="District"/>
                       
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="sub-district">Sub District :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('sub_district', e)} type="text" name='sub-district' value={ currentData.sub_district ? currentData.sub_district : ''} placeholder="Sub District"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="union">Union :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('union', e)} type="text" name='union' value={ currentData.union ? currentData.union : ''} placeholder="Union"/>

                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="thana">Thana :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('thana', e)} type="text" name='thana' value={ currentData.thana ? currentData.thana : ''} placeholder="Thana"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="area">Area :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('area', e)} type="text" name='area' value={ currentData.area ? currentData.area : ''} placeholder="Area"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="sub-area">Sub Area :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('sub_area', e)} type="text" name='sub-area' value={ currentData.sub_area ? currentData.sub_area : ''} placeholder="Sub Area"/>
                        
                        <Typography ml={ 1 }  htmlFor="super-sub-area">Super Sub Area</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('super_sub_area', e)} type="text" name='super-sub-area' value={ currentData.super_sub_area ? currentData.super_sub_area : ''} placeholder="Super Sub Area"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="postCode">Post Code :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('postCode', e)} type="text" name='postCode' value={ currentData.postCode ? currentData.postCode : ''} placeholder="Post Code"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="ptype">Place Type :</Typography>
                        <Select
                            isMulti
                            name="pType"
                            options={ pTypeOption }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this._setPtype}
                            isClearable={false}
                            value={currentData?.pType?.split(',')?.map(item => ( item ? {label: item, value: item} : '')) ?? ''}

                        />
                        

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="subtype">Sub Type :</Typography>
                        <Select
                            isMulti
                            name="subType"
                            options={ currentSubTypeOption }
                            isClearable={true}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this._setSubType}
                            value={currentData?.subType?.split(',')?.map(item => ( item ? {label: item.trim(), value: item.trim()} : '')) ?? ''}
                        />


                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Is Shop Open :</Typography>
                        <Select
                            name='is_shop_open'
                            id='is_shop_open'
                            options={ISSHOPOPENOPTIONS}
                            label='Is Shop Open'
                            onChange={this._setIsShopOpen}
                            placeholder={(currentData.is_shop_open === 1)? "Yes" : "No" }
                            defaultValue={(currentData.is_shop_open === 1)? "Yes" : "No" }
                            isClearable={false}
                        />

                        <div style={ STYLES.buttonContainer }>
                            <LoadingButton
                                variant={'contained'}
                                size={ 'small' }
                                loading={ isButtonLoading }
                                onClick={this._postUpdatedData}
                                sx={ STYLES.buttonStyle }
                                color={ 'text' }
                                endIcon={<Send />}
                                disabled={ currentSubTypeOption?.length <= 0 }
                            >
                                Update
                            </LoadingButton>
                            <LoadingButton
                                variant={'contained'}
                                size={ 'small' }
                                // loading={ isLoading }
                                startIcon={<Cancel />}
                                onClick={this.props.dataUpdatePanelToggler}
                                sx={ STYLES.buttonStyle }
                                color={ 'text' }
                            >
                                Close
                            </LoadingButton>
                            {/* <Button  variant='outlined' disabled={ currentSubTypeOption?.length <= 0 }  sx={STYLES.buttonStyle} onClick={this._postUpdatedData} >Submit</Button> */}
                            {/* <Button  variant='outlined'  sx={STYLES.buttonStyle} onClick={this.props.dataUpdatePanelToggler} value='Close Update Panel'>Close Update Panel</Button> */}
                        </div> 

                        {
                            showPTypeSubTypeWarning &&
                            <Alert severity="error" sx={{color: '#9b0a0a'}}>
                                <AlertTitle>Warning</AlertTitle>
                                All sub-type and their respective p-type must be selected 
                            </Alert>
                        }

                    </form>
                    <Divider sx={{ margin: '20px auto', width: '90%' }}/>
                </div>
            )
        }
}

const STYLES = {
    buttonContainer: { 
        width: '100%', 
        display: "flex", 
        alignItems: 'center', 
        margin: '20px auto',
    },
    buttonStyle: {
        padding: '4px 20px',
        margin: '0 5px 0 0',
        border: '1px solid #24242b56',
        borderRadius: '5px',
        fontWeight: 'bold',
        // color: '#202c39',
        backgroundColor: '#ffff',
        // boxShadow: '4px 4px 5px rgba(27, 27, 27, 0.151)'
    }
}

export default UpdateData;