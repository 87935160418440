import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedDistrictForFilter: '',
    filteredData: [],
    filterStartDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`, // for mapper
    filterEndDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`,
}

const filterDataSlice = createSlice({
    name: 'filterData',
    initialState,
    reducers: {
        setSelectedDistrictForFilter: (state, action) => {
            state.selectedDistrictForFilter = action.payload
        },
        setFilteredData: (state, action) => {
          state.filteredData = action.payload
        },
        setFilterStartDate: (state, action) => {
            state.filterStartDate = action.payload;
        },
        setFilterEndDate: (state, action) => {
            state.filterEndDate = action.payload;
        },
    }
})

export const { setSelectedDistrictForFilter, setFilteredData, setFilterStartDate, setFilterEndDate } = filterDataSlice.actions
export default filterDataSlice.reducer