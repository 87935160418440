import React from 'react';
import { connect } from 'react-redux';
import { Container, Box, CssBaseline } from '@mui/material';
import { Resizable } from 're-resizable';

import StyledTabMenu from './StyledTabMenu';
import LoaderPanel from './LoaderPanel';
import MapperPanel from './MapperPanel';
import FilterPanel from './FilterPanel';

import '../App.css'

// Actions and Reducers
import { setPoints, setRunLoader, setStaticPoints } from '../Redux/Reducers/mapDataReducer';
import { getDistrict } from '../Redux/Actions/commonAction';

// API
import { fetchIndividualUserPlace } from '../utils/fetchUtils';
import UpdateLogPanel from './UpdateLogPanel';


class Information extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {  
            selectedTab: 0,
            // expandDataLayer: false,
            individualUser: '',
            allUser: [],
            hideFilterPanel: true
            
        }
        this.filterPanelRef = React.createRef();
        // this.fetchIndividualUser = this.fetchIndividualUser.bind(this); 
        this.setIndividualuser = this.setIndividualuser.bind(this);
        this.toggleFilterPanel = this.toggleFilterPanel.bind(this);
    }

    componentDidMount(){
        const { dispatch } = this.props

        dispatch(getDistrict())
    }

    toggleFilterPanel(){
        this.setState({
            hideFilterPanel: !this.state.hideFilterPanel
        })
    }

    _onStatTabChange = (e, newValue) => {
        this.setState({ selectedTab: newValue })
    }

    // Fetching data individual user's places
    setIndividualuser(e){
        const { dispatch } = this.props
        const userID = e.target.value;
        dispatch(setRunLoader(true));

        fetchIndividualUserPlace(userID)
            .then(data => {
                dispatch(dispatch => (
                    dispatch(setPoints(data)),
                    dispatch(setStaticPoints(data)),
                    dispatch(setRunLoader(false))
                ))
 
            })
            .catch(err=> console.error(err))
    }

    

    render (){

        return (
            <React.Fragment >
         
                <div  style={this.state.hideFilterPanel ? {width: 0} : {transform: 'translateX(0)'}} ref={this.filterPanelRef}>

                <Resizable
                    defaultSize={{
                    }}
                    >
                <CssBaseline />
                <button onClick={this.toggleFilterPanel} style={{position: 'absolute', backgroundColor: '#202C39', color: '#009688', fontSize:'36px', zIndex: '1000', left: '-75px', height: '135px', width: '75px', border: 'none', borderRadius:'0 0 0 15px', boxShadow: '-4px 5px 6px #595c5b'}}>&#8633;</button>
                {/* <Container  maxWidth="sm"> */}
                <Container>
                    <Box sx={boxStyle}>
                        <StyledTabMenu
                           elevation={ 3 }
                           tabOptions={[
                            { label: 'Loader' },
                            { label: 'Filter' },
                            { label: 'Mapper' },
                            { label: 'Update Log' },
                        ]}
                        value={this.state.selectedTab}
                        onChange={ this._onStatTabChange }
                        
                        />
                        {
                            this.state.selectedTab === 0 &&
                            <LoaderPanel />
                        }
                        {
                            this.state.selectedTab === 1 &&
                            <FilterPanel />
                        }
                        {
                            this.state.selectedTab === 2 &&
                            <MapperPanel />
                        }
                        {
                            this.state.selectedTab === 3 &&
                            <UpdateLogPanel />
                        }
                      
                        
                        

                    </Box>

                    
                </Container>
                </Resizable>
                </div>
            </React.Fragment>
        )
    }
}

const boxStyle = { 
    // position: "relative",
    bgcolor: '#ffffff', 
    height: `${window.innerHeight - 64}px`, 
    minWidth: '45vw', 
    // maxWidth: '600px', 
    boxShadow: '0 0 10px black' 
} ;
// const boxStyleTest = { positio } ;

const mapStateToProps = (state) => ({
    points: state?.mapData?.points ?? '',
    staticPoints: state?.mapData?.staticPoints ?? ''
})

  const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(Information);