import { DATA_GRID_DEFAULT_SLOTS_COMPONENTS } from "@mui/x-data-grid"

/**********************
 ******  OLD API ******
 * ********************/
export const BASE_API_URL = 'https://retail.bmapsbd.com:8080'


/**********************
 ******  NEW API ******
 * ********************/
//Staging
// export const BASE_API = 'http://bkoih3.ml:9000'

// export const BASE_API = "https://barikoi.admin.api.barikoimaps.dev"
// export const BASE_API = 'http://192.168.0.119:8001'

//production
export const BASE_API = 'https://api.bmapsbd.com'

//  CNL base url --- production
export const CNL_BASE_API = 'https://cnl.barikoiretail.com:5623'

//  CNL base url --- staging
// export const CNL_BASE_API = 'http://20.24.15.52:7040'

// Other's API
const GEOSERVER_API = 'https://geoserver.bmapsbd.com'
// MAP API key
export const MAP = {
    API_KEY: process.env.REACT_APP_MAPKEY ?? '',
    MAPBOX_KEY: process.env.REACT_APP_MAPBOX_KEY ?? '',
    DOWNLOAD_RETAIL_REPORT: `${BASE_API}/api/retail-mapping-download-data-with-products`,  // + ?dateFrom=MM-DD-YY&dateTill=MM-DD-YY`
    GET_DIVISION_POLYGON: `${ BASE_API }/api/get-division`, 
    GET_DIVISION_DATA_COUNT: `${ BASE_API }/api/get-division-place-count`, 
}

export const MAP_STYLE = {
    GEOSERVER_STYLE: `${GEOSERVER_API}/styles/one-map/style.json?key=${MAP.API_KEY}`,
}

export const MAPPER = {
    // https://api.bmapsbd.com/user/1624
    FETCH_INDIVUDUAL_MAPPER_BY_ID: `${BASE_API}/user/`,  // +  user/mapper_id
    ALL_MAPPER: `${BASE_API}/mapper/list`,
    DATA_COUNT: `${BASE_API}/statistics/mapper`, // + ?dateFrom=01-08-2022&dateTill=02-08-2022
    RETAIL_DATA_COUNT: `${BASE_API}/statistics/retail/mapper`, // + ?dateFrom=01-08-2022&dateTill=02-08-2022
    INDIVIDUAL_DATA: `${BASE_API}/get/places/by/user`, // + ?uid=3712&dateFrom=2022-07-25&dateTill=2022-07-25
    INDIVIDUAL_RETAIL_DATA:`${BASE_API}/get/retail/places/by/user`,
}

export const RETAIL = {
    GET_TERRITORIES: `${BASE_API}/get-retail-route-breakdown`,
    GET_ROUTE_BY_TERRITORY: `${BASE_API}/get-retail-route-by-territory`,
    GET_MARKET_BY_ROUTE: `${BASE_API}/get-retail-market-by-route`,
}

export const CNL = {
    ALL_MAPPER: `${CNL_BASE_API}/api/v1/user-list`,
    UPDATE_POINT_DATA: `${CNL_BASE_API}/api/v1/update-outlet`,
    OUTLET_TYPES: `${CNL_BASE_API}/api/v1/outlet-type`,
    OUTLET_CATEGORIES: `${CNL_BASE_API}/api/v1/outlet-category`,
    TERRITORIES: `${CNL_BASE_API}/api/v1/territories`,
    ROUTES: `${CNL_BASE_API}/api/v1/routes?nation_id=1`,
    MARKET_OPPORTUNITIES: `${CNL_BASE_API}/api/v1/outlet-market-opportunity`
}

// Auth Configs
export const AUTH = {
    LOGIN_API: `${BASE_API}/admin/login`,
    GET_USER_API: `${BASE_API}/auth/user`
}


export const DATA = {
    UPDATE_POINT_DATE: `${BASE_API}/auth/place/update/`, // + uCode
    UPDATE_POINT_DATA_RETAIL: `${BASE_API}/auth/place/retail/update/`, // + uCode
    GET_PLACE_DATA: `${BASE_API}/v2/place`, // + uCode
    GET_SINGLE_RETAIL_PLACE_DATA: `${BASE_API}/v2/retail/place`, // + uCode
    GET_ALL_SUBTYPE: `${BASE_API}/place/get/all/subtype`,
    GET_PLACE_PLOTTING_DATA: `${BASE_API}/api/retail-mapping-get-data-with-products`,  // + ?dateFrom=YY-MM-DD&dateTill=YY-MM-DD&district={districtName}
    GET_RETAIL_PLACE_PLOTTING_DATA: `${BASE_API}/api/get-retail-data-with-products`,  // + ?dateFrom=YY-MM-DD&dateTill=YY-MM-DD&district={districtName}
    GET_UPDATE_LOG: `${BASE_API}/get-place-update-data`, // + ?start_date=YY-MM-DD HH:MM:SS&end_date=YY-MM-DD HH:MM:DD => optional &?user_id={mapper_id} 
    GET_RETAIL_FILTERRED_DATA: `${BASE_API}/get/retail/places/by/category`,  // + ?dateFrom=2023-01-01&dateTill=2023-01-05 & territory_name=afdsaf & route_name=dsfdsa & market_name=asdfdsa
    DELETE_IMAGE: `${BASE_API}/delete-place-image/`, //+ imageid
    GET_CNL_DATA: `${CNL_BASE_API}/api/v1/outlets` //+ imageid
}



// SOCKET configs
export const SOCKET = {
    API_KEY: 'a1975a97bf741bbb1008', 
    CLUSTER: 'ap1',
    WS_HOST: 'socket.bmapsbd.com',
    WS_PORT: 8005,
    WSS_PORT: 6002,
    // AUTH_ENDPOINT: `${BASE_API}/broadcasting/auth`, // remove by commenting out for production
    RETAIL_ADD_PLACE_CHANNEL: 'send-added-place',
    RETAIL_ADD_PLACE_EVENT: 'place-data',
    RETAIL_UPDATE_PLACE_EVENT: 'updated-place-data',
    ONLY_RETAIL_ADD_PLACE_EVENT: 'retail-place-data',
}

export const COMMON = {
    DISTRICT_LIST: `${BASE_API}/district/list/retail/mapper`,
    MARKER_ICON: `https://barikoi-dev-resources.s3.ap-southeast-1.amazonaws.com/marker-icons`,  // + /ptype
    DELETE_IMAGE: "DELETE_IMAGE",
}

export const PAGES = {
   MAPPER:  `Mapper`,
   RETAIL: `Olympic`,
   CNL: `CNL`
}


export const BUYEROPTIONS = [
    {value: '0', label: 'Non Buyer'},
    {value: '1', label: 'Buyer'}
]

export const TRADELISENCEOPTIONS = [
    {value: '0', label: 'No'},
    {value: '1', label: 'Yes'}
]

export const ISSHOPOPENOPTIONS = [
    {value: 0, label: 'No'},
    {value: 1, label: 'Yes'}
]

