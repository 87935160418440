import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  points: [],
  singleDataPoint: [], // this is for rendering single point on map without hampering other points data 
  socketDataPoints: [],
  socketRetailDataPoints: [],
  staticPoints: [], //for managing stat in filter panel
  runLoader: true,
  allMapperInfo: [],
  taskCreationCoordinates: '',
  mapperGeoLocation: {},
  renderSinglePoint: false,
  filteredData: [],
  updateDataSocketResponse: []
}

const mapDataSlice = createSlice({
  name: 'mapData',
  initialState,
  reducers: {
    setPoints: (state, action) => {
      state.points = action.payload;
    },
    setSingleDataPoint: (state, action) => {
      state.singleDataPoint = action.payload;
    },
    setSocketDataPoints: (state, action) => {
      state.socketDataPoints = action.payload;
    },
    setSocketRetailDataPoints: (state, action) => {
      state.socketRetailDataPoints = action.payload;
    },
    setRunLoader: (state, action) => {
      state.runLoader = action.payload;
    },
    setStaticPoints: (state, action) => {
      state.staticPoints = action.payload;
    },
    setAllMapperInfo: (state, action) => {
      state.allMapperInfo = action.payload;
    },
    setTaskCreationCoordinates: (state, action) => {
      state.taskCreationCoordinates = action.payload;
    },
    setMapperGeoLocation: (state, action) => {
      state.mapperGeoLocation = action.payload
    },
    setRenderSinglePoint: (state, action) => {
      state.renderSinglePoint = action.payload
    },
    setUpdateDataSocketResponse: (state, action) => {
      state.updateDataSocketResponse = action.payload
    }
  }
})

export const { setPoints, setSingleDataPoint, setSocketDataPoints, setSocketRetailDataPoints, setRunLoader, setStaticPoints,setAllMapperInfo, setTaskCreationCoordinates, setMapperGeoLocation, setRenderSinglePoint, setUpdateDataSocketResponse } = mapDataSlice.actions
export default mapDataSlice.reducer