import axios from "axios";
import { BASE_API } from "../../App.config";

// delete task 
const deleteTask = async (taskID) => {
    try {
        const res =  await axios.delete(`${BASE_API}/delete/data/task/${taskID}`)

        return  res?.data ?? ''  

            
    } catch (error) {
        console.error(error);
    }
}

const createTask = async (params) => {
    try {
        const res = await axios.post(`${BASE_API}/create/data/task`, params)
        return res
    } catch (error) {
        console.error(error);
    }
}

export { deleteTask, createTask }