import axios from 'axios'
import { DATA } from '../../App.config'
import { setUpdateLogData } from '../Reducers/updateLogReducer'

const getUpdateLogData = async (params) => {
    const { user_id, startDate, endDate } = params
    return axios.get(`${ DATA.GET_UPDATE_LOG }?start_date=${ startDate } 00:00:00&end_date=${ endDate } 23:59:59${ user_id ? '&user_id=' + user_id : '' }`)
    .then(res => {
        if(res?.status !== 200){
            return 
        }
        return res?.data
    })
    .catch(err => {
        console.error(err)
    })

}


// const prevSocketData = getState().mapData.updateDataSocketResponse
// const prevSockedDataCopy = [...prevSocketData]
// prevSockedDataCopy?.length > 0 ? prevSockedDataCopy.unshift(updateData) : prevSockedDataCopy.push(updateData)

// // // Add Socket Data To Redux State
// dispatch( setUpdateDataSocketResponse(prevSockedDataCopy))

export { getUpdateLogData }