export const allStaticArea= [
     
      "Abdullahpur"
     ,
     
      "Aganagar"
     ,
     
      "Agrakhola"
     ,
     
      "Ainta"
     ,
     
      "Alukanda"
     ,
     
      "Amin Bazar"
     ,
     
      "Aminpara"
     ,
     
      "Angar Gara"
     ,
     
      "Angargara"
     ,
     
      "Ati"
     ,
     
      "Awalirchala"
     ,
     
      "Baburgram"
     ,
     
      "Bamonsur"
     ,
     
      "Bandia"
     ,
     
      "Bangaon"
     ,
     
      "Barishur"
     ,
     
      "Bashil Bazar"
     ,
     
      "Bashundhara"
     ,
     
      "Basta"
     ,
     
      "Batajor"
     ,
     
      "Batajore"
     ,
     
      "Batajur"
     ,
     
      "Bawlia Baju Bazar"
     ,
     
      "Bayara"
     ,
     
      "Beparipara"
     ,
     
      "Beyara"
     ,
     
      "Bhaluka"
     ,
     
      "Bhaluka Paurashava"
     ,
     
      "Bhaluka Paurashava Bazar"
     ,
     
      "Bhaluka Pauroshobha Bazar"
     ,
     
      "Bhaluka Pauroshova"
     ,
     
      "Bhaluka Paurosobha Bazar"
     ,
     
      "Bhaluka Pourashaba"
     ,
     
      "Bhaluka Pourashova"
     ,
     
      "Bhaluka Pouroshava"
     ,
     
      "Bhaluka Pouroshoava"
     ,
     
      "Bhaluka Pouroshova"
     ,
     
      "Bhaluka Purashova Bazar"
     ,
     
      "Bharaliya"
     ,
     
      "Bhawalia Baju Bazar"
     ,
     
      "Bhora Dob"
     ,
     
      "Bhora Doba"
     ,
     
      "Bhoradoba"
     ,
     
      "Birunia"
     ,
     
      "Board Bazar"
     ,
     
      "Bogajan"
     ,
     
      "Bongaon"
     ,
     
      "Bonkua"
     ,
     
      "Boraid"
     ,
     
      "Borta"
     ,
     
      "Bote Bazar"
     ,
     
      "Brammankitta"
     ,
     
      "Brammongaon"
     ,
     
      "Brammonkitta"
     ,
     
      "Busta"
     ,
     
      "Chan"
     ,
     
      "Chand"
     ,
     
      "Chandipur"
     ,
     
      "Chanpur"
     ,
     
      "Chechia"
     ,
     
      "Chechua"
     ,
     
      "Chhital"
     ,
     
      "Chital"
     ,
     
      "Chotan Para"
     ,
     
      "Chotanpara"
     ,
     
      "Chunkutia"
     ,
     
      "Cotan Para"
     ,
     
      "Dakatia"
     ,
     
      "Dakkhinpara, Angargara"
     ,
     
      "Dakuria"
     ,
     
      "Dhaka Cantonment"
     ,
     
      "Dhakuria"
     ,
     
      "Dhaleshwar"
     ,
     
      "Dhitpur"
     ,
     
      "Dholia"
     ,
     
      "Doyal Market"
     ,
     
      "Dubalia Para"
     ,
     
      "Ekuria"
     ,
     
      "Ganka"
     ,
     
      "Ghatarchar"
     ,
     
      "Golam Bazar"
     ,
     
      "Gouripur"
     ,
     
      "Habir Bari"
     ,
     
      "Habirbari"
     ,
     
      "Hasnabad"
     ,
     
      "Hazir Bazar"
     ,
     
      "Hazratpur"
     ,
     
      "Hizla"
     ,
     
      "Hossainpur"
     ,
     
      "Itkhola"
     ,
     
      "Jagannathpur"
     ,
     
      "Jindapir"
     ,
     
      "Jindapir Mazar"
     ,
     
      "Jiyostola"
     ,
     
      "Joinpur"
     ,
     
      "Kachina"
     ,
     
      "Kadamtoli"
     ,
     
      "Kalatiya"
     ,
     
      "Kaliganj"
     ,
     
      "Kalindi"
     ,
     
      "Kashor"
     ,
     
      "Katlamari"
     ,
     
      "Kazirgaon"
     ,
     
      "Keraniganj Model Town"
     ,
     
      "Khagail"
     ,
     
      "Khalil"
     ,
     
      "Khalil Bazar"
     ,
     
      "Kharakanda"
     ,
     
      "Kharakandi"
     ,
     
      "Khejurbagh"
     ,
     
      "Kholamora"
     ,
     
      "Kliganj"
     ,
     
      "Koiyadi"
     ,
     
      "Konakhola"
     ,
     
      "Konakhola Bazar"
     ,
     
      "Kuchila"
     ,
     
      "Kuchila Tola"
     ,
     
      "Kushairabagh"
     ,
     
      "Maduary"
     ,
     
      "Mahamudpur"
     ,
     
      "Maher"
     ,
     
      "Mahmudpur"
     ,
     
      "Malancha"
     ,
     
      "Mallikbari"
     ,
     
      "Mallikbari Bazar"
     ,
     
      "Mamarishpur"
     ,
     
      "Mamarispur"
     ,
     
      "Mandail"
     ,
     
      "Mastar Bari"
     ,
     
      "Mastarbari"
     ,
     
      "Master Bari"
     ,
     
      "Masterbari"
     ,
     
      "Medila"
     ,
     
      "Meduary"
     ,
     
      "Mirerbagh"
     ,
     
      "Mirpur"
     ,
     
      "Mohona Chourasta"
     ,
     
      "Mollarhat"
     ,
     
      "Mollikbari"
     ,
     
      "Nandi"
     ,
     
      "Nandibari"
     ,
     
      "Narangi"
     ,
     
      "Narangi Chowrasta"
     ,
     
      "Naranqi"
     ,
     
      "Natangi"
     ,
     
      "Nijhuri"
     ,
     
      "Nodibari"
     ,
     
      "Norondi"
     ,
     
      "Pachgaon"
     ,
     
      "Pachgaun"
     ,
     
      "Palagaon"
     ,
     
      "Palgaon"
     ,
     
      "Panagaon"
     ,
     
      "Pangaon"
     ,
     
      "Paragaon"
     ,
     
      "Paragaun"
     ,
     
      "Pargendaria"
     ,
     
      "Paruldia"
     ,
     
      "Puler gath"
     ,
     
      "Pulerghat"
     ,
     
      "Pustogula"
     ,
     
      "Rainda"
     ,
     
      "Rajabari"
     ,
     
      "Rajendrapur"
     ,
     
      "Rajoi"
     ,
     
      "Rajoy"
     ,
     
      "Ramerkanda"
     ,
     
      "Rampur"
     ,
     
      "Rasulpur"
     ,
     
      "Razabari"
     ,
     
      "Ruhit Pur"
     ,
     
      "Ruhitpur"
     ,
     
      "Sakta"
     ,
     
      "Santigonj"
     ,
     
      "Seed"
     ,
     
      "Seed Store"
     ,
     
      "Seedestor"
     ,
     
      "Seedestore"
     ,
     
      "Seedestore Bazar"
     ,
     
      "Seedstore"
     ,
     
      "Seedstore Bazar"
     ,
     
      "Serajdikhan"
     ,
     
      "Shakta"
     ,
     
      "Shantiganj"
     ,
     
      "Shantigonj"
     ,
     
      "Sonakhali"
     ,
     
      "Subaddya"
     ,
     
      "Sutrapur"
     ,
     
      "Talab"
     ,
     
      "Talabo"
     ,
     
      "Talepur"
     ,
     
      "Tamart Bazar"
     ,
     
      "Tamat"
     ,
     
      "Taranagar"
     ,
     
      "Taska"
     ,
     
      "Taska Para"
     ,
     
      "Taskapara"
     ,
     
      "Teghria"
     ,
     
      "Tongrapara"
     ,
     
      "Udhura"
     ,
     
      "Uthura"
     ,
     
      "Uthura Bazar"
     ,
     
      "Uttarkhan"
     ,
     
      "Vayaboh"
     ,
     
      "Voraduba Union"
     ,
     
      "Washpur"
     ,
     
      "Zazira"
     ,
     
      "Zianagar"
     ,
     
      "Zinzira"
     
     ]