import axios from 'axios'
import { MAPPER } from '../../App.config'
import store from '../store'

// Import actions and reducers
import { setIsloading, setMapperDataCountList, setMapperList, setMapperRetailDataCountList, setTotalAddedDataCount, setTotalAddedRetailDataCount } from '../Reducers/mapperReducer'



// data count
const getMapperTotalAddedDataCount = (dataList) => {
    let total = 0 
    dataList.forEach( singleDayData => {
        singleDayData?.state?.forEach(mapper => {
            total += mapper?.p_count ? mapper.p_count : 0
        })
    })

    return total
}

// get mapper data count - params - dateFrom & dateTill are optional - default get data of currant data
const getMapperAddedDataCount = (params) => {
    return dispatch => {
        dispatch(setIsloading(true));
        axios.get(MAPPER.DATA_COUNT, params)
        .then(res => {
            if(res?.status !== 200){
                console.log("data count all -------------", res);
                return
                return
            }
            dispatch(setTotalAddedDataCount(getMapperTotalAddedDataCount(res?.data?.data)))
            dispatch(setMapperDataCountList(res?.data?.data))
            dispatch(setIsloading(false));
        })
        .catch(err => {
            console.error(err)
            dispatch(setIsloading(false));
        })
    }
}

//retail page------> get mappers retail data count - params - dateFrom & dateTill are optional - default get data of currant data
const getMapperAddedRetailDataCount = (params) => {
    return dispatch => {
        dispatch(setIsloading(true));
        axios.get(MAPPER.RETAIL_DATA_COUNT, params)
        .then(res => {
            if(res?.status !== 200){

                console.log("data count retail -------------", res);
                return
            }
            dispatch(setTotalAddedRetailDataCount(getMapperTotalAddedDataCount(res?.data?.data)))
            dispatch(setMapperRetailDataCountList(res?.data?.data))
            dispatch(setIsloading(false));
        })
        .catch(err => {
            console.error(err);
            dispatch(setIsloading(false));
        })
    }
}


// get all mapper information
const getAllMapperInfo = () => {
    return dispatch => {
        axios.get(MAPPER.ALL_MAPPER)
        .then(res =>{
            if(res?.status === 200){
                const modifiedMapperList = res?.data?.data?.map(item => {
                    return {
                        user_id : item?.id,
                        name: item?.name,
                        label: item?.name,
                        value: item?.id
                    }
                }) 
                dispatch(setMapperList(modifiedMapperList))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}

// get mapper name by ID
const getMapperNameByID = (id) => {
    const mapperList = store.getState().mapper.mapperList
    const targetMapper = mapperList?.find(item => item?.user_id === id)

    return targetMapper?.name

}


export { getMapperAddedDataCount, getMapperAddedRetailDataCount, getMapperTotalAddedDataCount, getAllMapperInfo, getMapperNameByID }