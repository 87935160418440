import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// styles
import '../App.css'
import MUIDataTable from 'mui-datatables';

// mui components
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, TextField } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Pagination from '@mui/material/Pagination';

// Actions and Reducers
import { setRenderSinglePoint, setRunLoader, setSingleDataPoint } from '../Redux/Reducers/mapDataReducer'
import { setHomePanelName } from '../Redux/Reducers/commonReducer'
import { fetchPlaceByUcode } from '../utils/fetchUtils'
import StyledDateRangePicker from './StyledDateRangePicker'
import MapperDropdown from './common/MapperDropdown'
import { LoadingButton } from '@mui/lab'
import { getUpdateLogData } from '../Redux/Actions/updateLogActions'
import { setUpdateLogStartDate, setUpdateLogData } from '../Redux/Reducers/updateLogReducer'
import axios from 'axios';

// custom text input 
const CustomTextField = styled(TextField)({
    '& label': {
        marginTop: '8px',
        fontSize: '12px',
        paddingLeft: '6px'
    },
    '& label.Mui-focused': {
        margin: '8px',
        color: '#4c5976'
    },
    '& .MuiOutlinedInput-root': {
        '& input': {
            width: '100% !important',
            padding: '6px',
            fontSize: '12px'
        },
        '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 255, 50%)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#4c5976',
        },
    },
  })

class UpdateLogPanel extends React.PureComponent {
    constructor(props){
      super(props);
      this.state = {
        value: 0,
        AllFilteredData: [],
        searchKeyword: '',
        pagePath: '',
        totalPageCount: 0,
        currentPage: 1
      }
    }

    componentDidMount(){
        const { dispatch } = this.props
        dispatch(setHomePanelName('updateLog'))
    }

    componentWillUnmount(){
        const { dispatch } = this.props
        dispatch( dispatch => (
            dispatch(setHomePanelName('')),
            dispatch(setUpdateLogData([]))
        ))
    }

    // get single point data, other data remains as it was. Store in reducer other than points
    // this is to show a single point data in map and open it's custop popup data while other data should also be visible as it was. Therefore, will store data in separated reducer instead of points
    _loadSeparatedSinglePointData = (e, uCode, index) => {
        const { dispatch } = this.props
        const { currentlySelectedNode } = this.state

        if(!uCode){
            return
        }

        dispatch(setRunLoader(true));

        fetchPlaceByUcode(uCode)
            .then(data => {
                dispatch(dispatch => {
                    dispatch(setRenderSinglePoint(true))
                    dispatch(setSingleDataPoint([data]))
                    dispatch(setRunLoader(false))
                })
            })
            .catch(err => {
                dispatch(setRunLoader(false));
                console.error(err)
            })

        // unmmark selected list item
        if(currentlySelectedNode){
            currentlySelectedNode.style.backgroundColor = 'white'
        } 
        //// mark selected list item
        const domElem = e.target
        domElem.style.backgroundColor = '#c9daea'
        // // mark selected list item reference
        this.setState({
            currentlySelectedNode: domElem
        })
    }

    // search data from list
    _searchString = (e) => {
        const keyword = e.target.value.trim()
        this.setState({ 
            searchKeyword: keyword
        })
    }

    // get update data || if filter available get filtered updated data
    _getUpdatedData = () => {
        const { updateDataSocketResponse } = this.props
        const { searchKeyword } =  this.state
        const data = updateDataSocketResponse?.map((item, index) => {
            let option = ''
            // if search keyword is available
            if(searchKeyword && item?.message?.toLowerCase()?.includes(searchKeyword.toLowerCase())){
                option = <p 
                            onClick={(e) => this._loadSeparatedSinglePointData(e, item?.data?.uCode, index)} 
                            value={ item?.mapData?.uCode } key={ index } 
                            style={ STYLE.updateListStyle }
                        >
                            { item?.message }
                        </p>
            }
            // if no search keyword is available
            if(!searchKeyword){
                option = <p
                            onClick={(e) => this._loadSeparatedSinglePointData(e, item?.data?.uCode, index)} 
                            value={ item?.mapData?.uCode } key={ index } 
                            style={ STYLE.updateListStyle }
                        >
                            { item?.message }
                        </p>
            }

            return option
        })
        return data
    }

    // storing selected mapper 
    _setIndividualuser = (data) => {
        this.setState({
            currentlySelectedMapper: data
        })
    }

    // load data on filter button click
    _loadData = () => {
        const {dispatch, updateLogStartDate, updateLogEndDate } = this.props
        const { currentlySelectedMapper } = this.state 

        const params = {
            startDate: updateLogStartDate,
            endDate: updateLogEndDate,
            user_id: currentlySelectedMapper?.user_id
        }
        this.setState({
            isLoading: true
        })

        // fetchData(selectedStateDistrict, params)
        getUpdateLogData(params)
        .then(res => {
            dispatch(setUpdateLogData(res?.data))
            this.setState({
                isLoading: false,
                pagePath: res?.data?.path,
                totalPageCount: res?.data?.last_page
            })

        })
        .catch(err => {
            console.error(err)
            this.setState({
                isLoading: false
            })
        })
    } 

    // update date
    _handleDateChange = (date) => {
        const { dispatch } = this.props
        if(date?.startDate){
            dispatch(setUpdateLogStartDate(date?.startDate))
        }
        else if(date?.endDate){
            dispatch(setUpdateLogStartDate(date?.endDate))
        }
    }

    //  mui table theme
    getMuiTheme = () => createTheme({
        components: {
            MUIDataTable: {
                styleOverrides:{
                    root: {
                        paddingBottom: '30px'
                    }
                }
            },
            MUIDataTableBodyCell: {
                styleOverrides:{
                    root: {
                        marginBottom: '10px',
                        padding: '0 5px',
                        cursor: 'pointer',
                        fontSize: '12.5px',
                    },
                }
            }
        }
    })

    _changePage = (page, path) => {
        const { updateLogData, dispatch } = this.props
        axios.get(updateLogData?.path + `?page=${page}`)
        .then(res => {
            dispatch(setUpdateLogData(res?.data ?? []))
        })
        .catch(err => {
            console.error(err)
        })
    }

    _updatePage = (_, page) => {
        const {dispatch, updateLogStartDate, updateLogEndDate } = this.props
        const { pagePath, currentlySelectedMapper } = this.state 


        const params = {
            startDate: updateLogStartDate,
            endDate: updateLogEndDate,
            user_id: currentlySelectedMapper?.user_id,
            page: page
        }

        // setting loader on
        this.setState({
            isLoading: true
        })
        const paginationUrl = {
            urlWithMapper: pagePath + `?startDate=${updateLogStartDate} 00:00:00&endDate=${updateLogEndDate} 00:00:00&user_id=${currentlySelectedMapper?.user_id}&page=${page}`,
            urlWithoutMapper: pagePath + `?startDate=${updateLogStartDate} 00:00:00&endDate=${updateLogEndDate} 00:00:00&page=${page}`
        }

        // fetching data of another page
        axios.get(currentlySelectedMapper?.user_id ? paginationUrl?.urlWithMapper : paginationUrl?.urlWithoutMapper)
        .then(res => {
            this.setState({
                currentPage: page,
                isLoading: false
            })
            dispatch(setUpdateLogData( res?.data?.data ?? [] ))
        })
        .catch(err => {
            console.error(err)
            this.setState({
                isLoading: false
            })
        })
    }

    render (){
        const { updateLogStartDate, updateLogEndDate, updateLogData, mapperList } = this.props
        const { totalPageCount, isLoading, currentPage } = this.state
        const options = {
            search: true,
            selectableRows: 'none',
            download: false,
            print: false,
            viewColumns: true,
            filter: false,
            filterType: 'dropdown',
            responsive: 'simple',
            tableBodyHeight: '100%',
            tableBodyMaxHeight: '100%',
            rowsPerPage: 30,
            serverSide: true,
            count: -1,
            customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
                return (
                    <Pagination 
                        variant='outlined' 
                        shape='rounded' 
                        onChange={ this._updatePage }
                        color='primary'
                        count={ totalPageCount }
                        page={ currentPage }
                        showFirstButton 
                        showLastButton
                        sx={{ display: 'flex', justifyContent: 'center', paddingTop: '15px' }}
                    />
                )
            },
        }

        const noDataMessage = [{ 
            message: <p style={{ color: '#ed6161', textAlign: 'center', fontWeight: 'bold' }}>No Data Found !</p>,
            uCode: null,
        }]
    
        return (
            <Box sx={{ minWidth: 120 }} style={ STYLE.container }>
                <StyledDateRangePicker
                    loadDataBtn={ false }
                    downloadBtn={ false }
                    dateRange={{ start: updateLogStartDate, end: updateLogEndDate }}
                    handleDateChange={ this._handleDateChange }
                />
                <MapperDropdown
                    setMapper={ this._setIndividualuser }
                    mapperList = { mapperList }
                />
                <div style={{...STYLE.dateItem, width: '100%'}}>
                    <LoadingButton
                        className={ 'toggleBtn' } 
                        variant={'contained'}
                        size={ 'small' }
                        onClick={ this._loadData }
                        loading={ isLoading }
                        sx={ STYLE.buttonStyle } 
                        // disabled={ selectedStateDistrict ? false : true }
                    >
                        Load Update Log
                    </LoadingButton>
                </div>
                {
                    isLoading &&
                    <CircularProgress color={ 'info' } size={ 80 } sx={ STYLE.spinner }/>
                }
                <ThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                        key={updateLogData?.data?.length}
                        title={'Data list'}
                        data={ updateLogData?.data?.length > 0 ? updateLogData.data : noDataMessage }
                        columns={ columns }
                        options={ options }
                        pagination={ true }
                    />
                </ThemeProvider>

          </Box>
        )
    }
}


// table column
const columns = [
    {
        name: 'message',
        options: {
        filter: false,
        setCellHeaderProps: value => ({ style: { zIndex: 0, color: '#323f4d', padding: '5px 15px' } }),
      }
    },
    {
        name: 'uCode',
        options: {
          filter: false,
          display: false,
          setCellHeaderProps: value => ({ style: { zIndex: 0, color: '#323f4d', padding: '5px 15px' } }),
        }
    },
  
]

// table options


const STYLE = {
    container: { 
        wordBreak: 'break-word',
        overflowY: 'scroll', 
        height: `${window.innerHeight-120}px`
    },
    buttonStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '95%',
        height: '28px',
        margin: '10px auto' 
    },
    inputStyle: { 
        margin: '10px auto 0 auto', 
        display: 'flex', 
        justifyContent: 'center', 
        height: '50px', 
        width: '95%'  
    },
    updateListStyle: { 
        cursor: 'pointer', 
        fontSize: '12px', 
        borderBottom: '1px solid #939aa0', 
        margin: 0, 
        padding: '3px 0' 
    },
    dateItem: {
        width: '50%',
    },
    spinner: {
        position: 'absolute',
        top: '45%',
        left: '40%', 
        zIndex: 10 
    }
}

// Prop Types
UpdateLogPanel.propTypes = {
    updateDataSocketResponse: PropTypes.array,
    runLoader: PropTypes.bool,
    updateLogData: PropTypes.object,
    mapperList: PropTypes.array,
}
UpdateLogPanel.defaultProps = {
    updateDataSocketResponse: [],
    runLoader: false,
    updateLogData: null,
    mapperList: []
    
}

const mapStateToProps = (state) => ({
  updateDataSocketResponse: state?.mapData?.updateDataSocketResponse ?? [],
  runLoader: state?.mapData.runLoader ?? false,
  updateLogStartDate: state?.updateLog?.updateLogStartDate ?? '',
  updateLogEndDate: state?.updateLog?.updateLogEndDate ?? '',
  updateLogData: state?.updateLog?.updateLogData ?? null,
  mapperList: state?.mapper?.mapperList ?? [],

})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLogPanel);
