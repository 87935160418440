import React from 'react';
import Box from '@mui/material/Box';
import { Container, Typography, Divider, Paper } from '@mui/material';
import { MAP } from '../App.config';
import DeckGL from '@deck.gl/react';

import  { StaticMap, MapContext } from 'react-map-gl';
// import { ScatterplotLayer } from '@deck.gl/layers';

import { PolygonLayer } from "deck.gl";

// Redux
import { connect } from 'react-redux';

class DefaultTaskMap extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            mapStyle: `https://map.barikoi.com/styles/OneMap/style.json?key=${MAP.API_KEY}`,
            layers: [],
            currentLongLat: [],
            popupInfo: {
                longitude: 90.3938010872331,
                latitude: 23.82707945251465,
            },
            isPopupOpen: false,
            opneImageModal: false,
            modalImageUrl : '',
            polygonData: []
        }
    }

    componentDidMount() {
        const { currentTask } = this.props
        let polygonData = [
            {
                contours: [
                    
                ],
                name: "firstPolygon"
            }
        ];

        if(currentTask?.id){
            // formatting and extracting coordinates
            const polygonCoordinatesJSON = currentTask ? currentTask : ''
            const polygonCoordinatesObj = polygonCoordinatesJSON['ST_AsGeoJSON(task_geometry)'] ? JSON.parse(polygonCoordinatesJSON['ST_AsGeoJSON(task_geometry)']) : ''
            polygonData[0].contours = polygonCoordinatesObj.coordinates

        }

        
        const layers = new PolygonLayer({
            id: "poly-layers",
            data: polygonData,
            stroked: true,
            filled: true,
            extruded: false,
            wireframe: true,
            lineWidthMinPixels: 1,
            // getPosition: d => console.log(d),
            getPolygon: d => d.contours,
            getLineColor: [0, 0, 250],
            getFillColor: [255, 77, 77, 100],
            getLineWidth: 5,
            });

        this.setState({ 
            layers: layers,
            polygonData: polygonData[0].contours[0]
            
        })
    }


    render() {
        const viewState= {
            longitude:  this.state.polygonData ? this.state.polygonData[0] ? this.state.polygonData[0][0] : 90.3938010872331 : 90.3938010872331,
            latitude:  this.state.polygonData ? this.state.polygonData[0] ?  this.state.polygonData[0][1] : 23.82707945251465 : 23.82707945251465,
            zoom: 12,
            pitch: 0,
            bearing: 0
        }
        const { mapStyle, layers } = this.state

        return (
                <Box style={ boxStyle }>
                    <Container style={ containerStyle }>
                        {/* <Paper> */}
                        <Paper>
                            <Typography variant={ 'subtitle1' } sx={ mapTitle }>Draw Area</Typography>
                        </Paper>
                        <Divider sx={{ borderColor: '#d4d4d4', marginBottom: '10px' }}/>
                            {/* <h2 style={{  marginTop: '0', fontWeight: 'bold', backgroundColor: 'green' }}>Draw Area </h2> */}
                        {/* </Paper> */}
                        <Container maxWidth="100%">
                            <DeckGL
                                initialViewState={viewState}
                                controller={true}
                                layers={layers}
                                ContextProvider={MapContext.Provider}
                                // style={{position: 'relative', width: "100%" ,height: "85vh", marginBottom: '10px'}}
                                style={ mapViewport }
                                
                                
                            >
                                <StaticMap
                                    mapStyle={mapStyle}
                                />

                            </DeckGL>
                        </Container>
                    </Container>
                       
                </Box>
        )
    }
}

const mapViewport = {
    position: 'relative', 
    width: '100%',
    height: `${window.innerHeight - 180}px`,
    boxShadow: '0 0 3px #919191',
}
const boxStyle = { 
    padding: '12px', 
    borderRadius: '5px',
    backgroundColor: '#f4f4f4'
  }
 const containerStyle = {
    position: 'relative', 
    maxWidth: '100%', 
    color: '#319688'
  }
const mapTitle = {
    marginTop: '0', 
    textAlign: 'center', 
    fontWeight: 'bold', 
    backgroundColor: '#ffffff', 
    padding: '5px',
    color: '#2ba08f'
  }

// export default TaskMap;

const mapStateToProps = (state) => ({
  taskCreationCoordinates: state?.mapData?.taskCreationCoordinates ?? []
})


const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTaskMap);