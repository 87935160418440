import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import DeckGL from '@deck.gl/react';
import { StaticMap, Popup, MapContext } from 'react-map-gl';
import { GeoJsonLayer, PolygonLayer, ScatterplotLayer, IconLayer } from '@deck.gl/layers';
import _isEqual from 'fast-deep-equal'

import { polygonSample } from '../../utils/multipolygon'


// loading spinner
import Loader from "react-js-loader";

// styles
import '../../App.css'

import { MAP } from '../../App.config';
import { CircularProgress } from '@mui/material';


const pTypeColor = {
    Shop: [255,105,180],
    Food: [0, 43, 217],
    Commercial: [22, 208, 50],
    Construction: [255, 0, 0],
    'Not Available': [174, 201, 0],
    "Others": [255,127,0]
}

class ReusableMapGL extends React.PureComponent {
    state = {
        viewState: {
            longitude: 90.3938010872331,
            latitude: 23.82707945251465,
            zoom: 10,
            pitch: 0,
            bearing: 0,
        },
        mapStyle: `https://geoserver.bmapsbd.com/styles/one-map/style.json?key=${MAP.API_KEY}`,
        layers: [],
        popupInfo: {
            longitude: 90.3938010872331,
            latitude: 23.82707945251465,
        },
        divisionPolygon: null,
        isPopupOpen: false,
        opneImageModal: false,
        modalImageUrl : '',
        showSinglePointOnTaskListClick: false, // this is used only after task is clicked from loader panel tasklist
    }

    componentDidMount(){
        const { geojsonProps } = this.props
        // if geojson is already available => create layer
        if(geojsonProps?.length){
            this._createPolygon(geojsonProps)
        }
    }

    componentDidUpdate(prevProps){
        const { geojsonProps } = this.props

        // if geojson data gets updated => create new layer
        if(!_isEqual(prevProps.geojsonProps, geojsonProps)){
            this._createPolygon(geojsonProps)
        }
    }

    _createPolygon = (geojsonProps) => {
        const { coordinates, polygon } = this.props

        // if(!_isEqual(prevProps.coordinates, coordinates)){
            // const data = [
            //     {
            //         // Simple polygon (array of coords)
            //         contour: coordinates?.polygon,
            //         zipcode: 94107,
            //         population: 26599,
            //         area: 6.11
            //     }
            // ]
            // this.setState({
            //     divisionPolygon: data
            // })
            if(polygon){
                this._createPolygon()
            }
        // }
    }

    _createPolygon = () => {
        const { polygon, geojsonProps } = this.props

        const layer = geojsonProps?.map(item => (
            new GeoJsonLayer({   
            id: 'GeoJsonLayer',
            data: item,
            /* props from GeoJsonLayer class */
            
            // elevationScale: 1,
            extruded: false, // this must be false to render outline
            filled: true,
            getElevation: 0,
            getFillColor: [0, 0, 255, 75],
            // getIconAngle: 0,
            // getIconColor: [0, 0, 0, 255],
            // getIconPixelOffset: [0, 0],
            // getIconSize: 1,
            // getLineColor: f => {
            //   const hex = f.properties.color;
            //   // convert to RGB
            //   return hex ? hex.match(/[0-9a-f]{2}/g).map(x => parseInt(x, 16)) : [0, 0, 0];
            // },
            getLineColor: [ 255, 0, 255 ],
            getLineWidth: 20,
            getPointRadius: 4,
            getText: f => f.properties.name,
            // getTextAlignmentBaseline: 'center',
            // getTextAnchor: 'middle',
            // getTextAngle: 0,
            // getTextBackgroundColor: [255, 255, 255, 255],
            getTextBorderColor: [0, 0, 0, 255],
            // getTextBorderWidth: 0,
            // getTextColor: [0, 0, 0, 255],
            // getTextPixelOffset: [0, 0],
            getTextSize: 12,
            // iconAlphaCutoff: 0.05,
            // iconAtlas: null,
            // iconBillboard: true,
            // iconMapping: {},
            // iconSizeMaxPixels: Number.MAX_SAFE_INTEGER,
            // iconSizeMinPixels: 0,
            // iconSizeScale: 1,
            // iconSizeUnits: 'pixels',
            // lineBillboard: false,
            // lineCapRounded: true,
            // lineJointRounded: true,
            // lineMiterLimit: 4,
            // lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
            lineWidthMinPixels: 2,
            lineWidthScale: 1,
            // lineWidthUnits: 'meters',
            // material: true,
            // pointAntialiasing: true,
            // pointBillboard: false,
            // pointRadiusMaxPixels: Number.MAX_SAFE_INTEGER,
            // pointRadiusMinPixels: 0,
            // pointRadiusScale: 1,
            pointRadiusUnits: 'pixels',
            pointType: 'circle+text',
            stroked: true,
            // textBackground: false,
            // textBackgroundPadding: [0, 0, 0, 0],
            // textBillboard: true,
            // textCharacterSet: [' ', '!', '"', '#', '$', '%', '&', ''', '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', '@', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '[', '\', ']', '^', '_', '`', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '{', '|', '}', '~', ''],
            // textFontFamily: 'Monaco, monospace',
            // textFontSettings: {},
            // textFontWeight: 'normal',
            // textLineHeight: 1,
            // textMaxWidth: -1,
            // textOutlineColor: [0, 0, 0, 255],
            // textOutlineWidth: 0,
            // textSizeMaxPixels: Number.MAX_SAFE_INTEGER,
            // textSizeMinPixels: 0,
            // textSizeScale: 1,
            // textSizeUnits: 'pixels',
            // textWordBreak: 'break-word',
            // wireframe: false,
            
            /* props inherited from Layer class */
            
            autoHighlight: false,
            // coordinateOrigin: [0, 0, 0],
            // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
            // highlightColor: [0, 0, 128, 128],
            // modelMatrix: null,
            // opacity: 1,
            pickable: true,
            // visible: true,
            // wrapLongitude: false,

            

          })
        ))

        this.setState({
            layers: layer
        }) 
    }


    

    

    render() {
        const { viewState, mapStyle, layers } = this.state
        const { coordinates, test } = this.props
        // console.log({ coordinates })
        return (
            <div>
                <DeckGL
                    initialViewState={ viewState }
                    // onViewStateChange={ this._handleViewStateChange }
                    controller={ true }
                    layers={ layers }
                    ContextProvider={ MapContext.Provider }
                    // getTooltip={{(a, b, c)} => console.log({ a, b, c })}
                >
                    <StaticMap
                        mapStyle={ mapStyle }
                    />
                    
                </DeckGL>
            </div>
        )
    }
}

// Prop Types
ReusableMapGL.propTypes = {
    geojsonProps: PropTypes?.array
}
ReusableMapGL.defaultProps = {
    geojsonProps: []
}

const mapStateToProps = (state) => ({
    // geojsonProps: state?.overview?.geojsonData
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(ReusableMapGL)