import React from 'react';
import {Alert, Divider, SvgIcon, Button } from '@mui/material';

// image zoom
import Viewer from 'react-viewer';

// custom styles
import '../App.css'

// Components
import UpdateData from './UpdateData';

// utils
import {BASE_API, PAGES} from '../App.config'
import { getMapperNameByID } from '../Redux/Actions/mapperAction';
import {fetchPtype, fetchSubType} from '../utils/fetchUtils'
import { DeleteOutline, ImportContacts } from '@mui/icons-material';
import { connect } from 'react-redux';
import UpdateDataRetail from './UpdateDataRetail';
import { deleteImage } from '../Redux/Actions/mapDataActions';
import UpdateDataCnl from './UpdateDataCnl';


const dataImgs = [ "https://api.bmapsbd.com/storage/places/ext_tmp/tmp_place_image/place_ITUU9511_YDr3O.jpg", 
  "https://api.bmapsbd.com/storage/places/ext_tmp/tmp_place_image/place_ITUU9511_YDr3O.jpg"
]


class CustomPopup extends React.PureComponent {
    state = {
      isUpdateModalOpen: false,
      isUpdateSuccessful: '',
      currentPointData: {},
      opneImageModal: false,
      modalImageUrl: '',
      pTypeOption: [],
      allSubTypeOption: [],
      currentSubTypeOption: [],
      imgs:[],
      hasImageDeleted: false,
    }

        //  get images of certain place

   

  componentDidMount(){
    const { info } = this.props

    //  get all images of a place
    let imgUrlList = [];

    info.images.map((item) => {
      // for mapper and retail api ----> image_link
      if(item.image_link){
        imgUrlList.push({ src: `${BASE_API}/${item?.image_link}`, alt:''})
      }
      // for cnl api ---- image_url
      else if(item?.image_url){
      imgUrlList.push({ src: `${item?.image_url}`, alt:''})
      }
    })
    
    this.setState({
      // ...this.state,
      currentPointData: info,
    })

    this.setState({
      imgs: imgUrlList
    })
  }



  componentDidUpdate(prevProps, prevState){
    const { info, pageName} = this.props
    const { currentPointData, pTypeOption, currentSubTypeOption, imgs, modalImageUrl } = this.state

    // if pType data is not fetched
    if(pTypeOption?.length === 0){
      fetchPtype()
      .then(data => {
          
          let ptypeOptionArray = [];
          
          data.map((item)=>(
              ptypeOptionArray.push({value: item.type, label: item.type})
          ))
        
          this.setState({
              pTypeOption: ptypeOptionArray,
          })
      

      })
      .catch(err => console.error(err))
    }

    //  if subType data is not fetched
    if(currentSubTypeOption?.length === 0 && pageName !== PAGES.CNL){
      fetchSubType()
      .then(data => {


          const pTypeArray = currentPointData?.pType?.slice().split(',');
          let subTypeOptionArray = []

          // checking pType similary to include current dropdown subType option for UpdateData component
          data?.forEach(subItem =>(
              pTypeArray?.forEach(pTypeItem=> (
                  subItem?.type === pTypeItem ? subTypeOptionArray?.push({value: subItem?.subtype, label: subItem?.subtype }) : ''
              ))
          ))

          this.setState({
              allSubTypeOption: data,
              currentSubTypeOption: subTypeOptionArray
              
          })
      })
      .catch(err => console.error(err))
    }

    // checking data update or not - by checking current point with previous point id
    if(currentPointData?.id !== info?.id){
      
      //  get all images of a place
      let imgUrlList = [];

      info.images.map((item) => {
        imgUrlList.push({ src: `${BASE_API}/${item?.image_link}`, alt:''})
      })

      this.setState({
        // ...this.state,
        currentPointData: info,
        imgs: imgUrlList
      })
    }

    if(prevState.imgs.length !== imgs){
      this.setState({
        imgs: imgs
      })
    }
    
  }

  componentWillUnmount(){
    this.setState({
    })
  }

  // open/close data update form
  _modalToggler = () => {
    this.setState((prevState) => ({
        isUpdateModalOpen: !this.state.isUpdateModalOpen
    }))
}

// success and failure toast
// option - boolean
  _popupNotification = (option) => { 
    this.setState({
      isUpdateSuccessful: option
    }) 
    setTimeout(() => {
      this.setState({
        isUpdateSuccessful: ''
      }) 

    }, 2500);
  }


  // update current point information received from UpdateData component
  _updateNewInfo = (newInfo) => {
    this.setState({
      // ...this.state,
      currentPointData: {
        ...newInfo
      } 
    })
  }


  // show image modal
  _showImageModal(e, path){ 
    const { modalImageUrl } = this.state
        
    if(modalImageUrl === path ){
      this.setState({
          opneImageModal: !this.state.opneImageModal,
          modalImageUrl: ''
      })
        
    }
    else if(modalImageUrl === ''){
        this.setState({
            opneImageModal: !this.state.opneImageModal,
            modalImageUrl: path
        })
    }
    else{
        this.setState({
            modalImageUrl: path
        })
    }
  }

  // close image slider
  _closeSlider = () => {
    this.setState({
        opneImageModal: false,
        modalImageUrl: ''
    })
  }

  _handleDeleteImg = (e, id, index) => {
    const {dispatch, info, popupToast} = this.props
    const {imgs} = this.state

    dispatch( deleteImage( id ))
    .then(res => {
      // if(res?.length > 0){
          // dispatch(setFilteredData(res))
      // }
      alert("image Successfully Deleted")

      // this.setState({
      //     isLoading: false
      // })
      //  get all images of a place

      let imgUrlList = imgs;

      imgUrlList.splice(index, 1);

      this.setState({
        // ...this.state,
        imgs: imgUrlList 
      })

      this.setState({
        hasImageDeleted: true,
      }, () => {
        alert("image Successfully Deleted")
      })

    })
    .catch(err => {
        console.error(err)
        // this.setState({
        //     isLoading: false
        // })

        this.setState({
          hasImageDeleted: false,
        }, () => {
          alert("Error Deleted")
        })
    })
  }



  
  
  render() {
    const { info, pageName } = this.props
    const { currentPointData, isUpdateSuccessful, opneImageModal, modalImageUrl, isUpdateModalOpen, allSubTypeOption, pTypeOption, currentSubTypeOption, imgs } = this.state
    
    return (
      <div style={{height: `${window.innerHeight-106}px`, width: '35vw !important'}}>
        {
          isUpdateSuccessful ? <Alert style={{position: 'absolute', left: '100px', top: '200px', fontWeight: 'bold' ,fontSize: '14px', backgroundColor: '#7dffaf'}} severity='success'>Updated Successfully!</Alert> : isUpdateSuccessful  === false ? <Alert style={{position: 'absolute', left: '100px', top: '200px', fontWeight: 'bold', color: 'white' ,fontSize: '14px' , backgroundColor: '#ff3d3d'}} severity='error'>Failed to update!</Alert> : ''
        }
        <h4 style={{padding: '0 10px', margin: 0}}>NAME: { info?.business_name ? info.business_name : info.outlet_name ? info.outlet_name : 'Name Not Available' }</h4>
        <p style={{paddingLeft: '10px', fontSize: '11px', margin: 0, color: '#319688'}}>Added by : {getMapperNameByID(info?.user_id) ?? info?.user_name}</p>
        <div>
        {/* { (pageName !== PAGES.CNL) ?  */}
          <Button 
            variant={ 'contained' }
            color={ 'text' } 
            // size={ 'small' }
            style={ buttonStyle } 
            onClick={this._modalToggler}
            startIcon={ <ImportContacts /> }
          >
            { isUpdateModalOpen ? 'Close Update Panel ' : 'Open Update Panel ' }
            { pageName }
          </Button>
          {/* : ""
        } */}
          {
            isUpdateModalOpen &&
              <div>
                { (pageName === PAGES.RETAIL) ?                 
                <UpdateDataRetail
                  updateInfo={(newInfo) => this._updateNewInfo(newInfo)}  
                  popupToast={(option) => this._popupNotification(option)} 
                  dataUpdatePanelToggler ={this._modalToggler} 
                  info={currentPointData} 
                  pTypeOptionProp={ pTypeOption }
                  allSubTypeOptionProp={ allSubTypeOption }
                  currentSubTypeOptionProp={ currentSubTypeOption }
                />
                :  (pageName === PAGES.CNL) ?   
                <UpdateDataCnl
                  updateInfo={(newInfo) => this._updateNewInfo(newInfo)}  
                  popupToast={(option) => this._popupNotification(option)} 
                  dataUpdatePanelToggler ={this._modalToggler} 
                  info={currentPointData} 
                  pTypeOptionProp={ pTypeOption }
                  allSubTypeOptionProp={ allSubTypeOption }
                  currentSubTypeOptionProp={ currentSubTypeOption }
                />  
                :       
                <UpdateData 
                  updateInfo={(newInfo) => this._updateNewInfo(newInfo)}  
                  popupToast={(option) => this._popupNotification(option)} 
                  dataUpdatePanelToggler ={this._modalToggler} 
                  info={currentPointData} 
                  pTypeOptionProp={ pTypeOption }
                  allSubTypeOptionProp={ allSubTypeOption }
                  currentSubTypeOptionProp={ currentSubTypeOption }
                />
                }
                </div>
            }
        </div>
          
        <React.Fragment>
          <div className='test' style={ bodyStyles }>
              <div style={imageContainer}>
              {currentPointData?.images ? 
                currentPointData.images.map((item, index)=>       
                  <div key={item?.id} 
                    style={{display: "flex", flexDirection: 'column'}}>

                    {   
                      //  for mapper and reatail api - image_link
                      item.image_link ?    
                        <img 
                          onClick={(e)=> this._showImageModal(e, `${BASE_API}/${item?.image_link}`)}
                          // key={item?.id} 
                          style={{display: 'block', borderRadius: '5px', padding: '3px'}} width='120px' height='80px' 
                          src={`${BASE_API}/${item?.image_link}`} 
                          alt='shop'  
                        /> 
                      : 
                        // for cnl api - image_url
                          <img 
                            onClick={(e)=> this._showImageModal(e, `${item?.image_url}`)}
                            // key={item?.id} 
                            style={{display: 'block', borderRadius: '5px', padding: '3px'}} width='120px' height='80px' 
                            src={`${item?.image_url}`} 
                            alt='shop'  
                          /> 
                    }
                    {
                      isUpdateModalOpen && 
                      <Button 
                      // variant={ 'contained' }
                      // color={ 'text' } 
                      // key={item?.id} 
                      size={ 'small' }
                      // style={ buttonStyle } 
                      value={item?.id}
                      onClick={(e)=> this._handleDeleteImg(e, item?.id, index)}
                      // onClick={(e) => this._handleDeleteImg(e, item?.id)}
                      // onChange={this._handleDeleteImg}
                      startIcon={ <DeleteOutline /> }
                    >
                    </Button>
                    }                      
                  </div>  
                      ) 
                    : ''}
              </div>


            <table>
              <tbody>
              {/* showing all information(received from MapGL) of a single point */}

                {currentPointData && Object?.keys(currentPointData)?.map((item) => (
                  (
                    item !== 'id' &&
                    item !== 'device_info' && 
                    item !== 'user_id' && 
                    item !== 'private_public_flag' &&
                    item !== 'complete_incomplete_flag' && 
                    item !== 'temporary_permanent_flag' && 
                    item !== 'longitude' &&
                    item !== 'latitude' && 
                    item !== 'popularity_ranking'
                  ) ?
                    <tr className='popup-tr' key={item}>
                      <td className='popup-row-dt' style={{ ...tdStyles, width: '220px', whiteSpace: 'no-wrap' }}>
                      <SvgIcon sx={{ fontSize: 15 }}>
                            <path d='M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z' />
                      </SvgIcon>
                        <strong>{ item.toUpperCase() }</strong>
                      </td>
                      <td className='popup-row-dt' style={ tdStyles }>
                        {
           
                          item === 'characteristics' && currentPointData[item] ? 
                            JSON.parse(currentPointData[item]).map((element, index) => <div key={index} style={subColumnContainerStyle}><p style={subColumnNameStyle}>{element?.key.split('_').join(' ').toUpperCase()}</p><Divider orientation='vertical' variant='middle' flexItem >:</Divider><p style={{flex: '1', paddingLeft: '5px' , margin: '5px auto', wordBreak: 'break-word'}}>{element.value}</p></div>)
                            :
                            item === 'retail_data_category' && currentPointData[item] ?
                              JSON.parse(currentPointData[item]).map((element, index) => <div key={index} style={subColumnContainerStyle}><p style={subColumnNameStyle}>{element?.category?.toUpperCase()}</p><Divider orientation='vertical' variant='middle' flexItem >:</Divider><p style={{flex: '2', paddingLeft: '5px', margin: '5px auto', wordBreak: 'break-word' }}>{element?.sku_type.map(sku => sku)}</p></div>)
                              :
                              item === 'contact' && currentPointData[item] ? 
                                JSON.parse(currentPointData[item]).map((element, index) => <div key={index} style={subColumnContainerStyle}><p style={subColumnNameStyle}>{element?.key.split('_').join(' ').toUpperCase()}</p><Divider orientation='vertical' variant='middle' flexItem >:</Divider><p style={{flex: '1', paddingLeft: '5px', margin: '5px auto', wordBreak: 'break-word' }}>{element.value}</p></div>)
                                :
                                item === 'amenities' && currentPointData[item] ? 
                                  JSON.parse(currentPointData[item]).map((element, index) => <div key={index} style={subColumnContainerStyle}><p style={subColumnNameStyle}>{element?.key.split('_').join(' ').toUpperCase()}</p><Divider orientation='vertical' variant='middle' flexItem >:</Divider><p style={{flex: '1', paddingLeft: '5px', margin: '5px auto', wordBreak: 'break-word' }}>{element.value}</p></div>)
                                  :
                                  item === 'floor_level_flat' && currentPointData[item] ? 
                                    JSON.parse(currentPointData[item]).map((element, index) => <div key={index} style={subColumnContainerStyle}><p style={subColumnNameStyle}>{element?.key.split('_').join(' ').toUpperCase()}</p><Divider orientation='vertical' variant='middle' flexItem >:</Divider><p style={{flex: '1', paddingLeft: '5px', margin: '5px auto', wordBreak: 'break-word' }}>{element.value}</p></div>)
                                    :
                                    // item === 'is_shop_open' && (currentPointData?.pType?.toLowerCase()?.includes('food') || currentPointData?.pType?.toLowerCase()?.includes('shop')) ?
                                    // currentPointData[item] ? 'Open' : 'Close'
                                    //     :
                                        item === 'is_shop_open' ?
                                        (currentPointData[item]===1) ? 'Yes' : 'No'
                                            :
                                        item === 'is_buyer' ?
                                        (currentPointData[item]===1) ? 'Buyer' : 'Non Buyer'
                                            :
                                            item === 'is_trade_licence' ?
                                            (currentPointData[item]===1) ? 'Yes' : 'No'
                                                :
                                                item !== 'images' &&
                                                currentPointData[item] ? currentPointData[item] : 
                                                  item === 'images' ? '-' : 'Not Available'
                              
                        }
                      </td>
                    </tr>
                    :
                    <tr key={item}></tr>
                ))}

                        <Viewer
                          visible={opneImageModal}
                          onClose={ this._closeSlider }
                          zoomSpeed={ 0.5 }
                          images={[{src: modalImageUrl, alt: ''}]}
                        />
                <br/>
                <br/>
              </tbody>
            </table>

          </div>
        </React.Fragment>
      </div>
    );
  }
}

const buttonStyle = {
  padding: '4px 20px',
  margin: '10px 5px 5px 0',
  border: '1px solid #24242b56',
  borderRadius: '5px',
  fontWeight: 'bold',
  backgroundColor: '#ffff',
}

const imageContainer = {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '40px'
}

const bodyStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: '8px 0px 0px 0px',
  overflow: 'hidden',
  fontSize: '10px'
}

const tdStyles = {
  boxSizing: 'border-box',
  verticalAlign: 'top',
  padding: '0px 4px',
  width: '100%',
  maxWidth: '450px'

}

const subColumnContainerStyle = {borderBottom: '1px solid #e5e5e5', display: 'flex', alignItems: 'center'}
const subColumnNameStyle = {color: 'green', flex: '1', paddingRight: '5px', margin: '5px auto'}

const mapStateToProps = (state) => ({
  pageName: state?.common.pageName ?? '',
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps) (CustomPopup);