// import React from 'react';
import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Grid, Paper } from '@mui/material';

// Conponent 
import TaskListTable from './TaskListTable';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


class TaskList extends React.PureComponent {

    render() {
        
        return (
            <Box sx={{ flexGrow: 1, minHeight: `${window.innerHeight-65}px`, backgroundColor: '#E8E9EF' }}>
                <h3 style={{ color: '#323f4d', display: 'block', textAlign: 'center', paddingTop: '10px', margin: '0 auto 10px auto'}}>Task List</h3>
                <Grid container style={{height: '100%', margin: '0 !important'}} columns={{ xs: 12, sm: 12, md: 12 }}>
                    <Grid  item xs={ 12 } sm={ 12 } md={ 12 }>
                        <Item key={ 1 } style={{ width: '90%', maxWidth: '1120px', margin: '0 auto'}}>
                            <TaskListTable />
                        </Item>
                    </Grid>
                </Grid>
          </Box>

        )
    }
}


export default TaskList;
