import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cnlMapperList: [],
    addedShopCount: 0,
    updatedShopCount: 0,
    teritories:[],
    routes: [],
    outletCategories:  [],
    outletTypes: [],
    // outletCategories: [],
    marketOpportunities: [],
    startDateType: 0,
}


const cnlSlice = createSlice({
    name: 'cnl',
    initialState,
    reducers: {
        setCnlMapperList: (state, action) => {
            state.cnlMapperList = action.payload
        },
        setAddedShopCount: (state, action) => {
            state.addedShopCount = action.payload
        },
        setUpdatedShopCount: (state, action) => {
            state.updatedShopCount = action.payload
        },
        setCnlTeritories: (state, action) => {
            state.teritories = action.payload
        },
        setCnlRoutes: (state, action) => {
            state.routes = action.payload
        },
        setOutletTypes: (state, action) => {
            state.outletTypes =  action.payload
        },
        setOutletCategories: (state, action) => {
            state.outletCategories =  action.payload
        },
        setMarketOpportunities: (state, action) => {
            state.marketOpportunities =  action.payload
        },
        setStartDateType: (state, action) => {
            state.startDateType =  action.payload
        },       

    
    }
})

export const { 
    setCnlMapperList, 
    setAddedShopCount,
    setUpdatedShopCount,
    setOutletTypes,
    setOutletCategories,
    setMarketOpportunities,
    setCnlTeritories,
    setCnlRoutes,
    setStartDateType
} = cnlSlice.actions
export default cnlSlice.reducer