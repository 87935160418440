import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import DeckGL from '@deck.gl/react';
import { StaticMap, Popup, MapContext } from 'react-map-gl';
import { ScatterplotLayer, IconLayer } from '@deck.gl/layers';
import _isEqual from 'fast-deep-equal'


// loading spinner
import Loader from "react-js-loader";

// styles
import '../App.css'

import { MAP, PAGES } from '../App.config';

import CustomPopup from './CustomPopup';
import { setPoints, setRenderSinglePoint, setRunLoader, setSingleDataPoint } from '../Redux/Reducers/mapDataReducer';
import { stackLoadedAndSocketData, fetchData, fetchRetailData, fetchCnlData } from '../Redux/Actions/mapDataActions'
import { getAllMapperInfo } from '../Redux/Actions/mapperAction'
import { fetchCnlPlaceByUcode, fetchPlaceByUcode, fetchRetailPlaceByUcode } from '../utils/fetchUtils'
import { getGeneratedTaskMessage, getGeneratedTaskMessageCnl } from '../Redux/Actions/commonAction'
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { LayersClearOutlined, LayersOutlined } from '@mui/icons-material';

const pTypeColor = {
    Shop: [255,105,180],
    Food: [0, 43, 217],
    Commercial: [22, 208, 50],
    Construction: [255, 0, 0],
    'Not Available': [174, 201, 0],
    "Others": [255,127,0]
}

class MapGL extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
            viewState: {
                longitude: 90.3938010872331,
                latitude: 23.82707945251465,
                zoom: 10,
                pitch: 0,
                bearing: 0,
            },
            mapStyle: `https://geoserver.bmapsbd.com/styles/one-map/style.json?key=${MAP.API_KEY}`,
            availableStyle: {
                default: `https://geoserver.bmapsbd.com/styles/one-map/style.json?key=${ MAP.API_KEY }`,
                satellite: `mapbox://styles/mapbox/satellite-v9`
            },
            layers: [],
            popupInfo: {
                longitude: 90.3938010872331,
                latitude: 23.82707945251465,
            },
            isPopupOpen: false,
            opneImageModal: false,
            modalImageUrl : '',
            showSinglePointOnTaskListClick: false, // this is used only after task is clicked from loader panel tasklist
        }
    }

    componentDidMount() {
        const { dispatch, startDate, endDate, pageName} = this.props
        const params = {
            startDate,
            endDate
        }

        this._popupClose();
        dispatch(setRunLoader(true))

        // fetching through api call to load data of current date
        dispatch(getAllMapperInfo())


        //loads data from retail API if in retail page, else from common mapper API
        // if(pageName === PAGES.RETAIL){
        //     console.log("API hit to ----- retail")
        //     fetchRetailData(null, params)
        //     .then(data => {
        //         const transformedIntoMessagetemplate = data?.map(item => { 
        //             const messageTemplate = getGeneratedTaskMessage(item)
        //             return {
        //                 mapData: item,
        //                 message: messageTemplate
        //             }
        //         })
        //         dispatch(setPoints(transformedIntoMessagetemplate))
        //         this._createLayer(data)
        //         dispatch(setRunLoader(false))    
        //     })
        //     .catch(err => {
        //         console.error(err)
        //         dispatch(setRunLoader(false))
        //     })
        // }else{
        //     console.log("API hit to ----- mapper");
        //     fetchData(null, params)
        //     .then(data => {
        //         const transformedIntoMessagetemplate = data?.map(item => { 
        //             const messageTemplate = getGeneratedTaskMessage(item)
        //             return {
        //                 mapData: item,
        //                 message: messageTemplate
        //             }
        //         })
        //         dispatch(setPoints(transformedIntoMessagetemplate))
        //         this._createLayer(data)
        //         dispatch(setRunLoader(false))    
        //     })
        //     .catch(err => {
        //         console.error(err)
        //         dispatch(setRunLoader(false))
        //     })
        // }


    }
    
    componentDidUpdate(prevProps, prevState) {
        const { dispatch, points, socketDataPoints, socketRetailDataPoints, selectedDistrict, selectedMapperID, renderSinglePoint, singleDataPoint, filteredData, homePanelName, startDate, endDate, pageName } = this.props
        const { popupInfo, isPopupOpen, layers } = this.state

        if(isPopupOpen){
            this._renderPopup(popupInfo) 
        }

        var params = {
            startDate,
            endDate
        }

        if(prevProps.pageName !== pageName){
                    //loads data from retail API if in retail page, else from common mapper API
                    if(pageName === PAGES.RETAIL){
                        fetchRetailData(null, params)
                        .then(data => {
                            const transformedIntoMessagetemplate = data?.map(item => { 
                                const messageTemplate = getGeneratedTaskMessage(item)
                                return {
                                    mapData: item,
                                    message: messageTemplate
                                }
                            })
                            dispatch(setPoints(transformedIntoMessagetemplate))
                            this._createLayer(data)
                            dispatch(setRunLoader(false))    
                        })
                        .catch(err => {
                            console.error(err)
                            dispatch(setRunLoader(false))
                        })
                    }else if(pageName === PAGES.CNL){
                        params = {
                            startDate,
                            endDate,


                        }
                        fetchCnlData(null, params)
                        .then(data => {
                            const transformedIntoMessagetemplate = data?.map(item => { 
                                const messageTemplate = getGeneratedTaskMessageCnl(item)
                                return {
                                    mapData: item,
                                    message: messageTemplate
                                }
                            })
                            dispatch(setPoints(transformedIntoMessagetemplate))
                            this._createLayer(data)
                            dispatch(setRunLoader(false))    
                        })
                        .catch(err => {
                            console.error(err)
                            dispatch(setRunLoader(false))
                        })
                    }else{
                        fetchData(null, params)
                        .then(data => {
                            const transformedIntoMessagetemplate = data?.map(item => { 
                                const messageTemplate = getGeneratedTaskMessage(item)
                                return {
                                    mapData: item,
                                    message: messageTemplate
                                }
                            })
                            dispatch(setPoints(transformedIntoMessagetemplate))
                            this._createLayer(data)
                            dispatch(setRunLoader(false))    
                        })
                        .catch(err => {
                            console.error(err)
                            dispatch(setRunLoader(false))
                        })
                    }
        }

        // if(pageName === PAGES.RETAIL){
        //     const { points, socketRetailDataPoints, selectedDistrict, selectedMapperID } = this.props

        //     // filtering data to render in map *Necessary to send all params in function
        //     const updatedMapData = stackLoadedAndSocketData(points, socketRetailDataPoints, selectedDistrict, selectedMapperID).mapData

        //     this._createLayer(updatedMapData)
        //     dispatch(setRunLoader(false))
        // }



        // render filter data only. if filter tab is open 
        if(homePanelName === 'filter' ){
            if(!_isEqual(prevProps.filteredData, filteredData)){
                this._createLayer(filteredData)
                dispatch(setSingleDataPoint([]))
                this._popupClose()
                return
            }
            if(!_isEqual(prevProps.singleDataPoint, singleDataPoint)){
                this._createLayer(filteredData)
            }
             // // if renderSinglePoint is true and different custom popup will be opened
            if(prevProps.singleDataPoint === singleDataPoint && singleDataPoint?.length > 0){ 
                this.setState({
                    showSinglePointOnTaskListClick: true,
                })
            }
            
            return
        }

        if(prevProps.pageName !== pageName && pageName === PAGES.RETAIL && pageName === PAGES.CNL ){
            const { points, socketRetailDataPoints, selectedDistrict, selectedMapperID } = this.props

            // filtering data to render in map *Necessary to send all params in function
            const updatedMapData = stackLoadedAndSocketData(points, socketRetailDataPoints, selectedDistrict, selectedMapperID).mapData

            this._createLayer(updatedMapData)
            dispatch(setRunLoader(false))

        }


        // home panel changed
        if(prevProps.homePanelName !== homePanelName){
            // render filter data only. if loader tab is open 
            
            this._popupClose()
            if(homePanelName === 'loader'){
                const { points, socketDataPoints, selectedDistrict, selectedMapperID } = this.props

                // filtering data to render in map *Necessary to send all params in function
                const updatedMapData = stackLoadedAndSocketData(points, socketDataPoints, selectedDistrict, selectedMapperID).mapData

                this._createLayer(updatedMapData)
                dispatch(setRunLoader(false))
            }
            else if(homePanelName === 'updateLog'){
                const { points, socketDataPoints, selectedDistrict, selectedMapperID } = this.props

                // filtering data to render in map *Necessary to send all params in function
                const updatedMapData = stackLoadedAndSocketData(points, socketDataPoints, selectedDistrict, selectedMapperID).mapData

                this._createLayer(updatedMapData)
                dispatch(setRunLoader(false))
                    
            }
            else{
                this._createLayer([])
            }

            return
        }
        
        // checking if there are data by accessing index and  if index has a id of data 
        // if((points[0] && points[0].id) || socketDataPoints?.length > 0 || prevProps.selectedDistrict !== selectedDistrict || prevProps.selectedMapperID !== selectedMapperID || prevProps.renderSinglePoint !== renderSinglePoint ){
            // checking deep copy of data to ensure new data to render
            if (
                    !_isEqual(prevState.layers, layers) || 
                    !_isEqual(prevProps.points, points) || 
                    !_isEqual(prevProps.singleDataPoint, singleDataPoint,) ||
                    prevProps.socketDataPoints?.length !== socketDataPoints?.length ||
                    prevProps.socketRetailDataPoints?.length !== socketRetailDataPoints?.length ||
                    prevProps.selectedDistrict !== selectedDistrict ||
                    prevProps.selectedMapperID !== selectedMapperID ||
                    prevProps.renderSinglePoint !== renderSinglePoint
                ){
                
                // filtering data to render in map *Necessary to send all params in function


                // plot all retail places in map -----> retail socket data
                if(pageName === PAGES.RETAIL){
                    const updatedMapData = stackLoadedAndSocketData(points, socketRetailDataPoints, selectedDistrict, selectedMapperID, renderSinglePoint).mapData

                    this._createLayer(updatedMapData)
                    dispatch(setRunLoader(false))
                }else  if(pageName === PAGES.CNL){
                    const updatedMapData = stackLoadedAndSocketData(points, socketRetailDataPoints, selectedDistrict, selectedMapperID, renderSinglePoint).mapData

                    this._createLayer(updatedMapData)
                    dispatch(setRunLoader(false))
                }else{
                    // plot all type places in map -----> socket data
                    const updatedMapData = stackLoadedAndSocketData(points, socketDataPoints, selectedDistrict, selectedMapperID, renderSinglePoint).mapData
                    this._createLayer(updatedMapData)
                    dispatch(setRunLoader(false))
                }
    
            }

        // }
        if(prevProps.singleDataPoint === singleDataPoint && singleDataPoint?.length > 0){ 
            this.setState({
                showSinglePointOnTaskListClick: true,
            })
        }
        // checking currently no data to render
        else if(points.length === 0 && prevProps.points.length > 0){
            
            this.setState({ layers: [] })
            dispatch(setRunLoader(false))
        }
    }

    // rendering popup panel(left panel to show information of points)
    _renderPopup = (info) => {
        const {dataPlotter} = this.props
        ReactDOM.render(
            <CustomPopup  info={info} dataPlotter={dataPlotter}/>,
            document.getElementById('custom-popup')
        )
    }

    //  setting the information of a single point data and state to open popup
    _setPopupInfo = (info, currentData) => {
        const { dispatch, pageName } = this.props
        const uCode = info?.object?.uCode
        
        //  cnl api has id not uCode
        const cnl_id = info?.object?.id

        dispatch(setRunLoader(true))
        this.setState({
            showSinglePointOnTaskListClick: false,
        })
        
        if(!uCode && !cnl_id){
            dispatch(setRunLoader(false))
            return
        }
       
        // this._createLayer(currentData, info?.index)

        if(pageName == PAGES.RETAIL){
            fetchRetailPlaceByUcode(uCode)
            .then(data => {
                dispatch(dispatch => {
                    this._popupClose()
                    dispatch(setRenderSinglePoint(true))
                    dispatch(setSingleDataPoint([data]))
                    // dispatch(setStaticPoints([data]))
                    dispatch(setRunLoader(false))
                    this.setState({
                        showSinglePointOnTaskListClick: true,
                    })
                })
            })
            .catch(err => {
                this._popupClose()
                dispatch(setRunLoader(false))
                console.error(err)
                fetchPlaceByUcode(uCode)
            })

        }else if(pageName == PAGES.CNL){
            fetchCnlPlaceByUcode(cnl_id)
            .then(data => {
                dispatch(dispatch => {
                    this._popupClose()
                    dispatch(setRenderSinglePoint(true))
                    dispatch(setSingleDataPoint([data]))
                    // dispatch(setStaticPoints([data]))
                    dispatch(setRunLoader(false))
                    this.setState({
                        showSinglePointOnTaskListClick: true,
                    })
                })
            })
            .catch(err => {
                this._popupClose()
                dispatch(setRunLoader(false))
                console.error(err)
                fetchPlaceByUcode(uCode)
            })

        }  
        else{
            fetchPlaceByUcode(uCode)
            .then(data => {
                dispatch(dispatch => {
                    this._popupClose()
                    dispatch(setRenderSinglePoint(true))
                    dispatch(setSingleDataPoint([data]))
                    // dispatch(setStaticPoints([data]))
                    dispatch(setRunLoader(false))
                    this.setState({
                        showSinglePointOnTaskListClick: true,
                    })
                })
            })
            .catch(err => {
                this._popupClose()
                dispatch(setRunLoader(false))
                console.error(err)
            })

        }

    }

    _popupClose = () => {
        const { dispatch } = this.props

        this.setState({ 
            isPopupOpen: false,
            showSinglePointOnTaskListClick: false,
        })
        dispatch(setRenderSinglePoint(false))
        dispatch(setSingleDataPoint([]))
    }

    // handleV view state change
    _handleViewStateChange = (value) => {
        const { singleDataPoint, filteredData } = this.props
        const { viewState } = this.state

        // singleDataPoint is always prioritize to flyto]
        if(singleDataPoint?.length > 0){
            this.setState({
                viewState: {
                    ...viewState, 
                    latitude : Number(singleDataPoint[0]?.latitude), 
                    longitude: Number(singleDataPoint[0]?.longitude), 
                    zoom: 20 
                } 
            })
            return
        }

        // if filter option is selected
        // if(filteredData?.length > 0){
        //     this.setState({
        //         viewState:  {
        //             ...viewState, 
        //             latitude : filteredData[0]?.latitude, 
        //             longitude: filteredData[0]?.longitude, 
        //             zoom: 20
        //         } 
        //     })
        //     console.log("filtered Data ---------------->", filteredData);
        //     return
        // }

        // if(filteredData?.length > 0){
        //     this.setState({
        //         viewState:  {
        //             ...viewState, 
        //             latitude : Number(singleDataPoint[0]?.latitude), 
        //             longitude: Number(singleDataPoint[0]?.longitude), 
        //             zoom: 14
        //         } 
        //     })
        //     return
        // }

    }

    // create layers 
    _createLayer = (data) => {
        if(data?.length < 1){
            return
        }
        const {singleDataPoint} = this.props
        const currentData = data
        this._handleViewStateChange()

        const newLayer = new ScatterplotLayer({
            id: 'scatterplot-layer',
            data: currentData,
            pickable: true,
            opacity: 0.8,
            filled: true,
            radiusScale: 5,
            radiusMinPixels: 1,
            radiusMaxPixels: 100,
            getPosition: d => [ Number(d?.longitude),  Number(d?.latitude)],
            getRadius: d => 1.7,
            getFillColor: d => pTypeColor[d?.pType ? pTypeColor[d?.pType.split(",")[0]] ? d?.pType.split(",")[0] : 'Others' : 'Not Available'],
            getLineColor: d => [0, 0, 0],
            // getPickingInfo: d => (d.info, 'click'),
            autoHighlight: true,
            highlightColor: [0, 0, 0],
            // highlightedObjectIndex: selectedDataIndex !== null ? selectedDataIndex : null,
            onClick: (info, event) => { this._setPopupInfo(info, currentData ) },
            radiusUnits: 'pixels'
        })
        // if single point data data exist// basically for marking selected data
        if(singleDataPoint[0]){
            const markerIconLayer = new IconLayer({
                id: 'IconLayer',
                data: singleDataPoint,
                // getColor: d => [Math.sqrt(d.exits), 140, 0],
                getIcon: d => 'marker',
                getPosition: d => [ Number(d?.longitude),  Number(d?.latitude)],
                getSize: d => 5,
                iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
                iconMapping: {
                  marker: { 
                    x: 0,
                    y: 0,
                    width: 128,
                    height: 128,
                    anchorY: 128,
                    mask: true
                  }
                },
                sizeScale: 8,
                pickable: true,
              });
    
            this.setState({
                layers: [newLayer, markerIconLayer]
            })



            // returning so that this layer do not get stored in state and remains temporary
            return
        }
        
        this.setState({
            layers: newLayer
        })
    }

    // change map style onClick
    _onClickMapStyleChange = () => {
        const { mapStyle, availableStyle } = this.state

        if( mapStyle === availableStyle?.default ){
            this.setState({
                mapStyle: availableStyle?.satellite ?? mapStyle
            })
            return
        }
        this.setState({ mapStyle: availableStyle?.default ?? mapStyle })
    }

    

    render() {
        const { points, singleDataPoint, runLoader } = this.props
        const { viewState, mapStyle, layers, popupInfo, isPopupOpen, showSinglePointOnTaskListClick } = this.state

        return (
            <div>
                <DeckGL
                    initialViewState={ viewState }
                    // onViewStateChange={ this._handleViewStateChange }
                    controller={true}
                    layers={layers}
                    ContextProvider={MapContext.Provider}
                >
                    <StaticMap
                        mapStyle={ mapStyle}
                        mapboxApiAccessToken={  MAP.MAPBOX_KEY } // this is only for mapbox
                    />
                    {   
                        // Loading spinner 
                        runLoader &&
                        <div className={"row loader"} style={{marginTop: "100px"}}>
                            <div className={"item"}>
                                <Loader type="bubble-loop" bgColor={"#009688"} title={"Loading Data"} color={'#009688'} size={70} />
                            </div>
                        </div>
                    }
                    {
                    
                        // color legend of points according to pType
                        <div className='legend-container'>
                            <Tooltip title={ 'Change Map Layer' } placement={ 'top' }>
                                <div style={{ backgroundColor: '#151f2a', textAlign: 'center', borderRadius: '0 7px 0 0'  }}>
                                    <IconButton 
                                        color={ 'primary' } 
                                        sx={{ backgroundColor: 'white', margin: '3px', borderRadius: '3px' }}
                                        onClick={ this._onClickMapStyleChange }
                                    > 
                                        <LayersOutlined /> 
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <ul style={{padding: '0 20px', marginTop: '10px', marginBottom: '10px'}}>
                                {Object.keys(pTypeColor).map(item => (
                                    <li key={item} style={{listStyleType: "none", display:'flex', alignItems: 'center'}}>
                                        <p style={{fontSize: '12px', paddingRight: '10px', margin: '0'}}>{item} : </p>  
                                        <p style={{backgroundColor: `rgb(${pTypeColor[item]})`, margin: '2px', height: '15px', width: '15px', borderRadius: '50px'}}></p>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    }
                    {
                        // this panel opens after clicking data point inside map
                        isPopupOpen &&   
                        <Popup
                            anchor="bottom"
                            longitude={Number(popupInfo?.longitude)}
                            latitude={Number(popupInfo?.latitude)}
                            closeOnClick={false}
                            onClose={this._popupClose}
                            captureScroll={true}
                            style={{cursor: 'text', userSelect: 'text'}}
                        >
                            <div id='custom-popup'>
                            
                            </div>

                        </Popup>
                    }
                    {
                        // this panel opens after clicking task in loader panle's task list
                        (showSinglePointOnTaskListClick && singleDataPoint?.length > 0) &&
                        <Popup
                            anchor="bottom"
                            longitude={singleDataPoint ? Number(singleDataPoint[0]?.longitude) : Number(points[0]?.longitude) }
                            latitude={singleDataPoint ? Number(singleDataPoint[0]?.latitude) : Number(points[0]?.latitude)}
                            closeOnClick={false}
                            onClose={this._popupClose}
                            captureScroll={true}
                            style={{cursor: 'text', userSelect: 'text'}}
                        >
                            <div id='custom-popup' style={{backgroundColor: 'white'}}>
                                <CustomPopup info={singleDataPoint ? singleDataPoint[0] : points[0]} />
                            </div>
                        </Popup>
                    }
                </DeckGL>
            </div>
        )
    }
}

// Prop Types
MapGL.propTypes = {
    points: PropTypes.array,
    singleDataPoint: PropTypes.array,
    selectedDataIndexToHighlight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    selectedMapperID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    renderSinglePoint: PropTypes.bool,
    filteredData: PropTypes.array,
    homePanelName: PropTypes.string,
}
MapGL.defaultProps = {
    points: [],
    singleDataPoint: [],
    selectedDataIndexToHighlight: null,
    selectedMapperID: 0,
    renderSinglePoint: false,
    filteredData: [],
    homePanelName: '',
    runLoader: false
}

const mapStateToProps = (state) => ({
    points: state?.mapData?.points ?? [],
    singleDataPoint: state?.mapData?.singleDataPoint ?? [],
    socketDataPoints: state?.mapData?.socketDataPoints ?? [],
    socketRetailDataPoints: state?.mapData?.socketRetailDataPoints ?? [],
    selectedDistrict: state?.mapData?.selectedDistrict ?? '',
    renderSinglePoint: state?.mapData?.renderSinglePoint ?? false,
    allMapperInfo: state?.mapData?.allMapperInfo ?? [],
    selectedDataIndexToHighlight: state?.mapData?.selectedDataIndexToHighlight ?? null,
    runLoader: state?.mapData.runLoader ?? false,
    dataPlotter: state?.mapper?.dataPlotter ?? [],
    selectedMapperID: state?.mapper?.selectedMapperID ?? 0,
    filteredData: state?.filter?.filteredData ?? [],
    homePanelName: state?.common?.homePanelName ?? '',
    startDate: state?.common?.startDate ?? '',
    endDate: state?.common?.endDate ?? '',
    pageName: state?.common.pageName ?? '',
    startDateType: state?.cnl?.startDateType ?? 0,
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(MapGL);