import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    homePanelName: '', // homepanel names are: loader, filter, mapper, updateLog
    allDistrict: [],
    startDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`, // for loader
    endDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`, // for loader
    selectedDistrict: '', // for loader panel
    selectedDistrictForFilter: '',
    pageName: '' // current page of sidebar
}


const commonDataSlice = createSlice({
    name: 'commonData',
    initialState,
    reducers: {
        setHomePanelName: (state, action) => {
            state.homePanelName = action.payload
        },
        setAllDistrict: (state, action) => {
            state.allDistrict = action.payload;
        },
        setStartDate: (state, action) => {
        state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setSelectedDistrict: (state, action) => {
            state.selectedDistrict = action.payload
        },
        setPageName: (state, action) => {
            state.pageName = action.payload
        },
    
    }
})

export const { 
    setAllDistrict, 
    setStartDate, 
    setEndDate, 
    setUpdateLogStartDate,
    setUpdateLogEndtDate,
    setSelectedDistrict, 
    setHomePanelName,
    setPageName
} = commonDataSlice.actions
export default commonDataSlice.reducer