import axios from "axios"
import { COMMON } from '../../App.config'
import dayjs from 'dayjs'

// reducers and actions
import { setAllDistrict } from "../Reducers/commonReducer"
import { getMapperNameByID } from "./mapperAction"

// load district list
const getDistrict = () => {
    return dispatch => {
        axios.get(COMMON.DISTRICT_LIST)
        .then(res => {
            if(res?.status !== 200){
                return []
            }
            const onlyDistrictList = res?.data?.data.map(item => {
                const obj = {
                    label: item.district,
                    value: item.district,
                }
                return obj
            })
            const allDistrict = {
                label: 'All',
                value: 'All'
            }
            dispatch(setAllDistrict([allDistrict, ...onlyDistrictList]))
        })
        .catch(err => {
            console.error(err)
        })
    }
}

// generate message for list 
const getGeneratedTaskMessage = (item) => {
    let retailData = ''
    if(item?.retail_data_category){
        retailData = JSON.parse(item?.retail_data_category)
    }

    console.log("retail item =======", item);
    
    const template = <span>
                        Time: 
                        {
                            dayjs( item?.created_at, 'YYYY-MM-DD HH:mm:ss')
                            .format('YYYY-MM-DD hh:mm:ss A')}
                                | Message: 
                                <span style={{  borderRadius: "5px", background: "#01A36A", color: "white", padding: "2px 6px"}}>
                                    {getMapperNameByID(item?.user_id)} 
                                </span>
                                Added a Place: 
                                <span style={{ color: 'red' }}>
                                    {item?.business_name ? item.business_name + ',' : ''} 
                                    {item?.Address ? item.Address : ''} 
                                    { 
                                        ((item?.city || item?.area) ? ' near ' : '') 
                                        + (item?.area ? item.area + ',' : '') 
                                        + (item?.city ? item.city : '')
                                    } 
                                </span> 
                                area with Code: {item?.uCode}, 
                                {/* {
                                    item?.subType ? 'subType: ' 
                                    + (<span style={{ color: 'green', fontWeight: "bold" }}> {item?.subType} </span> )+ ',' : '' 
                                }  */}
                                {item?.category_of_outlet ?
                                    (<span>Category of Outlet: 
                                        <span style={{ color: 'green', fontWeight: "bold" }}>
                                            {item?.category_of_outlet + ", "}
                                        </span>
                                    </span>) 
                                    : 
                                    ""
                                }
                                subType: <span style={{ color: 'green', fontWeight: "bold" }}>{item?.subType}</span>, 
                                pType: <span style={{ color: 'green', fontWeight: "bold" }}>{item?.pType}</span>, 
                                <span>
                                    { 
                                        item?.place_name ? 'Place Name: ' 
                                        + item.place_name + ',' : ''
                                    } 
                                </span> 
                                { item?.sub_area ? 'Sub Area: '+ item.sub_area + ',' : ''} 
                                { item?.district ? 'District: ' + item.district + ',' : '' } 
                                { item?.thana ? 'Thana: '+ item.thana  +',': ''} 
                                { item?.postCode ? 'PostCode: ' + item.postCode + ',' : ''} 
                                Image Count: 
                                {item?.image_count} || {retailData ? retailData.map(element => element?.category + ':' + element?.sku_type.map(sku => sku) +'||') : ''
                    } 
                    </span>
    return template
}


// generate message for list 
const getGeneratedTaskMessageCnl = (item) => {
    let retailData = ''
    if(item?.retail_data_category){
        retailData = JSON.parse(item?.retail_data_category)
    }
    
    const template = <span>
                        Time: 
                        {
                            (item?.outlet_updated_at  === null) ? 
                                dayjs( item?.created_at, 'YYYY-MM-DD HH:mm:ss')
                                .format('YYYY-MM-DD hh:mm:ss A')
                                : 
                                dayjs( item?.outlet_updated_at, 'YYYY-MM-DD HH:mm:ss')
                                .format('YYYY-MM-DD hh:mm:ss A')}
                                | message: 
                                <span style={{ border: "1px solid lightgrey", borderRadius: "5px", background: "#E6E6E6", color: "grey", padding: "2px 6px"}}>
                                    {getMapperNameByID(item?.user_name) } 
                                    {item?.user_name ? item.user_name + '' : ''}
                                </span> 
                                
                                <span style={{ fontWeight: "bold", paddingLeft: "2px"}}>
                                {item?.outlet_updated_at === null ? " Added a Place: " : " Updated a Place: "}
                                </span>
                                <span style={{ color: 'red' }}>
                                    {item?.outlet_name ? item.outlet_name + ',' : ''} 
                                    {item?.address ? item.address : ''} 
                                    { 
                                        ((item?.city || item?.area) ? ' near ' : '') 
                                        + (item?.area_name ? item.area_name + ',' : '') 
                                        + (item?.territory_name ? item.territory_name + ',' : '') 
                                        + (item?.region_name ? item.region_name : '')
                                    } 
                                </span> 
                                area with Code: {item?.id}, 
                                {
                                    item?.subType ? 'subType: ' 
                                    + item.subType + ',' : '' 
                                } 
                                {/* pType: {item?.pType},  */}
                                <span style={{ color: 'red' }}>
                                    { 
                                        item?.place_name ? 'Place Name: ' 
                                        + item.place_name + ',' : ''
                                    } 
                                </span> 
                                { item?.sub_area ? 'Sub Area: '+ item.sub_area + ',' : ''} 
                                { item?.district ? 'District: ' + item.district + ',' : '' } 
                                { item?.thana ? 'Thana: '+ item.thana  +',': ''} 
                                { item?.postCode ? 'PostCode: ' + item.postCode + ',' : ''} 
                                Image Count: 
                                {item?.images?.length} || 
                                {/* {retailData ? 
                                    retailData.map(element => 
                                        element?.category + ':' + element?.sku_type.map(sku => sku) +'||'
                                    ) 
                                    : ''
                                }  */}
                    </span>
    return template
}

export { getDistrict, getGeneratedTaskMessage, getGeneratedTaskMessageCnl }