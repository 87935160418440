import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';

import logo from '../assets/Barikoi-logo.png'

import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { logout } from '../Redux/Actions/authActions'
import { margin } from '@mui/system';


const Navbar = (props) => {

  const pageName = useSelector((state) => state?.common?.pageName);

  const handleLogout = () => {
    props.dispatch(logout());

  }

  return (
    <Box  sx={{ flexGrow: 1 }}>
      {/* <AppBar style={{'rgb(81 94 108)'}} position='static'> */}
      <AppBar style={{backgroundColor: '#343a40'}} position='static'>
        <Toolbar sx={{ minHeight: '64px' }}>
          <IconButton
            size='large'
            edge='start'
            aria-label='menu'
            sx={{ mr: 2 }}
          >
          </IconButton>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
          <Typography variant='h6' component='div'  style={{paddingLeft: '45px', marginTop: "10px"}}>
            <img src={logo} height='25px' width='70px' alt='barikoi-logo' />
          </Typography>
          <p style={{color: '#faf7f2', fontWeight: 'bold' , fontFamily: 'Monospace', fontSize: "14px", border: "2px solid #faf7f2", padding: "0 5px"} }>{pageName}</p>
          <Button style={{marginRight: '125px'}} color='inherit'>
            <div>
              <li onClick={handleLogout} style={{display: 'flex', fontFamily: 'Sans-serif', fontFamily: 'Monospace', fontSize: "14px !important", alignItems: 'center', color: '#202c39', color: "#faf7f2", fontWeight: 'bold'}}  className='navLinks'><LogoutIcon /> Logout</li>
          </div>
          </Button>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

// Prop Types
Navbar.propTypes = {
  userEmail: PropTypes.string,
  password: PropTypes.string,
  authError: PropTypes.string,
  dispatch: PropTypes.func
}

Navbar.defaultProps = {
  userEmail: '',
  password: '',
  authError: '',
//   dispatch: () => null
}

const mapStateToProps = (state) => ({
  userEmail: state.auth.userEmail,
  password: state.auth.password,
  authError: state.auth.error
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)