import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

// Import Components
// import { Layout } from 'antd'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'

// const { Content } = Layout

const ProtectedRoute = ({ isAuthenticated, children }) => {
    if(isAuthenticated) {
        return (
            <div style={{ width: '100%', height: '100vh', boxSizing: 'border-box' }}>
                <Navbar />
                 <Sidebar />
                {/* <Content style={{ padding: '1rem', marginTop: 64, boxSizing: 'border-box' }}> */}
                    { children }
                {/* </Content> */}
            </div>
        )
    }
    return <Navigate to='/login' />
}

// Prop Types
ProtectedRoute.propTypes = {
    isAuthenticated: PropTypes.bool
}

ProtectedRoute.defaultProps = {
    isAuthenticated: false
}

export default ProtectedRoute