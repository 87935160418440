import axios from 'axios'
import { CNL, DATA } from '../../App.config'
import { setCnlMapperList, setCnlRoutes, setCnlTeritories, setMarketOpportunities, setOutletCategories, setOutletTypes } from '../Reducers/cnlReducer'

// get all mapper information
const getAllMapperInfoCnl = () => {
    return dispatch => {
        let url = CNL.ALL_MAPPER

        axios.get( url )
        .then(res =>{
            if(res?.status === 200){
                // cnl data api structure is different
                let modifiedMapperList = res?.data?.users?.map(item => {
                    return {
                        user_id : item?.employee_id,
                        name: item?.user_name,
                        label: item?.user_name,
                        value: item?.employee_id
                    }
                })
                dispatch(setCnlMapperList(modifiedMapperList))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}

// fetch data for individual mapper --- cnl data only 
const fetchIndividualUserCnlData = async (employee_id, params) => {
    try{
        const { dateFrom, dateTill, include_filter_by_created_at } = params
        const url = `${DATA.GET_CNL_DATA}?employee_id=${employee_id}&start_date=${dateFrom}&end_date=${dateTill}&include_filter_by_created_at=${include_filter_by_created_at}`
        const results = await axios.get(url)

        return results?.data?.outlets ? results.data.outlets : []
    }
    catch(err){
        throw(err)
    }
} 

//  fetch cnl outlet types
const getOutletTypes = () => {
    return dispatch => {
        let url = CNL.OUTLET_TYPES

        axios.get( url )
        .then(res =>{
            if(res?.status === 200){
                // cnl data api structure is different
                let outletTypes = res?.data?.outlet_types

                let outletTypesArr = []

                outletTypes.map((item) => {
                    outletTypesArr.push({
                        value: item, 
                        label: item
                    });
                })

                dispatch(setOutletTypes(outletTypesArr))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}

//  fetch cnl outlet categories
const getOutletCategories = () => {
    return dispatch => {
        let url = CNL.OUTLET_CATEGORIES

        axios.get( url )
        .then(res =>{
            if(res?.status === 200){
                // cnl data api structure is different
                let outletCategories = res?.data?.outlet_categories

                let outletCategoriesArr = []
                outletCategories.map((item) => {
                    outletCategoriesArr.push({
                        value: item, 
                        label: item
                    });
                })
                dispatch(setOutletCategories(outletCategoriesArr))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}

//  fetch cnl outlet Market Opportunities
const getMarketOpportunities = () => {
    return dispatch => {
        let url = CNL.MARKET_OPPORTUNITIES

        axios.get( url )
        .then(res =>{
            if(res?.status === 200){
                // cnl data api structure is different
                let marketOpportunities = res?.data?.outlet_market_opportunities

                let marketOpportunitiesArr = []

                marketOpportunities.map((item) => {
                    marketOpportunitiesArr.push({
                        value: item, 
                        label: item
                    });
                })
                dispatch(setMarketOpportunities(marketOpportunitiesArr))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}


//  fetch cnl territories
const getCnlTerritories = () => {
    return dispatch => {
        let url = CNL.TERRITORIES

        axios.get( url )
        .then(res =>{
            console.log("REss ter", res);
            if(res?.status === 200){
                // cnl data api structure is different
                let territories = res?.data?.territories

                let territoriesArr = []

                territories.map((item) => {
                    territoriesArr.push({
                        value: item?.id, 
                        label: item?.territory_name
                    });
                })
                dispatch(setCnlTeritories(territoriesArr))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}

//  fetch cnl territories
const getCnlRoutes = () => {
    return dispatch => {
        let url = CNL.ROUTES

        axios.get( url )
        .then(res =>{
            console.log("REss ter", res);
            if(res?.status === 200){
                // cnl data api structure is different
                let routes = res?.data?.routes

                let routesArr = []

                routes.map((item) => {
                    routesArr.push({
                        value: item?.id, 
                        label: item?.route_name
                    });
                })
                dispatch(setCnlRoutes(routesArr))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}

export { getAllMapperInfoCnl, fetchIndividualUserCnlData, getOutletTypes, getOutletCategories, getMarketOpportunities, getCnlTerritories, getCnlRoutes }