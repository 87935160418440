import  React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import PropTypes from 'prop-types';
import axios from 'axios';

// BASE API'S
import {BASE_API_URL} from '../App.config'

// loading spinner
import Loader from "react-js-loader";

// API's
import {fetchAllUserData} from '../utils/fetchUtils'





const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E8E9EF',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(id,name, email, phone, allow_mapping, action) {
  return { id,name, email, phone, allow_mapping, action};
}



function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = this.props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }


  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };


class MapperActions extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            allMapperData: [],
            rows: [],
            isUpdateAccess: "",
            page: 0,
            rowsPerPage: 10
        }
        this.updateAccess = this.updateAccess.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.callFetchTask = this.callFetchTask.bind(this)
        this.popupNotification = this.popupNotification.bind(this)
    }


    // option - boolean
    popupNotification(option){ 
        this.setState({
            isUpdateAccess: option
        }) 
        const popUpTimeOut = setTimeout(() => {

        this.setState({
            isUpdateAccess: ''
        }) 

        }, 15500);
    }

    componentDidMount(){
        this.callFetchTask()
    }

    // API Call - fetching all mapper information
    callFetchTask(){
        fetchAllUserData()
        .then(data => {

            let rowOptions = [];
            data.map(item => (
                rowOptions.push(createData(
                    item.id.toString(),
                    item.name,
                    item.email,
                    item.phone,
                    item.allow_mapping,
                ))
            ))
  
            this.setState({
                allMapperData: data,
                rows: rowOptions
            })
        })
        .catch(err=> console.error(err))
    }


    // update mapper access
    async updateAccess(mapperID, accessType){
      
        try {
            await axios.post(`${BASE_API_URL}/api/allow/disallow/mapping?mapper_id=${mapperID}&type=${accessType}`);
            this.popupNotification(true)

            this.setState({
                ...this.state,
                rows: 
                    this.state.rows.map(item => {
                        if(item.id === mapperID){
                            if(item.allow_mapping === 0){
                                item.allow_mapping = 1;
                            }
                            else{
                                item.allow_mapping = 0;
                            }
                        }
                        return item
                    })
                
            })
            this.forceUpdate()


        } catch (error) {
            
        }
    }


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
      };
    

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)

        })
      };


    render(){
        const { rows, rowsPerPage, page} = this.state;
        // Avoid a layout jump when reaching the last page with empty rows.
        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
        return (
            <div>
                {/* <Navbar /> */}
            
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                    <TableRow>
                        <StyledTableCell style={{fontWeight: 'bold'}} align="left">ID</StyledTableCell>
                        <StyledTableCell style={{fontWeight: 'bold'}} align="left">Name</StyledTableCell>
                        <StyledTableCell style={{fontWeight: 'bold'}} align="left">Email</StyledTableCell>
                        <StyledTableCell style={{fontWeight: 'bold'}} align="left">Phone</StyledTableCell>
                        <StyledTableCell style={{fontWeight: 'bold'}} align="left">Allowed Access</StyledTableCell>
                        <StyledTableCell style={{fontWeight: 'bold'}} align="left">Actions</StyledTableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>


                    {
                        

                        (rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <StyledTableRow key={`${row.id}-${row.allow_mapping}`}>
                                <StyledTableCell align="left">{row.id}</StyledTableCell>
                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                                <StyledTableCell align="left">{row.email}</StyledTableCell>
                                <StyledTableCell align="left">{row.phone}</StyledTableCell>
                                <StyledTableCell align="left">{row.allow_mapping === 0 ? <span style={{backgroundColor: "#ffbfbf", padding: "5px 10px", borderRadius: '10px', fontSize: '12px', color: '#372a59'}}>Not Allowed</span> : <span style={{backgroundColor: "rgb(29 213 69 / 35%)", padding: "5px 10px", borderRadius: '10px', fontSize: '12px', color: '#372a59'}}>Allowed</span>}</StyledTableCell>
                                
                                <StyledTableCell align="left">
                                    <div>
                                        
                                         { 
                                            row.allow_mapping === 0 ? 
                                            <button style={{padding: '10px', cursor: 'pointer', borderRadius: '10px', fontWeight: "bold", borderColor: '#e6eef6',backgroundColor: '#B0F0BE'}} value={row.id} onClick={() => this.updateAccess(row.id, 'allow')}>Allow Access</button>
                                            : 
                                            <button style={{padding: '10px', cursor: 'pointer', borderRadius: '10px', fontWeight: "bold", borderColor: '#e6eef6',backgroundColor: '#F9BFBF'}} value={row.id} onClick={() => this.updateAccess(row.id, 'disallow')}>Restrict Access</button>
                                         }

                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )
                    }


                    
                    </TableBody>
                    <TableFooter style={{backgroundColor: "rgb(240 240 247)"}}>
                        <TableRow colSpan={6}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 25, { label: 'All', value: -1 }]}
                                // colSpan={3}
                                count={this.state.rows.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                SelectProps={{
                                    inputProps: {
                                    'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={this.TablePaginationActions}
                                style={{color: '#5e6e7f', paddingRight: "50px"}}
                            />
                        </TableRow>
                    </TableFooter>
                    
                </Table>

                
                {
                    this.state.isUpdateAccess ? <Alert style={{position: 'absolute', left: '40%', top: '250px', fontWeight: 'bold' ,fontSize: '14px', backgroundColor: '#7dffaf'}} severity="success">Access Permission Updated Successfully!</Alert> : this.state.isUpdateAccess  === false ? <Alert style={{position: 'absolute', left: '40%', top: '250px', fontWeight: 'bold', color: 'white' ,fontSize: '14px' , backgroundColor: '#ff3d3d'}} severity="error">Failed to Updated Access Permission!</Alert> : ''
                }

                </TableContainer>
            </div>
        );
    }
}




// const mapStateToProps = (state) => ({
//     runLoader: state?.mapData.runLoader ?? false
// })


// const mapDispatchToProps = (dispatch) => ({dispatch})

export default MapperActions;
// export default connect(mapStateToProps, mapDispatchToProps)(MapperActions);