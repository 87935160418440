import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, CircularProgress } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// utils
import { fetchAllTask } from '../utils/fetchUtils'
import { setTaskList } from '../Redux/Reducers/taskReducer'
import { deleteTask } from '../Redux/Actions/taskActions'



class TaskListTable extends React.PureComponent {
    state = {
        isUpdateSuccessful: '',
        page: 0,
        isOpen: false,
        selectedTask: '',
        selectedTaskIndex: '',
        isLoading: false,
        columns: [
            { 
                field: 'id', 
                headerName: 'ID', 
                minWidth: 90,
                disableClickEventBubbling: true,
            },
            {
                field: 'task_name',
                headerName: 'Task Name',
                minWidth: 150,
                editable: false,
                disableClickEventBubbling: true
            },
            {
                field: 'task_area',
                headerName: 'Area',
                minWidth: 150,
                editable: false,
                disableClickEventBubbling: true,
            },
            {
                field: 'task_start_time',
                headerName: 'Timeline',
                minWidth: 300,
                valueGetter: (params) => `${params.row.task_start_time} - ${params.row.task_end_time}`,
                editable: false,
                disableClickEventBubbling: true
            },
            {
                field: 'assigned_to',
                headerName: 'Mapepr ID',
                minWidth: 150,
                disableClickEventBubbling: true
            },
            {
                field: 'task_status',
                headerName: 'Status',
                minWidth: 50,
                editable: false,
                disableClickEventBubbling: true
            },
            {
                field: 'actions',
                headerName: 'Actions',
                minWidth: 150,
                renderCell: ( cellValues ) =>  ([
                        <button 
                            style={ editButtonStyle } 
                        >
                            <Link style={ editLinkStyle } to={`/taskUpdate/${cellValues?.row?.id}`}>
                                Edit
                            </Link>
                        </button>,
                        <button 
                            onClick={ () => this._handleModal(true, cellValues) } 
                            style={ deleteBtnStyle } 
                        >
                            Delete
                        </button>
                    ]),
                    
                editable: false,
                disableClickEventBubbling: true
            }
        ]
    }

    componentDidMount(){
        const { taskList } = this.props

        // if task is not fetched
        if(taskList?.length === 0){
            this.setState({
                isLoading: true
            })
            this.callFetchTask()

        }
    }

    // fetching all task list
    callFetchTask(){
        const { dispatch } = this.props
        
        fetchAllTask()
        .then(data => {
            const { Tasks } = data

            dispatch( setTaskList(Tasks) )
            this.setState({
                isLoading: false
            })
        })
        .catch(err=> console.error(err))
    }

    _handleModal = (newValue, taskCell) => {
        // if newValue is true and taskId exist 
        if(newValue && taskCell?.row?.id){
            this.setState({ 
                isOpen: true,
                selectedTask: taskCell?.row?.id,
                selectedTaskIndex: taskCell?.api?.getRowIndex(taskCell?.row?.id)
            })

            return 
        }

        // closing the modal 
        this.setState({
            selectedTask: '',
            selectedTaskIndex: '',
            isOpen: false
        })
        
    }

    _deleteTask = () => {
        const { dispatch, taskList } = this.props
        const { selectedTask, selectedTaskIndex } = this.state

        this.setState({
            isLoading: true
        })

        // delete task
        deleteTask(selectedTask)
        .then(res => {
            if(res?.status === 200){
                this._handleModal(false)
                const taskListCopy = [...taskList]
                taskListCopy.splice(selectedTaskIndex, 1)
                dispatch(setTaskList(taskListCopy))

                this.setState({
                    isLoading: false
                })
            }
        })
        .catch(err => {
            console.error(err)
            this._handleModal(false)
            this.setState({
                isLoading: false
            })
        })
    }


    render(){
        const { taskList } = this.props
        const { columns, isOpen, selectedTask, isLoading } = this.state

        return (
          <Box sx={{ height: `${window.innerHeight- 180}px` }}>
              {
                    isLoading &&
                    <div style={ spinnerContainer }>
                        <CircularProgress color={ 'info' } size={ 70 }/>
                    </div>
              }
            <DataGrid
              rows={ taskList }
              key={ taskList?.length }
              components={{
                Toolbar: GridToolbar,
              }}
              sx={{ height: '100%', margin: '0 auto' }}
            //   loading={ isLoading }
              columns={ columns }
              pageSize={ 30 }
              rowsPerPageOptions={[20, 30, 50, 100]}
            //   disableSelectionOnClick
            //   ColumnResizeIcon
              disableExtendRowFullWidth={ true }

            />

            <Dialog
                open={ isOpen }
                // onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                sx={{ zIndex: 10 }}
            >
                <DialogTitle>
                    Do you want to delete task with ID - {selectedTask} ?
                </DialogTitle>
                <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    If you click Ok, this task will be completeley removed.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => this._handleModal(false)}>Cancel</Button>
                <Button onClick={ this._deleteTask } autoFocus>
                    Agree
                </Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
    }   
}

const deleteBtnStyle = {
    padding: '5px 10px', 
    backgroundColor: '#F9BFBF', 
    borderRadius: '5px', 
    fontWeight: 'bold', 
    borderColor: '#cafdff'
}
const editButtonStyle = {
    padding: '0', 
    backgroundColor: '#B0F0BE', 
    borderRadius: '5px', 
    fontWeight: 'bold', 
    borderColor: '#cafdff'
}
const editLinkStyle = { 
    display: 'inline-block', 
    padding: '5px 10px', 
    textDecoration: 'none', 
    color: '#323f4d' 
}
const spinnerContainer = {
    position: 'absolute', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    zIndex: 999
}

TaskListTable.propTypes = {
    taskList: PropTypes.array 
}
TaskListTable.defaultProps = {
    taskList: []
}

const mapStateToProps = (state) => ({
    taskList: state?.task?.taskList ?? []
})

const mapDispatchToProps = (dispatch) => ({dispatch})


export default connect(mapStateToProps, mapDispatchToProps)(TaskListTable)