import axios from 'axios'
import { MAP } from '../../App.config'
import { setGeojsonData } from '../Reducers/overviewReducer'



const transformDataForPolygonLayer = (data) => {
  const parsedData = data?.map((item, index) => {
      const contour = JSON.parse(item?.geom)
      const obj = {
        type: "Feature",
        properties: { name: 'YEASIN ' + index },
        geometry: contour
      }
      return obj
  })
    const transformedData = data?.map(item => {
        const contour = JSON.parse(item?.geom)
        return contour
    })
    // const geojson = GeometryEditor(transformedData[0])

  parsedData.push({
    type: "Feature",
    properties: { name: 'YEASIN GEojson layer text test updated' },
    geometry: { type:'Point', coordinates: [90.3938010872331, 23.82707945251465,] }
  })

  return parsedData
}

// fetch polygon coordinates of division
const getDivisionPolygon = () => {
    return dispatch => {
        axios.get(MAP.GET_DIVISION_POLYGON)
        .then(res =>{
            if(res?.status !== 200){
                return
            }

            const parsedGeojson = transformDataForPolygonLayer(res?.data?.data) 
            dispatch(setGeojsonData( parsedGeojson ))

        })
        .catch(err =>{
            console.error(err)
        })
    }
}


const getDivisionDataCount = () => {
   return dispatch => { 
       axios.get(MAP.GET_DIVISION_DATA_COUNT)
       .then(res => {
        //    console.log({ res })
           return
       })
       .catch(err => {
           console.error(err)
       })
   }
}

export {
    getDivisionPolygon,
    getDivisionDataCount
}