import './App.css';
import React from 'react';
import './App.css';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Components
import RetailWindow from './components/RetailWindow';
import TaskWindow from './components/TaskWindow';
import TaskList from './components/TaskList';
import UpdateTask from './components/UpdateTask';
import Login from './components/Login'
import CircularProgress from '@mui/material/CircularProgress';
import MapperActions from './components/MapperActions';
import ProtectedRoute from './components/common/ProtectedRoute';
import { Overview } from './components/page'


// Router
import {
    BrowserRouter as Router,
    Navigate,
    Routes,
    Route
} from "react-router-dom";


// Import Actions & Methods
import { validateUser } from './Redux/Actions/authActions';
import RetailDetailWindow from './components/RetailDetailWindow';
import CnlWindow from './components/CnlWindow';



class App extends React.PureComponent {

    componentDidMount() {
        const { dispatch } = this.props
    
        // Validate User
        const token = localStorage.getItem('token')
        if(token){
            dispatch( validateUser(token)) 
        }
      }

    render() {
        
        const { isAuthenticated, isValidating } = this.props
        

        return (
            <div >
               {
                   isValidating ?
                   <CircularProgress style={{position: 'absolute', top: '250px', left: '45%'}}/>
                   :
                   <Router>
                        {/* {isAuthenticated ?  <Sidebar /> : ""} */}
                 
                        <Routes> 
                            <Route exact path='/login' element={ isAuthenticated ? <Navigate to='/' /> : <Login />} />
                            <Route exact path='/' element={ 
                                <ProtectedRoute isAuthenticated={ isAuthenticated }>
                                    <RetailWindow /> 
                                </ProtectedRoute>
                                } 
                            />
                            <Route exact path='/taskCreationWindow' element={ 
                                <ProtectedRoute isAuthenticated={ isAuthenticated }>
                                    <TaskWindow /> 
                                </ProtectedRoute>
                                } 
                            />
                            <Route exact path='/taskList' element={ 
                                <ProtectedRoute isAuthenticated={ isAuthenticated }>
                                    <TaskList /> 
                                </ProtectedRoute>
                                } 
                            />
                            <Route exact path='/taskUpdate/:taskID' element={ 
                                <ProtectedRoute isAuthenticated={ isAuthenticated }>
                                    <UpdateTask /> 
                                </ProtectedRoute>
                                } 
                            />
                            <Route exact path='/mapperActions' element={ 
                                <ProtectedRoute isAuthenticated={ isAuthenticated }>
                                    <MapperActions /> 
                                </ProtectedRoute>
                                } 
                            />
                            <Route exact path='/overview' element={ 
                                <ProtectedRoute isAuthenticated={ isAuthenticated }>
                                    <Overview /> 
                                </ProtectedRoute>
                                } 
                            />
                            <Route exact path='/retail' element={ 
                                <ProtectedRoute isAuthenticated={ isAuthenticated }>
                                    <RetailDetailWindow />
                                </ProtectedRoute>
                                } 
                            />
                            <Route exact path='/cnl' element={ 
                                <ProtectedRoute isAuthenticated={ isAuthenticated }>
                                    <CnlWindow />
                                </ProtectedRoute>
                                } 
                            />
                    
                            <Route
                                path="*" 
                                element={
                                <main style={{ padding: "1rem" }}>
                                    <p>There's nothing here!</p>
                                </main>
                                }
                            />
                        </Routes>
                    </Router>
               }  

                
            </div>

        )
    }
}


// Prop Types
App.propTypes = {
    isAuthenticated: PropTypes.bool,
    isValidating: PropTypes.bool,
    dispatch: PropTypes.func
  }
  
  App.defaultProps = {
    isAuthenticated: false,
    isValidating: false,
    dispatch: () => null
  }
  
  const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isValidating: state.auth.isValidating
  })
  
  const mapDispatchToProps = dispatch => ({ dispatch })
  
  export default connect(mapStateToProps, mapDispatchToProps)(App)