import React from 'react'

class ErrorBoundary extends React.PureComponent {
  state = {
    error: null,
    errorInfo: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    const { children } = this.props
    const { error, errorInfo } = this.state

    return (
      <React.Fragment>
        {
          error ?
            <div style={ containerStyles }>
              <h4 style={{ whiteSpace: 'normal' }}>
                  Filed to Load Map.
                  {
                      console.error(errorInfo)
                  }
                </h4>
            </div>
            :
            children
        }
      </React.Fragment>
    )
  }
}

// Styles
const containerStyles = {
  padding: '16px',
  color: '#909090',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}


export default ErrorBoundary