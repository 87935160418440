import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import authReducer from './Reducers/authReducer'
import mapDataReducer from './Reducers/mapDataReducer'
import mapperReducer from './Reducers/mapperReducer'
import commonReducer from './Reducers/commonReducer'
import filterReducer from './Reducers/filterReducer'
import taskDataSlice from './Reducers/taskReducer'
import updateLog from './Reducers/updateLogReducer'
import overviewReducer from './Reducers/overviewReducer'
import cnlReducer from './Reducers/cnlReducer'
import retailReducer from './Reducers/retailReducer'




const store = configureStore({
    reducer: {
        mapData: mapDataReducer,
        auth: authReducer,
        mapper: mapperReducer,
        common: commonReducer,
        cnl: cnlReducer,
        filter: filterReducer,
        retail: retailReducer,
        task: taskDataSlice,
        updateLog: updateLog,
        overview: overviewReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
    })
})
export default store