import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Alert, Box, CircularProgress, Container, Divider, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import '../App.css';


// BASE API'S
import { BASE_API } from '../App.config'

// action and reducers
import { connect } from 'react-redux';
import {setAllMapperInfo, setTaskCreationCoordinates} from '../Redux/Reducers/mapDataReducer'
import { fetchAllTask, fetchAllUserData } from '../utils/fetchUtils'
import { setTaskList } from '../Redux/Reducers/taskReducer'

// Components
import {allStaticArea} from '../utils/area'
import { createTask } from '../Redux/Actions/taskActions';


class TaskCreateForm extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
           task_name: '',
           task_area: '',
           task_start_time: '',
           task_end_time: '',
           task_geometry: '',
           assigned_to: '',
           missing_suggestion: '',
           task_status: 0,
           task_description: '',
           task_type: '',
           allMapperOptionArray: [],
           allAreaOptionArray: [],
           isUpdateSuccessful: '',
           staticMessage: '',
           isLoading: false
        }
        this.updateState = this.updateState.bind(this);
        this.updateDropdownToState = this.updateDropdownToState.bind(this);
        this.updateTask = this.updateTask.bind(this);
        this.popupNotification = this.popupNotification.bind(this);
        this.getMapperName = this.getMapperName.bind(this);
    }

    componentDidMount(){
        const { currentTask, dispatch } = this.props

        // Fetching all mapper list
        fetchAllUserData()
        .then(users => {
            let allMapperOption = [];
            let allAreaOption= [];

            // creating mapper dropdown list
            users.map((mapper)=>(
                allMapperOption.push({value: mapper.id, label: mapper.name})
            ))

            // creating area dropdown list
            allStaticArea.map((area)=>(
                allAreaOption.push({value: area, label: area})
            ))

            this.setState({
                allMapperOptionArray: allMapperOption,
                allAreaOptionArray: allAreaOption
            })
            dispatch(setAllMapperInfo(users))
        })
        .catch(err=> console.error(err))

        if(currentTask?.id){
            const taskFromProps = currentTask
            const polygonCoordinatesJSON = currentTask ? currentTask : ''
            const polygonCoordinatesObj = polygonCoordinatesJSON['ST_AsGeoJSON(task_geometry)'] ? JSON.parse(polygonCoordinatesJSON['ST_AsGeoJSON(task_geometry)']) : ''
            const currentCoordinateCopy = [...polygonCoordinatesObj.coordinates[0]]
            const spaceSeperatedItems = currentCoordinateCopy.map(item => (
                item.join(' ')
            ))
            const commaSeperatedCoordinates = spaceSeperatedItems.join(',')

            dispatch(setTaskCreationCoordinates(commaSeperatedCoordinates))
            this.setState({
                task_name: taskFromProps.task_name,
                task_area: taskFromProps.task_area,
                task_start_time: taskFromProps.task_start_time,
                task_end_time:  taskFromProps.task_end_time,
                task_geometry: commaSeperatedCoordinates,
                assigned_to: taskFromProps.assigned_to,
                missing_suggestion: taskFromProps.missing_suggestion,
                task_status: taskFromProps.task_status,
                task_description: taskFromProps.task_description,
                task_type: taskFromProps.task_type,
            })
        }
    }


    componentDidUpdate(){
        const { taskCreationCoordinates } = this.props

        if(taskCreationCoordinates !== this.state.task_geometry){
           this.setState({
            task_geometry: taskCreationCoordinates 
           })
       }
    }

    componentWillUnmount(){
        const { dispatch } = this.props
        dispatch(setTaskCreationCoordinates(''))
    }

    // get specific mapper name 
    getMapperName(mapperID){
        let currentMapperName = ''
        this.state.allMapperOptionArray.forEach((element) => {
            if(element.value === mapperID){
                currentMapperName = element.label
            } 
        })
        return currentMapperName;
    }

     // option - boolean
     popupNotification(option, message){ 
         // if specific message passed
         if(message){
             this.setState({
                 isUpdateSuccessful: option,
                 staticMessage: message
             }) 
        }
        // if no message exist
        if(!message){
            this.setState({
                isUpdateSuccessful: option,
            }) 
       }
        const popUpTimeOut = setTimeout(() => {

        this.setState({
            isUpdateSuccessful: '',
            staticMessage: ''
        }) 

        }, 2500);
    }

    // update form input value to state
    updateState(event, property){
        this.setState({
            [property] : event.target.value
        })
    }

    // update dropdown input data to state
    updateDropdownToState(option, property){
        if(option){
            this.setState({
                [property.name]: option.value
            })
        }
    }

    // create a new task
    postCreatedTask = async (e) =>{ 
        e.preventDefault();
        const { dispatch } = this.props
        const { task_name, task_geometry, task_start_time, task_end_time, task_description, task_area, assigned_to, missing_suggestion, task_status, task_type } = this.state

        this.setState({
            isLoading: true
        })

        // below field are required field
        if(task_name && task_geometry && task_start_time && task_end_time && task_description){
            const params =  {
                task_name: task_name,
                task_area: task_area,
                task_start_time: task_start_time,
                task_end_time: task_end_time,
                task_geometry: task_geometry,
                assigned_to: assigned_to,
                missing_suggestion: missing_suggestion,
                task_status: task_status,
                task_description: task_description,
                task_type: task_type
            }

            createTask(params)
            .then(res => {
                if(res?.data?.status == 200){
                    this.popupNotification(true)

                    // cleaning up current form data
                    dispatch(setTaskCreationCoordinates(''))
                    this.setState({ 
                        task_name: '',
                        task_area: '',
                        task_start_time: '',
                        task_end_time: '',
                        task_geometry: '',
                        assigned_to: '',
                        missing_suggestion: '',
                        task_status: '',
                        task_description: '',
                        task_type: '',
                        isLoading: false
                    })

                    // fetch task with newly added one
                    fetchAllTask()
                    .then(data => {
                        const { Tasks } = data
            
                        dispatch( setTaskList(Tasks) )
                        this.setState({
                            isLoading: false
                        })
                    })
                    .catch(err=> {
                        this.setState({
                            isLoading: false
                        })
                        console.error(err)
                    })

                    return
                }
                this.popupNotification(false, 'Something is Wrong')
            })
            .catch(err => {
                console.error(err)
                this.popupNotification(false, 'Task Creation Failed')
                this.setState({
                    isLoading: false
                })
            })
            
        }else{
            this.setState({
                isLoading: false
            })
            this.popupNotification(false, 'Please Draw Polygon and Enter All Required Field');
        }
    }

    // update current task
    async updateTask(e){ 
        e.preventDefault()
        const { dispatch, currentTask, updateCurrentTaskState } = this.props
        const { task_name, task_geometry, task_start_time, task_end_time, task_description, task_area, assigned_to, missing_suggestion, task_status, task_type } = this.state

        this.setState({
            isLoading: true
        })

        if(task_name && task_geometry && task_start_time && task_end_time && task_description){
            try {
                await axios.patch(`${ BASE_API }/update/data/task/${currentTask.id}`,
                {
                    task_name: task_name,
                    task_area: task_area,
                    task_start_time: task_start_time,
                    task_end_time: task_end_time,
                    task_geometry: task_geometry,
                    assigned_to: assigned_to,
                    missing_suggestion: missing_suggestion,
                    task_status: task_status,
                    task_description: task_description,
                    task_type: task_type
                    
                })

                this.popupNotification(true);
                dispatch(setTaskCreationCoordinates(''))
                this.setState({
                    isLoading: false
                })

                // fetch currently updated state of the task
                updateCurrentTaskState() 

                // fetch task with newly updated data
                fetchAllTask()
                    .then(data => {
                        const { Tasks } = data
                        dispatch( setTaskList(Tasks) )
                    })
                    .catch(err=> {
                        console.error(err)
                    })
                
            } catch (error) {
                this.popupNotification(false)
                this.setState({
                    isLoading: false
                })
                console.error(error)
             }
        }
        else{
            this.setState({
                isLoading: false
            })
            this.popupNotification(false)
        }
    }

    render() {
        const { currentTask } = this.props
        const { isLoading, staticMessage, isUpdateSuccessful, task_name, task_start_time, task_end_time, task_area, allAreaOptionArray, allMapperOptionArray, assigned_to, missing_suggestion, task_description, task_type } = this.state

        return (
            <Box sx={ boxStyle }>
                {
                    isLoading && 
                    <CircularProgress style={{position: 'absolute', top: '250px', left: '45%'}}/>
                }
                <Container sx={ containerStyle }>
                    {
                        isUpdateSuccessful ? <Alert style={ alertStyle } severity='success'>{ staticMessage ? staticMessage : 'Created Successfully!' }</Alert> : isUpdateSuccessful  === false ? <Alert style={{ ...alertStyle, color: 'white', backgroundColor: '#e05353'}} severity='error'>{ staticMessage ? staticMessage : 'Failed to Create Task!' }</Alert> : ''
                    }
                    <Typography variant={ 'subtitle1' } sx={ mapTitle }>Create Task</Typography>
                    <Divider />
                    <form style={{backgroundColor: 'white'}} action=''>
                        <br />
                        <label style={labelStyle}  className='' htmlFor=''> 
                            <span style={{color: 'red'}}>*</span> 
                            Task Name
                        </label>
                        <input 
                            required='required' 
                            value={ task_name } 
                            onChange={event => this.updateState(event, 'task_name')} 
                            placeholder='Enter Task Name' 
                            type='text' 
                            style={inputStyle}
                        />
                        

                        <div style={{display: 'flex', width: '90%', margin: '0 auto'}}>
                            <div style={{width: '50%', marginRight: '10px'}}>
                                <label 
                                    style={ dateLabelStyle } 
                                    htmlFor='start'
                                >
                                    <span style={{color: 'red'}}>*</span> 
                                    Start date:
                                </label>
                                <input 
                                    required='required' 
                                    className='filterBy-dropdown' 
                                    style={ dateInputStyle } 
                                    onChange={event => this.updateState(event, 'task_start_time')} 
                                    type='date' 
                                    id='start' 
                                    name='trip-start'
                                    value={ task_start_time ? task_start_time.split(' ')[0] : ''}
                                    min='2017-01-01' max='2050-12-31' 
                                />
                            </div>

                            <div style={{width: '50%'}}>
                                <label style={ dateLabelStyle } htmlFor='end'><span style={{color: 'red'}}>*</span> End date:</label>
                                <input 
                                    required='required' 
                                    className='filterBy-dropdown' 
                                    style={ dateInputStyle } 
                                    onChange={event => this.updateState(event, 'task_end_time')} 
                                    type='date' 
                                    id='start' 
                                    name='trip-start'
                                    value={ task_end_time ?  task_end_time.split(' ')[0] : ''}
                                    min='2017-01-01' max='2050-12-31' 
                                />
                            </div>
                        </div>

                        <div style={{display: 'flex', width: '90%', margin: '0 auto'}}>
                            <div style={{width: '50%'}}>
                            <label style={ selectLabelStyle } htmlFor='area'>Area</label>
                                <Select
                                    // isMulti
                                    name={ 'task_area' }
                                    className={ 'filterBy-dropdown' }
                                    classNamePrefix={ 'select' }
                                    options={ allAreaOptionArray }
                                    isClearable={ true }
                                    styles={ customSelectDropdownStyles }
                                    onChange={ this.updateDropdownToState }
                                    placeholder= { task_area }
                                />
                            </div>

                            <div style={{width: '50%'}}>
                                <label 
                                    style={ selectLabelStyle } 
                                    htmlFor='mapper'
                                >
                                    Mapper
                                </label>
                                <Select
                                    // isMulti
                                    name='assigned_to'
                                    className='filterBy-dropdown'
                                    options={ allMapperOptionArray }
                                    classNamePrefix='select'
                                    isClearable={true}
                                    styles={customSelectDropdownStyles}
                                    onChange={this.updateDropdownToState}
                                    required='required'
                                    placeholder={this.getMapperName(assigned_to)}
                                />
                            </div>

                        </div>


                        <label style={ textAreaLabelStyle }  htmlFor=''>Missing Suggestion</label>
                        <textarea 
                            value={ missing_suggestion } 
                            onChange={event => this.updateState(event, 'missing_suggestion')} 
                            style={{width: '90%',display: 'block', margin: '0 auto'}}  
                            id='remarks' 
                            name='remarks' 
                            rows='7' 
                            cols='47'
                        >
                        </textarea>

                        <label style={ textAreaLabelStyle }  htmlFor=''><span style={{color: 'red'}}>*</span> Task Description</label>
                        <textarea 
                            value={ task_description } 
                            required='required' 
                            onChange={event => this.updateState(event, 'task_description')} 
                            style={{width: '90%', display: 'block', margin: '0 auto'}}  
                            id='remarks' 
                            name='remarks' 
                            rows='7' 
                            cols='47'
                        >
                        </textarea >


                        <fieldset  style={{minWidth: '86%', width: '88%', margin: '0 auto', textAlign: 'left'}}>
                            <legend>Task Type:</legend>
                            <div style={{lineHeight: '16px'}}>
                                {
                                    task_type === 0 ?
                                    <input 
                                        checked 
                                        onChange={event => this.updateState(event, 'task_type')} 
                                        type='radio' 
                                        id='inHouse' 
                                        name='task_type' 
                                        value='0' 
                                    />
                                    :
                                    <input 
                                        onChange={event => this.updateState(event, 'task_type')} 
                                        type='radio' 
                                        id='inHouse' 
                                        name='task_type' 
                                        value='0' 
                                    />
                                }
                                
                                <label style={radioLabelStyle} htmlFor='inHouse'>In House</label>
                            </div>

                            <div style={{lineHeight: '16px'}}>
                                {
                                    task_type === 1 ?
                                    <input 
                                        checked 
                                        onChange={event => this.updateState(event, 'task_type')} 
                                        type='radio' 
                                        id='field' 
                                        name='task_type' 
                                        value='1' 
                                    />
                                    :
                                    <input 
                                        onChange={event => this.updateState(event, 'task_type')} 
                                        type='radio' 
                                        id='field' 
                                        name='task_type' 
                                        value='1' 
                                    />
                                }
                                
                                
                                <label style={radioLabelStyle} htmlFor='field'>Field</label>
                            </div>

                        </fieldset>


                        <div style={ buttonContainer }>
                            {
                                currentTask ? 
                                <LoadingButton 
                                    disabled={ isLoading }
                                    onClick={ this.updateTask } 
                                    style={ isLoading ? onLoadSubmitBtnStyle : submitBtnStyle } 
                                    size={ 'small' }
                                    color={ 'text' }
                                    variant={ 'contained' }
                                    value='Update'
                                >
                                    Update
                                </LoadingButton>
                                :
                                <LoadingButton 
                                    disabled={ isLoading }
                                    onClick={ this.postCreatedTask } 
                                    style={ isLoading ? onLoadSubmitBtnStyle : submitBtnStyle } 
                                    size={ 'small' }
                                    color={ 'text' }
                                    variant={ 'contained' }
                                    value='Create' 
                                >
                                    Create
                                </LoadingButton>
                            }
                        </div>    
                        
                        <br />
                    </form>
                </Container>
            </Box>
        )
    }
}

const submitBtnStyle = {
    backgroundColor: '#323F4D', 
    color: '#53bcac', 
    padding: '4px 20px', 
    fontWeight: 'bold', 
}
const onLoadSubmitBtnStyle = {
    padding: '4px 20px', 
    fontWeight: 'bold',
    backgroundColor: '#e5e5e5', 
    color: '#5e6e7f', 
    cursor: 'wait'
}
const textAreaLabelStyle = {
    fontSize: '16px',
    display: 'block', 
    width: '90%', 
    margin: '0 auto', 
    textAlign: 'left', 
    color: 'rgb(49, 150, 136)'
}
const alertStyle = {
    position: 'absolute', 
    zIndex: '1000', 
    left: '50%', 
    top: '200px', 
    fontWeight: 'bold',
    fontSize: '14px', 
    backgroundColor: '#7dffaf'
}
const selectLabelStyle = {
    paddingLeft: '10px', 
    fontSize: '16px', 
    display: 'block', 
    width: '100%', 
    margin: '0 auto' , 
    textAlign: 'left', 
    color: 'rgb(49, 150, 136)'
}
const dateLabelStyle = {
    fontSize: '16px', 
    display: 'block', 
    width: '100%', 
    margin: '0 auto' , 
    textAlign: 'left', 
    color: 'rgb(49, 150, 136)'
}
const dateInputStyle = {
    width: '90%',
    display: 'block', 
    padding: '5px 10px', 
    borderRadius: '4px', 
    border: '1px solid rgba(190, 190, 190, 0.76)', 
    color: '#757575'
}
const boxStyle = { 
    padding: '10px', 
    borderRadius: '5px',
    backgroundColor: '#f4f4f4',
    boxShadow: '0 0 3px #919191',
}
const containerStyle = {
    color: '#319688', 
    boxShadow: '0 0 3px #919191'
}
const mapTitle = {
    marginTop: '0', 
    textAlign: 'center', 
    fontWeight: 'bold', 
    backgroundColor: '#ffffff', 
    padding: '5px',
    color: '#2ba08f'
}
const radioLabelStyle = {
    fontSize: '14px',
    color: '#757575'
}
const customSelectDropdownStyles = {
    control: base => ({
        ...base,
        minHeight: 20,
        minWidth: '120%',
        width: '120%',
        marginLeft: '-10%',
        // backgroundColor: 'red'
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 2
    }),
    clearIndicator: base => ({
        ...base,
        padding: 2
    }),
    multiValue: base => ({
        ...base,
        // backgroundColor: 'red'
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
}
const labelStyle = {
    width: '90%',
    margin: '5px auto',
    fontSize: '16px', 
    textAlign: 'left',
    display: 'block',
    color: 'rgb(49, 150, 136)'
}
const inputStyle = {
    width: '86%', 
    padding: '7px 10px',
    borderRadius: '4px 0 0 4px', 
    border: '1px solid rgba(190, 190, 190, 0.76)'
}
const buttonContainer = {
    display: 'block', 
    width: '90%', 
    textAlign: 'left', 
    margin: '20px auto 0 auto'
}


const mapStateToProps = (state) => ({
    taskCreationCoordinates: state?.mapData?.taskCreationCoordinates ?? '',
    allMapperInfo: state?.mapData?.allMapperInfo ?? [],
  })
  
  
  const mapDispatchToProps = (dispatch) => ({dispatch})
  
  export default connect(mapStateToProps, mapDispatchToProps)(TaskCreateForm);
