import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'; 
import Select from "react-select";

//mui components
import { SearchOutlined, ArrowDropDownCircle } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';

// API Method Call
import { fetchPlaceByUcode, fetchIndividualUserPlace } from '../utils/fetchUtils'

// Actions and Reducers
import { getMapperAddedDataCount } from '../Redux/Actions/mapperAction'
import { setPoints, setRunLoader, setStaticPoints, setRenderSinglePoint, setSingleDataPoint } from '../Redux/Reducers/mapDataReducer';
import { setMapperEndDate, setMapperStartDate, setSelectedMapper } from "../Redux/Reducers/mapperReducer";
import { getGeneratedTaskMessage } from '../Redux/Actions/commonAction'

//Components
import StyledDateRangePicker from './StyledDateRangePicker'
import MapperDropdown from "./common/MapperDropdown";

class MapperPanel extends React.PureComponent {
        state = {
            currentlySelectedNode: null,
            currentlySelectedMapper: null,
            isLoading: false
        }
        userSearchIdRef = React.createRef();

    componentDidMount() {
        const { mapperDataCountList, dispatch } = this.props

        if (mapperDataCountList.length === 0) {
            // fetchng all mapper added data count details
            dispatch(getMapperAddedDataCount())
        }
    }

    componentWillUnmount(){
        const { dispatch } = this.props
        const mapperData = {
            name: '',
            user_id: 0
        }

        dispatch(dispatch => {
            dispatch(setSelectedMapper(mapperData))
            dispatch(setRenderSinglePoint(false))
        })
    }

    //  reload mapper added data count
    _reloadMapperDataCount = () => {
        const { dispatch } = this.props
        dispatch(getMapperAddedDataCount())
    }

    // fetch place by id
    _fetchPlaceByUcode = (e) => {
        const { dispatch } = this.props
        const uCode = this.userSearchIdRef.current.value?.trim();
        dispatch(setRunLoader(true));
        if (uCode) {
            // fetch point's data by uCode
            fetchPlaceByUcode(uCode)
                .then(data => {
                    dispatch(dispatch => {
                        dispatch(setSingleDataPoint([data]))
                        dispatch(setPoints([{mapData: data, message: ''}]))
                        dispatch(setRenderSinglePoint(true))
                        dispatch(setRunLoader(false))
                    })
                   

                })
                .catch(err => {
                    dispatch(setRunLoader(false));
                    console.error(err)
                })

        }

    }

    // storing selected mapper 
    setIndividualuser = (data) => {
        this.setState({
            currentlySelectedMapper: data
        })
    }

     // fetching data of iindividual mapper
    _loadMapperData = () => {
        const { dispatch, mapperStartDate, mapperEndDate } = this.props
        const { currentlySelectedMapper } = this.state

        this.setState({
            isLoading: true
        })

        const params = {
            dateFrom: mapperStartDate,
            dateTill: mapperEndDate
        }

        dispatch(setSingleDataPoint([]))
        // fetching data of iindividual mapper
        fetchIndividualUserPlace(currentlySelectedMapper?.user_id, params)
            .then(res => {
                dispatch(() => {
                    const transformedIntoMessagetemplate = res?.map(item => {
                        const messageTemplate = getGeneratedTaskMessage(item)
                        return {
                            mapData: item,
                            message: messageTemplate
                        }
                    })
                    dispatch(setPoints(transformedIntoMessagetemplate))
                    dispatch(setStaticPoints(res))
                    dispatch(setSelectedMapper(currentlySelectedMapper))
                })
                this.setState({
                    isLoading: false
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isLoading: false
                })
            })
    }

    // get single point data, other data remains as it was. Store in reducer other than points
    // this is to show a single point data in map and open it's custop popup data while other data should also be visible as it was. Therefore, will store data in separated reducer instead of points
    _loadSeparatedSinglePointData = (e, uCode, index) => {
        const { dispatch } = this.props
        const { currentlySelectedNode } = this.state

        if(!uCode){
            return
        }

        dispatch(setRunLoader(true));

        fetchPlaceByUcode(uCode)
            .then(data => {

                dispatch(dispatch => {
                    dispatch(setRenderSinglePoint(true))
                    dispatch(setSingleDataPoint([data]))
                    // dispatch(setStaticPoints([data]))
                    dispatch(setRunLoader(false))
                })
                

            })
            .catch(err => {
                dispatch(setRunLoader(false));
                console.error(err)
            })

        if(currentlySelectedNode){
            currentlySelectedNode.style.backgroundColor = 'white'
        } 

        const domElem = e.target
        domElem.style.backgroundColor = '#c9daea'

        this.setState({
            currentlySelectedNode: domElem
        })
    }

    // update date
    _handleDateChange = (date) => {
        const { dispatch } = this.props
        if(date?.startDate){
            dispatch(setMapperStartDate(date?.startDate))
        }
        else if(date?.endDate){
            dispatch(setMapperEndDate(date?.endDate))
        }
    }

    render() {
        const { mapperDataCountList, totalAddedDataCount, mapperList, selectedMapperID, points, mapperStartDate, mapperEndDate, isLoadingMapperCount } = this.props
        const { currentlySelectedMapper, isLoading } = this.state

        const theme = createTheme({
            components: {
                MuiAccordion: {
                styleOverrides: {
                    root: () => ({
                        width: '95%',
                        margin: '0 auto !important',
                        boxShadow: '0 3px 4px #d4d4d4'
                    })
                }
              }
            }
        })

        return (
            <Box sx={{ minWidth: 120 }} style={{ wordBreak: 'break-word', overflowY: 'scroll', height: `${window.innerHeight-113}px`, paddingBottom: '40px !important'}}>
                <StyledDateRangePicker 
                    loadIndividualMapperData={true}
                    dateRange={{ start: mapperStartDate, end: mapperEndDate }}
                    handleDateChange={ this._handleDateChange }
                />
    
                <div className='filterBy-dropdown' style={{ width: '98%', marginTop: '3px' , display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 2px', border: 'none', boxShadow: 'none' }}>
                    <input placeholder='Search place by uCode' ref={this.userSearchIdRef} type="text" style={{ width: "90%", padding: '4px 10px', borderRadius: '4px 0 0 4px', border: '1px solid rgba(190, 190, 190, 0.76)' }} />
                    <button style={{ backgroundColor: '#202C39', color: 'white', width: '40px', height: '26px', padding: '0 0 0 0', borderRadius: '0 4px 4px 0' }}>< SearchOutlined style={{ cursor: 'pointer' }} onClick={e => this._fetchPlaceByUcode(e)} /></button>
                </div>

                <MapperDropdown
                    setMapper={ this.setIndividualuser }
                    mapperList = { mapperList }
                />
               
                <div className='filterBy-dropdown' style={{ width: '98%', marginTop: '3px' , display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 2px', border: 'none', boxShadow: 'none' }}>
                    <LoadingButton
                            className={ 'toggleBtn' } 
                            variant={'contained'}
                            size={ 'small' }
                            onClick={ this._loadMapperData }
                            loading={ isLoading }
                            sx={ buttonStyle } 
                            disabled={ currentlySelectedMapper?.user_id ? false : true }
                        >
                            Load Mapper Data
                        </LoadingButton>
                </div>
                <hr style={{width: '70%', margin: '10px auto', borderColor: '#e6eef6'}}/>

                <ThemeProvider theme={theme}>
                    <Accordion> 
                        <AccordionSummary
                        expandIcon={<ArrowDropDownCircle />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>
                            Data Added Today
                        </Typography>
                        </AccordionSummary>
                        {
                            mapperDataCountList?.length > 0 ?
                            <AccordionDetails>
                                {/* <Typography> */}
                                <>
                                    <LoadingButton
                                        className={ 'toggleBtn' } 
                                        variant={'contained'}
                                        size={ 'small' }
                                        onClick={ this._reloadMapperDataCount }
                                        loading={ isLoadingMapperCount }
                                        sx={ buttonStyle } 
                                    >
                                        Reload
                                    </LoadingButton>
                                    <ol style={{marginTop: '0px'}}>
                                        {
                                            mapperDataCountList[0]?.state.map(mapper => (
                                                <li style={{ fontSize: '13px' }} key={mapper?.name}>{mapper?.name}: Data: <span style={{color: '#ce3131', fontWeight: 'bold'}}>{mapper?.p_count}</span>, Image: <span style={{color: '#ce3131', fontWeight: 'bold'}}>{mapper?.i_count}</span></li>
                                                ))
                                            }
                                    </ol>
                                    <h5 style={{margin: 0, color: '#38aa80', textAlign: 'center'}}>{`Total Data Count Today : ${totalAddedDataCount}`}</h5>
                                
                                </>
                                {/* </Typography> */}
                            </AccordionDetails>
                            :
                            <div style={{textAlign: 'center'}}>
                                <CircularProgress />
                            </div>

                        }
                       
                    </Accordion>
                </ThemeProvider>
                {   
                    selectedMapperID !== 0 && 
                    <div style={{padding: '10px', height: '100%' }}>
                        { 
                            points?.map((item, index) => (
                                <p 
                                    onClick={(e) => this._loadSeparatedSinglePointData(e, item?.mapData?.uCode, index)} 
                                    value={'item?.uCode'} 
                                    key={ item?.mapData?.uCode } 
                                    style={{
                                        cursor: 'pointer', 
                                        fontSize: '13px', 
                                        borderBottom: '1px solid #939aa0', 
                                        margin: 0, 
                                        padding: '3px 0'
                                    }}
                                >
                                    {
                                        item?.message
                                    }
                                </p>
                            ))
                        }
                    </div>
                }
            </Box>
        )
    }
}


// loading button style
const buttonStyle = {
    width: '100%', 
    height: '28px',
    margin: '10px auto' 
  }


// Props Validation
MapperPanel.propTypes = {
    points: PropTypes.array,
    staticPoints: PropTypes.array,
    mapperDataCountList: PropTypes.array,
    totalAddedDataCount: PropTypes.number,
    selectedMapperID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    mapperList: PropTypes.array,
    isLoadingMapperCount: false,
}

MapperPanel.defaultProps = {
    points: [],
    staticPoints: [],
    mapperDataCountList: [],
    totalAddedDataCount: 0,
    mapperList: [],
    selectedMapperID: 0,
    isLoadingMapperCount: false
}

const mapStateToProps = (state) => ({
    points: state?.mapData?.points ?? [],
    staticPoints: state?.mapData?.staticPoints ?? [],
    mapperDataCountList: state?.mapper?.mapperDataCountList ?? [],
    totalAddedDataCount: state?.mapper?.totalAddedDataCount ?? 0,
    selectedMapperID: state?.mapper?.selectedMapperID ?? 0,
    mapperStartDate: state?.mapper?.mapperStartDate ?? '',
    mapperEndDate: state?.mapper?.mapperEndDate ?? '',
    runLoader: state?.mapData.runLoader ?? false,
    mapperList: state?.mapper?.mapperList ?? [],
    isLoadingMapperCount: state?.mapper?.isLoading,
})

const mapDispatchToProps = dispatch => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(MapperPanel);