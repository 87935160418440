import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    teritories:[],
    routes: [],
    marketNames: [],
}


const retailSlice = createSlice({
    name: 'retail',
    initialState,
    reducers: {
        setRetailTeritories: (state, action) => {
            state.teritories = action.payload
        },
        setRetailRoutes: (state, action) => {
            state.routes = action.payload
        },
        setRetailMarketNames: (state, action) => {
            state.marketNames = action.payload
        } 
    }
})

export const {
    setRetailTeritories, 
    setRetailRoutes,
    setRetailMarketNames,

} = retailSlice.actions
export default retailSlice.reducer