import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


// Component 
import TaskMap from './TaskMap';
import DefaultTaskMap from './DefaultTaskMap';
import TaskCreateForm from './TaskCreateForm'
import {withHOCParam} from './HOC/HOC';

// style
import '../taskStyle.css'

// API's
import {fetchTaskByID} from '../utils/fetchUtils'
import { Button } from '@mui/material';
import { ChangeCircle } from '@mui/icons-material';
import ErrorBoundary from './common/ErrorBoundary';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  
  class UpdateTask extends React.PureComponent {
      constructor(props){
        super(props);
        this.state = {
            currentTask : {},
            editMap: false
        }
        this.toggleEditableMap = this.toggleEditableMap.bind(this)
      }

      toggleEditableMap(){
        this.setState({
            editMap : !this.state.editMap
        })
      }
      
      
    componentDidMount(){
        // params is added by HOC
        const { taskID } = this.props.params
        this._fetchCurrentTaskByID(taskID)
       
    }

    _fetchCurrentTaskByID = (taskId) => {
        // fetching task
        fetchTaskByID(taskId)
        .then(result => {
            this.setState({
                currentTask: result
            })
        })
        .catch(err=> console.error(err))
    }

    // fetching task after previous task is updated so sync with current state
    _fetchCurrentState = () => {
        // params is added by HOC
        const { taskID } = this.props.params
        this._fetchCurrentTaskByID(taskID)

    }

    
    render() {
        const { currentTask, editMap } = this.state

        return (
            <Box sx={{ flexGrow: 1,backgroundColor: '#E8E9EF' }}>
                <Grid container style={{ margin: '0 !important'}} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '5px' }}>
                        <Item>
                            <TaskCreateForm 
                                key={ currentTask.id } 
                                currentTask={ currentTask } 
                                updateCurrentTaskState={ this._fetchCurrentState }
                            />
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '5px' }}>
                        <Item style={{position: 'relative', height: `${window.innerHeight - 110}px`, overflowY: 'hidden', }}>
                            {
                                editMap ? 
                                <ErrorBoundary>
                                    <TaskMap key={ currentTask.id } currentTask={ currentTask }/> 
                                </ErrorBoundary>
                                : 
                                <ErrorBoundary>
                                    <DefaultTaskMap key={ currentTask.id } currentTask={ currentTask }/>
                                </ErrorBoundary>
                            }
                            <Button 
                                variant={ 'contained' }
                                onClick={ this.toggleEditableMap } 
                                startIcon={ <ChangeCircle /> }
                                style={ modeToggler }
                                color={ 'text' }
                                size={ 'small' }
                            >
                                EDIT
                            </Button>
                        </Item>
                    </Grid>
                </Grid>
          </Box>

        )
    }
}

const modeToggler = {
    position: "absolute", 
    top: '30px', 
    right: '35px',
    fontWeight: 'bold',
    backgroundColor: '#ffff',
    border: '1px solid #24242b56',
}

// hos user to add taskID in params 
export default withHOCParam(UpdateTask);
