import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dataPlotter: {},
  mapperList: [],
  mapperDataCountList: [],
  mapperRetailDataCountList: [],
  totalAddedDataCount: 0,
  totalAddedRetailDataCount: 0,
  selectedMapperID: 0,
  selectedMapperName: '',
  mapperStartDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`, // for mapper
  mapperEndDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`,
  isLoading: false,
}

const authSlice = createSlice({
  name: 'mapper',
  initialState,
  reducers: {
    setDataPlotter: (state, action) => {
      state.dataPlotter = action.payload
    },
    setMapperList: (state, action) => {
      state.mapperList = action.payload
    },
    setMapperDataCountList: (state, action) => {
      state.mapperDataCountList = action.payload
    },
    setMapperRetailDataCountList: (state, action) => {
      state.mapperRetailDataCountList = action.payload
    },
    setTotalAddedDataCount: (state, action) => {
      state.totalAddedDataCount = action.payload
    },
    setTotalAddedRetailDataCount: (state, action) => {
      state.totalAddedRetailDataCount = action.payload
    },
    setSelectedMapper: (state, action) => {
      state.selectedMapperID = action.payload.user_id
      state.selectedMapperName = action.payload.name
    },
    setMapperStartDate: (state, action) => {
      state.mapperStartDate = action.payload;
    },
    setMapperEndDate: (state, action) => {
      state.mapperEndDate = action.payload;
    },
    setIsloading: (state, action) => {
      state.isLoading = action.payload
    }
  }
})

export const { setDataPlotter, setMapperList, setMapperDataCountList, setMapperRetailDataCountList, setTotalAddedDataCount, setTotalAddedRetailDataCount, setSelectedMapper, setMapperStartDate, setMapperEndDate, setIsloading } = authSlice.actions
export default authSlice.reducer