import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'


// import components
import ReusableMapGL from '../common/ReusableMapGL'

// import actions and reducers
import { getDivisionPolygon, getDivisionDataCount } from '../../Redux/Actions/overviewActions'
import { Box } from '@mui/system'

class Overview extends PureComponent {
    state={
        stateGeojsonData: null
    }

    componentDidMount(){
        const { dispatch } = this.props

        //fetching polygon of divisions
        dispatch( getDivisionPolygon() )
        dispatch( getDivisionDataCount() )
    }

    componentDidUpdate(prevProps){
        const { stateGeojsonData, statePlygon } = this.state
        const { geojsonData, polygon } = this.props

        if(!_isEqual(statePlygon, polygon)){
            this.setState({
                stateGeojsonData: geojsonData
            })
        }
    }

    render(){
        const { stateGeojsonData, statePlygon } = this.state
        
        return(
            <Box>
                <ReusableMapGL 
                    geojsonProps={ stateGeojsonData }
                />
            </Box>
        )
    }
}

const mapStateToProps = (state) => ({
    geojsonData: state?.overview?.geojsonData ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Overview)