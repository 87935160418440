import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import _isEqual from 'fast-deep-equal'
// import { VariableSizeList as ReactWindowList } from 'react-window'

// styles
import '../App.css'

import StyledDateRangePicker from './StyledDateRangePicker';
import MUIDataTable from 'mui-datatables';

// mui components
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import createCache from '@emotion/cache';
import { Box, CircularProgress } from '@mui/material';

//package library component
import Select from 'react-select';

// Actions and Reducers
import { stackLoadedAndSocketData } from '../Redux/Actions/mapDataActions'
import { setRenderSinglePoint, setRunLoader, setSingleDataPoint } from '../Redux/Reducers/mapDataReducer'
import { setSelectedDistrict, setHomePanelName, setStartDate, setEndDate } from '../Redux/Reducers/commonReducer'
import { fetchPlaceByUcode } from '../utils/fetchUtils'
import ReactWindowList from './common/ReactWindowList';


class LoaderPanel extends React.PureComponent {
    constructor(props){
      super(props);
      this.state = {
          value: 0,
          AllFilteredData: [],
          tableRow: [],
      }
    }

    componentDidMount(){
        const { staticPoints, points, socketDataPoints, selectedDistrict, dispatch } = this.props
        if( points.length > 0 || socketDataPoints.length > 0 ){
            const transformedDataForTable = stackLoadedAndSocketData(points, socketDataPoints, selectedDistrict).messageData
            this.setState({
                tableRow: transformedDataForTable
            })
        }
        this.setState({ AllFilteredData: staticPoints })
        dispatch(setHomePanelName('loader'))
    }
  

    componentDidUpdate(prevProps, prevState){
        const { staticPoints, points, socketDataPoints, selectedDistrict } = this.props
        const { AllFilteredData } = this.state
        if (!_isEqual(staticPoints, AllFilteredData)){
            this.setState({ AllFilteredData: staticPoints })
            }
        if(!_isEqual(prevProps.points, points) || !_isEqual(prevProps.socketDataPoints, socketDataPoints) || !_isEqual(prevProps.selectedDistrict, selectedDistrict)){
            const transformedDataForTable = stackLoadedAndSocketData(points, socketDataPoints, selectedDistrict).messageData
            this.setState({
                tableRow: transformedDataForTable
            })
        }
    }

    componentWillUnmount(){
        const { dispatch } = this.props
        dispatch(setHomePanelName(''))
    }

    // update selected district
    _handleDistrictChange = (e) => {
        const { dispatch } = this.props
        const newSelectedDistrict = e?.value ?? ''
        dispatch(setRunLoader(true))
        dispatch(setSelectedDistrict(newSelectedDistrict))
    }

    // get single point data, other data remains as it was. Store in reducer other than points
    // this is to show a single point data in map and open it's custop popup data while other data should also be visible as it was. Therefore, will store data in separated reducer instead of points
    loadSinglePointForCustomPopup = (uCode) => {
        const { dispatch } = this.props
    
        dispatch(setRunLoader(true))

        fetchPlaceByUcode(uCode)
        .then(data => {

            dispatch(dispatch => {
                dispatch(setRenderSinglePoint(true))
                dispatch(setSingleDataPoint([data]))
                dispatch(setRunLoader(false))
            })

        })
        .catch(err => {
            console.error(err)
            dispatch(setRunLoader(false))
        })
    }


    // update date
    _handleDateChange = (date) => {
        const { dispatch } = this.props
        if(date?.startDate){
            dispatch(setStartDate(date?.startDate))
        }
        else if(date?.endDate){
            dispatch(setEndDate(date?.endDate))
        }
    }

    render (){
        const { selectedDistrict, allDistrict, startDate, endDate } = this.props
        const { tableRow } = this.state

        return (
            <Box sx={{ minWidth: 120 }} style={{ overflowY: 'none', height: `${window.innerHeight-83}px`}}>
             <StyledDateRangePicker
                loadDataBtn={true}
                downloadBtn={true}
                dateRange={{ start: startDate, end: endDate }}
                handleDateChange={ this._handleDateChange }
            />
            <div style={{ display: 'block', width: '100%', padding: '0 10px'}}>
                <label style={label} htmlFor='accepting_deal'>select a district [Currently Selected - <span style={{color: '#f57876', fontWeight: 'bold'}}>{selectedDistrict ? selectedDistrict : 'All'}</span>]</label>
                <Select
                    name='district'
                    labelId='district-label'
                    id='district-label-id'
                    label='District'
                    onChange={this._handleDistrictChange}
                    style={{ display: 'block', width: '90%', padding: '5px'}}
                    options={allDistrict}
                    placeholder={'select a district' }
                    defaultValue={selectedDistrict ? { label: selectedDistrict, value: selectedDistrict} : 'None'}
                    classNamePrefix='select'
                    isClearable={false}
                    styles={customStyles}
                />
            </div>


            <hr style={{width: '60%', margin: '15px auto'}}/>
                <ReactWindowList 
                    tableRow={ tableRow }
                    loadSinglePointForCustomPopup={ this.loadSinglePointForCustomPopup }
                />
          </Box>
        )
    }
}

const label = {
    display: 'inline-block',
    // color: '#2d7b74',
    textAlign: 'left',
    fontSize: '12px',
    paddingLeft: '0'
  }

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 20
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 2
    }),
    clearIndicator: base => ({
        ...base,
        padding: 2
    }),
    multiValue: base => ({
        ...base,
        // backgroundColor: 'red'
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
    })
};

// Prop Types
LoaderPanel.propTypes = {
    points: PropTypes.array,
    staticPoints: PropTypes.array,
    socketDataPoints: PropTypes.array,
    selectedDistrict: PropTypes.string,
    allDistrict: PropTypes.array,
    runLoader: PropTypes.bool,
}
LoaderPanel.defaultProps = {
    points: [],
    staticPoints: [],
    socketDataPoints: [],
    selectedDistrict: '',
    allDistrict: [],
    runLoader: false,
}


const mapStateToProps = (state) => ({
  points: state?.mapData?.points ?? [],
  staticPoints: state?.mapData?.staticPoints ?? [],
  socketDataPoints: state?.mapData?.socketDataPoints ?? [],
  selectedDistrict: state?.common?.selectedDistrict ?? '',
  allDistrict: state?.common?.allDistrict ?? [],
  runLoader: state?.mapData.runLoader ?? false,
  startDate: state?.common?.startDate ?? '',
  endDate: state?.common?.endDate ?? '',
})

const mapDispatchToProps = (dispatch) => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(LoaderPanel);
