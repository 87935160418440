import React, { useEffect } from 'react'
// import { useSelector } from 'react-redux'
import Select from 'react-select'

const MapperDropdown = ({ setMapper, mapperList }) => {
    // const { mapperList } = useSelector(state => state?.mapper)

    useEffect(() => {    
      return () => {
      }
    }, [mapperList])
    
    return(
        <div style={{ display: 'block', width: '95%', margin: '8px auto',  padding: '0', fontSize: '13px'}}>
            <Select
                name='mapper'
                labelId="mapper-label"
                id="mapper-label-id"
                label="Mapper"
                onChange={ setMapper }
                style={{ display: 'block', width: '100%'}}
                options={ mapperList }
                placeholder={'select a Mapper' }
                // defaultValue={selectedDistrict ? { label: selectedDistrict, value: selectedDistrict} : 'None'}
                classNamePrefix="select"
                isClearable={ false }
                styles={ customStyles }
                />
        </div>
    )
}


// react-select component style
const customStyles = {
    control: base => ({
        ...base,
        minHeight: 20
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 2
    }),
    clearIndicator: base => ({
        ...base,
        padding: 2
    }),
    multiValue: base => ({
        ...base,
        // backgroundColor: "red"
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
    })
};

export default MapperDropdown