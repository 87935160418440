import React from 'react';
import _isEqual from 'fast-deep-equal'
import { Box, TextField } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import Select from 'react-select';
import '../App.css'

// API Method Call
import { fetchPlaceByUcode } from '../utils/fetchUtils'

// import actions and reducers
import { setHomePanelName } from '../Redux/Reducers/commonReducer'
import { setSelectedDistrictForFilter, setFilteredData, setFilterStartDate, setFilterEndDate } from '../Redux/Reducers/filterReducer'
import { fetchData } from '../Redux/Actions/mapDataActions'
import { setRunLoader, setRenderSinglePoint, setSingleDataPoint } from '../Redux/Reducers/mapDataReducer'
import { getGeneratedTaskMessage } from '../Redux/Actions/commonAction'

// import components
import StyledDateRangePicker from './StyledDateRangePicker'
import { LoadingButton } from '@mui/lab';

class FilterPanel extends React.PureComponent {
    state={
        selectedStateDistrict: '',
        allDistrictWithoutAllOption: [],
        isLoading: false,
        onStateFilteredData: []
    }

    //keeping track of current tab to show data respective data in MAP 
    componentDidMount(){
        const { dispatch, allDistrict, filteredData } = this.props
        dispatch(setHomePanelName('filter'))

        // discarding all option from district list
        const districtWithoutAllOption = allDistrict?.filter(item => item?.value !== 'All')
        this.setState({
            allDistrictWithoutAllOption: districtWithoutAllOption,
            onStateFilteredData: filteredData
        })

    }

    componentDidUpdate(prevProps, prevState){
        const { filteredData } = this.props

        // if filtered data of props get changed
        if( !_isEqual( prevProps.filteredData, filteredData ) ){
            this.setState({
                onStateFilteredData: filteredData
            })
        }

    }

     //keeping track of current tab by setting homePanelName empty to show data respective data in MAP 
    componentWillUnmount(){
        const {dispatch} = this.props
        dispatch(setFilteredData([]))
        dispatch(setSingleDataPoint([]))
        dispatch(setHomePanelName(''))
    }

    // dispatch changed district
    _handleDistrictChange = (e) => {
        const { dispatch } = this.props
        const newSelectedDistrict = e?.value ?? ''

        this.setState({
            selectedStateDistrict: newSelectedDistrict
        })

        dispatch(setSelectedDistrictForFilter(newSelectedDistrict))
    }

    // load data on filter button click
    _loadData = () => {
        const {dispatch, filterStartDate, filterEndDate } = this.props
        const { selectedStateDistrict } = this.state

        const params = {
            startDate: filterStartDate,
            endDate: filterEndDate
        }
        this.setState({
            isLoading: true
        })

        fetchData(selectedStateDistrict, params)
        .then(res => {
            if(res?.length > 0){
                dispatch(setFilteredData(res))
            }
            this.setState({
                isLoading: false
            })

        })
        .catch(err => {
            console.error(err)
            this.setState({
                isLoading: false
            })
        })
    } 

    // get single point data, other data remains as it was. Store in reducer other than points
    // this is to show a single point data in map and open it's custop popup data while other data should also be visible as it was. Therefore, will store data in separated reducer instead of points
    _loadSeparatedSinglePointData = (e, uCode, index) => {
        const { dispatch } = this.props
        const { currentlySelectedNode } = this.state

        if(!uCode){
            return
        }

        dispatch(setRunLoader(true));

        fetchPlaceByUcode(uCode)
            .then(data => {

                dispatch(dispatch => {
                    dispatch(setRenderSinglePoint(true))
                    dispatch(setSingleDataPoint([data]))
                    // dispatch(setStaticPoints([data]))
                    dispatch(setRunLoader(false))
                })
                

            })
            .catch(err => {
                dispatch(setRunLoader(false));
                console.error(err)
            })

        // unmarking selected list data
        if(currentlySelectedNode){
            currentlySelectedNode.style.backgroundColor = 'white'
        } 

         // marking selected list data
        const domElem = e.target
        domElem.style.backgroundColor = '#c9daea'

        this.setState({
            currentlySelectedNode: domElem
        })
    }

    // update date
    _handleDateChange = (date) => {
        const { dispatch } = this.props
        if(date?.startDate){
            dispatch(setFilterStartDate(date?.startDate))
        }
        else if(date?.endDate){
            dispatch(setFilterEndDate(date?.endDate))
        }
    }

    // search data by keyword
    _onChangeSearch = ( e ) => {
        const { filteredData } = this.props
        const targetValue = e.target.value?.trim()?.toLowerCase()

        // if matched data is found
        const searchedData = filteredData?.filter( item => { 
            for( const [ _, value ] of Object.entries( item ) ){
                if( value && JSON.stringify(value)?.toLowerCase()?.includes( targetValue )){
                    return item
                }
            }   
        })

        this.setState({ 
            onStateFilteredData: searchedData
        })
    }

    render (){
        const { filterStartDate, filterEndDate } = this.props
        const { isLoading, selectedStateDistrict, allDistrictWithoutAllOption, onStateFilteredData } = this.state

        return (
            <Box sx={filterContainerStyle}>
                <div style={{padding: 0}}>
                    <StyledDateRangePicker 
                        filterPanelData={ true }
                        dateRange={{ start: filterStartDate, end: filterEndDate }}
                        handleDateChange={ this._handleDateChange }
                    />
                </div>
                <div style={dateInputContainerStyle}>
                    <div style={dateItem}>
                        <label style={label} htmlFor='retail_size'>District:</label>
                        <Select
                            name={ 'retail_size' }
                            options={ allDistrictWithoutAllOption }
                            classNamePrefix={ 'select' }
                            onChange={ this._handleDistrictChange }
                            isClearable={ true }
                            styles={ customStyles }
                        />
                    </div>
                    <div style={{...dateItem, width: '100%'}}>
                        <LoadingButton
                            className={ 'toggleBtn' } 
                            variant={'contained'}
                            size={ 'small' }
                            onClick={ this._loadData }
                            loading={ isLoading }
                            sx={ buttonStyle } 
                            disabled={ selectedStateDistrict ? false : true }
                        >
                            Filter
                        </LoadingButton>
                    </div>
                    <div style={{ ...dateItem, width: '100%' }}>
                        <TextField
                            onChange={ this._onChangeSearch } 
                            label={ 'Search by keyword' } 
                            variant={ 'outlined' } 
                            fullWidth={ true }
                            size={ 'small' }
                            sx={{ fontSize: '12px !important', lineHeight: '16px', zIndex: 0 }}
                        />
                    </div>
                </div>

                {
                    <div style={{ textAlign: 'center' }}>
                        <p>Total Data Count: <span>{ onStateFilteredData?.length ?? 0 }</span></p>
                    </div>
                }
                {   onStateFilteredData?.length > 0 &&
                    <div style={{padding: '10px 0 30px 0', height: '100%'}}>
                        { 
                            onStateFilteredData?.map(( item, index ) => (
                                <p 
                                    onClick={ ( e ) => this._loadSeparatedSinglePointData( e, item?.uCode, index )} 
                                    value={ 'item?.uCode' } 
                                    key={ item?.uCode } 
                                    style={{
                                        cursor: 'pointer', 
                                        fontSize: '13px', 
                                        borderBottom: '1px solid #939aa0', 
                                        margin: 0, 
                                        padding: '3px 0',
                                        lineHeight: '17px'
                                    }}
                                >
                                    {
                                        getGeneratedTaskMessage( item )
                                    }
                                </p>
                            ))
                        }
                    </div>
                }
         
          </Box>
        )
    }
}

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 20,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 2
    }),
    clearIndicator: base => ({
        ...base,
        padding: 2
    }),
    multiValue: base => ({
        ...base,
        // backgroundColor: 'red'
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};


const filterContainerStyle = {
    overflowY: 'auto', 
    height:'100%', 
    maxHeight: `${window.innerHeight - 110}px`, 
    minWidth: '120px',  
    padding: '15px 15px 50px 15px', 
    fontSize: '16px',
    wordBreak: 'break-word',
}

const dateInputContainerStyle = {
  width: '100%',
  padding: '0',
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  marginTop: '5px'
}
const dateItem = {
  width: '50%',
  paddingRight: '5px'
}

const label = {
  display: 'inline-block',
  textAlign: 'left',
  fontSize: '12px',
  paddingLeft: '1px',
}

const buttonStyle = {
  width: '100%', 
  height: '28px',
  margin: '10px auto' 
}


// Prop Types
FilterPanel.propTypes = {
    points: PropTypes.array,
    filteredData: PropTypes.array,
    allDistrict: PropTypes.array,
}
FilterPanel.defaultProps = {
    points: [],
    filteredData: [],
    allDistrict: [],
}

const mapStateToProps = (state) => ({
    points: state?.mapData?.points ?? [],
    filteredData: state?.filter?.filteredData ?? '',
    allDistrict: state?.common?.allDistrict ?? [],
    filterStartDate: state?.filter?.filterStartDate ?? '',
    filterEndDate: state?.filter?.filterEndDate ?? '',
    startDate: state?.common?.startDate ?? '',
    endDate: state?.common?.endDate ?? '',
})
const mapDispatchToProps = (dispatch) => ({dispatch})
  
  export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);