// import React from 'react';
import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Component 
import TaskMap from './TaskMap';
import TaskCreateForm from './TaskCreateForm'
import ErrorBoundary from './common/ErrorBoundary'

// style
import '../taskStyle.css'




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


class TaskWindow extends React.PureComponent {

    render() {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container style={{height: '100%', margin: '0 !important'}} >
                    <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                        <Item sx={{ padding: 1.5 }}>
                            <Paper elevation={ 3 }>
                                    <ErrorBoundary>
                                        <TaskMap />
                                    </ErrorBoundary>
                            </Paper>
                        </Item>
                    </Grid>
                    <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                        <Item sx={{ padding: 1.5 }}>
                            <Paper elevation={ 2 }>
                                <TaskCreateForm  />
                            </Paper>
                        </Item>
                    </Grid>
                </Grid>
          </Box>

        )
    }
}


export default TaskWindow;
