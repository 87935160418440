import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'; 
import Select from "react-select";

//mui components
import { SearchOutlined, ArrowDropDownCircle } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';

// API Method Call
import { fetchPlaceByUcode, fetchIndividualUserPlace, fetchRetailPlaceByUcode, fetchIndividualUserRetailPlace, fetchCnlPlaceByUcode } from '../utils/fetchUtils'

// Actions and Reducers
import {  getMapperAddedDataCount, getMapperAddedRetailDataCount } from '../Redux/Actions/mapperAction'
import { setPoints, setRunLoader, setStaticPoints, setRenderSinglePoint, setSingleDataPoint } from '../Redux/Reducers/mapDataReducer';
import { setMapperEndDate, setMapperStartDate, setSelectedMapper } from "../Redux/Reducers/mapperReducer";
import { getGeneratedTaskMessage, getGeneratedTaskMessageCnl } from '../Redux/Actions/commonAction'

//Components
import StyledDateRangePicker from './StyledDateRangePicker'
import MapperDropdown from "./common/MapperDropdown";
import { fetchIndividualUserCnlData, getAllMapperInfoCnl, getMarketOpportunities, getOutletCategories, getOutletTypes } from "../Redux/Actions/cnlActions";
import { setAddedShopCount, setUpdatedShopCount } from "../Redux/Reducers/cnlReducer";

class MapperPanelCnl extends React.PureComponent {
        state = {
            currentlySelectedNode: null,
            currentlySelectedMapper: null,
            startDateType: 0,
            isLoading: false
        }
        userSearchIdRef = React.createRef();

    componentDidMount() {
        const { mapperRetailDataCountList, dispatch } = this.props

        if (mapperRetailDataCountList.length === 0) {
            // fetchng all mapper added data count details
            dispatch(getMapperAddedRetailDataCount())
        }
        dispatch(getAllMapperInfoCnl())      
    }

    componentWillUnmount(){
        const { dispatch } = this.props
        const mapperData = {
            name: '',
            user_id: 0
        }

        dispatch(dispatch => {
            dispatch(setSelectedMapper(mapperData))
            dispatch(setRenderSinglePoint(false))
            dispatch(setAddedShopCount(0))
            dispatch(setUpdatedShopCount(0))
        })
    }

    // // fetch place by id
    // _fetchRetailPlaceByUcode = (e) => {
    //     const { dispatch } = this.props
    //     const uCode = this.userSearchIdRef.current.value?.trim();
    //     dispatch(setRunLoader(true));
    //     if (uCode) {
    //         // fetch point's data by uCode
    //         fetchRetailPlaceByUcode(uCode)
    //             .then(data => {
    //                 dispatch(dispatch => {
    //                     dispatch(setSingleDataPoint([data]))
    //                     dispatch(setPoints([{mapData: data, message: ''}]))
    //                     dispatch(setRenderSinglePoint(true))
    //                     dispatch(setRunLoader(false))
    //                 })
    //             })
    //             .catch(err => {
    //                 dispatch(setRunLoader(false));
    //                 console.error(err)
    //             })

    //     }

    // }

    // storing selected mapper 
    setIndividualuser = (data) => {
        this.setState({
            currentlySelectedMapper: data
        })
    }

     // fetching data of iindividual mapper
    _loadMapperData = () => {
        const { dispatch, mapperStartDate, mapperEndDate  } = this.props
        const { currentlySelectedMapper, startDateType } = this.state

        this.setState({
            isLoading: true
        })

        const params = {
            dateFrom: mapperStartDate,
            dateTill: mapperEndDate,
            include_filter_by_created_at: startDateType,
        }

        dispatch(setSingleDataPoint([]))
        // fetching data of iindividual mapper
        fetchIndividualUserCnlData(currentlySelectedMapper?.user_id, params)
            .then(res => {
                dispatch(() => {
                    const transformedIntoMessagetemplate = res?.map(item => {
                        const messageTemplate = getGeneratedTaskMessageCnl(item)
                        return {
                            mapData: item,
                            message: messageTemplate
                        }
                    })
                    dispatch(setPoints(transformedIntoMessagetemplate))
                    dispatch(setStaticPoints(res))
                    // this._createLayer(res)
                    dispatch(setSelectedMapper(currentlySelectedMapper))
                })
                this.setState({
                    isLoading: false
                })
            })
            .catch(err => {
                console.error(err)
                this.setState({
                    isLoading: false
                })
            })
        }

    // get single point data, other data remains as it was. Store in reducer other than points
    // this is to show a single point data in map and open it's custop popup data while other data should also be visible as it was. Therefore, will store data in separated reducer instead of points
    _loadSeparatedSinglePointData = (e, uCode, index) => {
        const { dispatch } = this.props
        const { currentlySelectedNode } = this.state

        if(!uCode){
            return
        }

        dispatch(setRunLoader(true));

        fetchCnlPlaceByUcode(uCode)
            .then(data => {

                dispatch(dispatch => {
                    dispatch(setRenderSinglePoint(true))
                    dispatch(setSingleDataPoint([data]))
                    // dispatch(setStaticPoints([data]))
                    dispatch(setRunLoader(false))
                })
                

            })
            .catch(err => {
                dispatch(setRunLoader(false));
                console.error(err)
            })

        if(currentlySelectedNode){
            currentlySelectedNode.style.backgroundColor = 'white'
        } 

        const domElem = e.target
        domElem.style.backgroundColor = '#c9daea'

        this.setState({
            currentlySelectedNode: domElem
        })
    }

    // update date
    _handleDateChange = (date) => {
        const { dispatch } = this.props
        if(date?.startDate){
            dispatch(setMapperStartDate(date?.startDate))
        }
        else if(date?.endDate){
            dispatch(setMapperEndDate(date?.endDate))
        }
    }

    // start date type - created date or updated date
    _handleStartDateType = (e) => {
        let startDateType = e.value
        console.log("startDateType name", startDateType);
        this.setState({ startDateType })
    }

    render() {
        const { mapperRetailDataCountList, totalAddedRetailDataCount, mapperList, selectedMapperID, points, mapperStartDate, mapperEndDate } = this.props
        const { currentlySelectedMapper, isLoading } = this.state

        const theme = createTheme({
            components: {
                MuiAccordion: {
                styleOverrides: {
                    root: () => ({
                        width: '95%',
                        margin: '0 auto !important',
                        boxShadow: '0 3px 4px #d4d4d4'
                    })
                }
              }
            }
        })

        return (
            <Box sx={{ minWidth: 120 }} style={{ wordBreak: 'break-word', overflowY: 'scroll', height: `${window.innerHeight-113}px`, paddingBottom: '40px !important'}}>
                <StyledDateRangePicker 
                    loadIndividualMapperData={true}
                    dateRange={{ start: mapperStartDate, end: mapperEndDate }}
                    handleDateChange={ this._handleDateChange }
                />
    
                {/* <div className='filterBy-dropdown' style={{ width: '98%', marginTop: '3px' , display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 2px', border: 'none', boxShadow: 'none' }}>
                    <input placeholder='Search place by id' ref={this.userSearchIdRef} type="text" style={{ width: "90%", padding: '4px 10px', borderRadius: '4px 0 0 4px', border: '1px solid rgba(190, 190, 190, 0.76)' }} />
                    <button style={{ backgroundColor: '#202C39', color: 'white', width: '40px', height: '26px', padding: '0 0 0 0', borderRadius: '0 4px 4px 0' }}>< SearchOutlined style={{ cursor: 'pointer' }} onClick={e => this._fetchRetailPlaceByUcode(e)} /></button>
                </div> */}
                 <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "5px"}}>
                    <Typography ml={ 1 } mt={ 1 } fontSize={ 13 } variant={ 'body1' } htmlFor="outlet_categories">Start Date Type -  </Typography>
                    <Select
                        name='startDateType'
                        id='startDateType'
                        options={[
                            {
                                label: "Add Date",
                                value: 1
                            },
                            {   label: "Update Date",
                                value: 0
                            }
                        ]}
                        // label='outletCategories'
                        onChange={this._handleStartDateType}
                        placeholder="Start Date Type"
                        defaultValue={
                                {   label: "Update Date",
                                    value: 0
                                }
                            }
                        isClearable={false}
                    />

                </Box>

                <MapperDropdown
                    setMapper={ this.setIndividualuser }
                    mapperList = { mapperList }
                />
               
                <div className='filterBy-dropdown' style={{ width: '98%', marginTop: '3px' , display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 2px', border: 'none', boxShadow: 'none' }}>
                    <LoadingButton
                            className={ 'toggleBtn' } 
                            variant={'contained'}
                            size={ 'small' }
                            onClick={ this._loadMapperData }
                            loading={ isLoading }
                            sx={ buttonStyle } 
                            disabled={ currentlySelectedMapper?.user_id ? false : true }
                        >
                            Load Mapper Data
                        </LoadingButton>
                </div>
                <hr style={{width: '70%', margin: '10px auto', borderColor: '#e6eef6'}}/>

                {/* <ThemeProvider theme={theme}>
                    <Accordion> 
                        <AccordionSummary
                        expandIcon={<ArrowDropDownCircle />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography variant={'body1'} sx={{fontWeight: 'bold'}}>Data Added Today</Typography>
                        </AccordionSummary>
                        {
                            mapperRetailDataCountList?.length > 0 ?
                            <AccordionDetails>
                             
                                    <ol style={{marginTop: '0px'}}>
                                        {
                                            mapperRetailDataCountList[0]?.state.map(mapper => (
                                                <li style={{ fontSize: '13px' }} key={mapper?.name}>{mapper?.name}: Data: <span style={{color: '#ce3131', fontWeight: 'bold'}}>{mapper?.p_count}</span>, Image: <span style={{color: '#ce3131', fontWeight: 'bold'}}>{mapper?.i_count}</span></li>
                                                ))
                                            }
                                    </ol>
                                    <h5 style={{margin: 0, color: '#38aa80', textAlign: 'center'}}>{`Total Data Count Today : ${totalAddedRetailDataCount}`}</h5>
                             
                            </AccordionDetails>
                            :
                            <div style={{textAlign: 'center'}}>
                                <CircularProgress />
                            </div>

                        }
                       
                    </Accordion>
                </ThemeProvider> */}
                {   
                    selectedMapperID !== 0 && 
                    <div style={{padding: '10px', height: '100%' }}>
                        { 
                            points?.map((item, index) => (
                                <p 
                                    onClick={(e) => this._loadSeparatedSinglePointData(e, item?.mapData?.id, index)} 
                                    value={'item?.uCode'} 
                                    key={ item?.mapData?.id } 
                                    style={{
                                        cursor: 'pointer', 
                                        fontSize: '13px', 
                                        borderBottom: '1px solid #939aa0', 
                                        margin: 0, 
                                        padding: '3px 0'
                                    }}
                                >
                                    {
                                        item?.message
                                    }
                                </p>
                            ))
                        }
                    </div>
                }
            </Box>
        )
    }
}


// loading button style
const buttonStyle = {
    width: '100%', 
    height: '28px',
    margin: '10px auto' 
  }


// Props Validation
MapperPanelCnl.propTypes = {
    points: PropTypes.array,
    staticPoints: PropTypes.array,
    mapperRetailDataCountList: PropTypes.array,
    totalAddedRetailDataCount: PropTypes.number,
    selectedMapperID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    MapperList: PropTypes.array,
}

MapperPanelCnl.defaultProps = {
    points: [],
    staticPoints: [],

    mapperRetailDataCountList: [],
    totalAddedRetailDataCount: 0,
    mapperList: [],
    selectedMapperID: 0
}

const mapStateToProps = (state) => ({
    points: state?.mapData?.points ?? [],
    staticPoints: state?.mapData?.staticPoints ?? [],
    mapperDataCountList: state?.mapper?.mapperDataCountList ?? [],
    mapperRetailDataCountList: state?.mapper?.mapperRetailDataCountList ?? [],
    totalAddedRetailDataCount: state?.mapper?.totalAddedRetailDataCount ?? 0,
    selectedMapperID: state?.mapper?.selectedMapperID ?? 0,
    mapperStartDate: state?.mapper?.mapperStartDate ?? '',
    mapperEndDate: state?.mapper?.mapperEndDate ?? '',
    runLoader: state?.mapData.runLoader ?? false,
    mapperList: state?.cnl?.cnlMapperList ?? [],

})

const mapDispatchToProps = dispatch => ({dispatch})

export default connect(mapStateToProps, mapDispatchToProps)(MapperPanelCnl);