import axios from 'axios'
import { RETAIL } from '../../App.config'
import { setRetailMarketNames, setRetailRoutes, setRetailTeritories } from '../Reducers/retailReducer'

// get all mapper information
const getFilterOptions = () => {
    return dispatch => {
        let url = RETAIL.GET_TERRITORIES

        axios.get( url )
        .then(res =>{
            if(res?.status === 200){
                console.log("Filter Options", res.data.data);

                 // setup territories
                 let territories = res?.data?.data?.territory
                 let territoriesArr = []
 
                 territories.map((item) => {
                     territoriesArr.push({
                         value: item?.territory_name, 
                         label: item?.territory_name
                     });
                 })
                dispatch(setRetailTeritories(territoriesArr))

                // // setup routes
                // let routes = res?.data?.data?.route
                // let routesArr = []

                // routes.map((item) => {
                //     routesArr.push({
                //         value: item?.route_name, 
                //         label: item?.route_name
                //     });
                // })
                // dispatch(setRetailRoutes(routesArr))

                // // setup market
                // let market = res?.data?.data?.market
                // let marketArr = []

                // market.map((item) => {
                //     marketArr.push({
                //         value: item?.market_name, 
                //         label: item?.market_name
                //     });
                // })
                // dispatch(setRetailMarketNames(marketArr))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}

// get routes by territory
const getRoutesByTerritory = (territory) => {
    return dispatch => {
        let url = `${RETAIL.GET_ROUTE_BY_TERRITORY}?territory_name=${territory}`

        axios.get( url )
        .then(res =>{
            if(res?.status === 200){
                // setup routes
                let routes = res?.data?.data
                let routesArr = []

                routes.map((item) => {
                    routesArr.push({
                        value: item?.route_name, 
                        label: item?.route_name
                    });
                })
                dispatch(setRetailRoutes(routesArr))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}

// get routes by territory
const getMarketsByRoute = (route) => {
    return dispatch => {
        let url = `${RETAIL.GET_MARKET_BY_ROUTE}?route_name=${route}`

        axios.get( url )
        .then(res =>{
            if(res?.status === 200){
                // setup market
                let market = res?.data?.data
                let marketArr = []

                market.map((item) => {
                    marketArr.push({
                        value: item?.market_name, 
                        label: item?.market_name
                    });
                })
                dispatch(setRetailMarketNames(marketArr))
            }
        })
        .catch(err =>{
            console.error(err)
        })
    }
}



export { getFilterOptions, getRoutesByTerritory, getMarketsByRoute }