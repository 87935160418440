import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  isValidating: false,
  userEmail: '',
  password: '',
  token: null,
  user: {},
  error: '',
  shouldSocketReopenOnError: true
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload
    },
    setIsValidating: (state, action) => {
      state.isValidating = action.payload
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload
    },
    setPassword: (state, action) => {
      state.password = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setShouldSocketReopenOnError: (state, action) => {
      state.shouldSocketReopenOnError = action.payload
    }
  }
})

export const { setIsAuthenticated, setIsValidating, setUserEmail, setPassword, setToken, setUser, setError, setShouldSocketReopenOnError } = authSlice.actions
export default authSlice.reducer