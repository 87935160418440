import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// icons 
import HomeIcon from '@mui/icons-material/Home';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ViewListIcon from '@mui/icons-material/ViewList';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { AccountCircle, Analytics, QueryStats } from '@mui/icons-material';



export default function Sidebar(props) {
  const [drawerState, setDrawerState] = React.useState({
    left: false,
  });



  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{padding: '0 10px' ,color: '#319688'}}
    >
      <List style={ STYLE.userLogo }>
        <AccountCircle 
          fontSize={ 'large' }
        />
        Mapper Web
      </List>
      <Divider />

      <List >
        <div>
          <Link style={{color: '#383838', textDecoration: 'none'}} to='/'>
              <li style={{display: 'flex', alignItems: 'center'}} onClick={props.clickToJump} className='navLinks'><HomeIcon style={{padding: '0 5px 0 0'}} />Home</li>
            </Link>
        </div>
      </List>

      <List >
        <div>
          <Link style={{color: '#383838', textDecoration: 'none'}} to='/taskCreationWindow'>
              <li style={{display: 'flex', alignItems: 'center'}} onClick={props.clickToJump} className='navLinks'><PlaylistAddIcon />Task Creation</li>
            </Link>
        </div>
      </List>

      <List >
        <div>
          <Link style={{color: '#383838', textDecoration: 'none'}} to='/taskList'>
              <li style={{display: 'flex', alignItems: 'center'}} onClick={props.clickToJump} className='navLinks'><ViewListIcon />Task List</li>
            </Link>
        </div>
      </List>

      <List >
        <div>
          <Link style={{color: '#383838', textDecoration: 'none'}} to='/mapperActions'>
              <li style={{display: 'flex', alignItems: 'center'}} onClick={props.clickToJump} className='navLinks'><HowToRegIcon />Mapper Actions</li>
            </Link>
        </div>
      </List>

      <List >
        <div>
          <Link style={{color: '#383838', textDecoration: 'none'}} to='/overview'>
              <li style={{display: 'flex', alignItems: 'center'}} onClick={props.clickToJump} className='navLinks'><Analytics />Overview</li>
            </Link>
        </div>
      </List>

      <List >
        <div>
          <Link style={{color: '#383838', textDecoration: 'none'}} to='/retail'>
              <li style={{display: 'flex', alignItems: 'center'}} onClick={props.clickToJump} className='navLinks'><Analytics />Olympic</li>
            </Link>
        </div>
      </List>

      <List >
        <div>
          <Link style={{color: '#383838', textDecoration: 'none'}} to='/cnl'>
              <li style={{display: 'flex', alignItems: 'center'}} onClick={props.clickToJump} className='navLinks'><Analytics />CNL</li>
            </Link>
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button style={{position: 'absolute', left: '0',top: '0', zIndex: '1000' , width: '75px', height: '63px', backgroundColor: '#202C39', color: '#319688', fontWeight: 'bold', borderRadius: '0 5px 5px 0'}} onClick={toggleDrawer(anchor, true)}><MenuOpenIcon style={{fontSize: '40px'}}></MenuOpenIcon></Button>
          <Drawer
            anchor={anchor}
            open={drawerState[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

const STYLE = {
  userLogo: { 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    cursor: 'pointer', 
    fontWeight: 'bold', 
    padding: '15px 15px 15px 5px'
  }
}
