import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

// import components
import { Button } from '@mui/material';

//import redux
import { setPoints, setRunLoader, setStaticPoints } from '../Redux/Reducers/mapDataReducer'
import { downloadReport, fetchCnlData, fetchData, fetchRetailData } from '../Redux/Actions/mapDataActions'
import { getGeneratedTaskMessage, getGeneratedTaskMessageCnl } from '../Redux/Actions/commonAction'

import '../App.css'
import { PAGES } from '../App.config';

class StyledDateRangePicker extends React.PureComponent {
  state = {
    defaultStartDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`, // for loader
    defaultEndDate: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`, // for loader
  }
  
  // Fetching data - for loader map
  _callDataApi = fetchDataByRange => {
    const { startDate, endDate, dispatch, pageName } = this.props

    const params = {
      startDate,
      endDate
    }

    dispatch(setRunLoader(true));


    if(pageName === PAGES.RETAIL){
      //loads data from retail API if in retail page, else from common mapper API

      fetchRetailData(null, params)
      .then(data => {

        const transformedIntoMessagetemplate = data?.map(item => { 
          const messageTemplate = getGeneratedTaskMessage(item)
          return {
              mapData: item,
              message: messageTemplate
          }
      })

      // console.log("");
        // dispatching data
        dispatch(dispatch => {
          dispatch(setPoints(transformedIntoMessagetemplate))
          dispatch(setStaticPoints(data))
          dispatch(setRunLoader(false))
      })
    })
    .catch(err => console.error(err))
  }
  else if(pageName === PAGES.CNL){
    fetchCnlData(params)
    .then(data => {
      const transformedIntoMessagetemplate = data?.map(item => { 
        const messageTemplate = getGeneratedTaskMessageCnl(item)
        return {
            mapData: item,
            message: messageTemplate
        }
    })
      // dispatching data
      dispatch(dispatch => {
        dispatch(setPoints(transformedIntoMessagetemplate))
        dispatch(setStaticPoints(data))
        dispatch(setRunLoader(false))
    })
  })
  .catch(err => console.error(err))

  }else{
      fetchData(null, params)
      .then(data => {
        const transformedIntoMessagetemplate = data?.map(item => { 
          const messageTemplate = getGeneratedTaskMessage(item)
          return {
              mapData: item,
              message: messageTemplate
          }
      })
        // dispatching data
        dispatch(dispatch => {
          dispatch(setPoints(transformedIntoMessagetemplate))
          dispatch(setStaticPoints(data))
          dispatch(setRunLoader(false))
      })
    })
    .catch(err => console.error(err))
  }
    }




  _getDataCount = () => {
    
  }

  
 // setting start data
  _changeStartDate(e){
    const { handleDateChange } = this.props
    const start = e.target.value;

    handleDateChange({startDate: start})
  }

  // setting end date 
  _changeEndDate(e){
    const { handleDateChange } = this.props
    const end = e.target.value;

    handleDateChange({endDate: end})  
  }

  // downloading data by date range
  _downloadData = () =>{
    const { startDate, endDate } = this.props

    downloadReport(startDate, endDate)
  }
  

  render() {
    const { dateRange, downloadBtn, loadDataBtn, loadIndividualMapperData } = this.props
    const { defaultStartDate, defaultEndDate } = this.state

    return (
      <div> 
        {/* <h3 >Load Data By Range</h3> */}
        
        <div style={dateInputContainerStyle}>
          <div style={dateItem}>
            <label style={label} htmlFor="start">Start date:</label>
            <input style={dropdownInputStyle} onChange={(e) => this._changeStartDate(e)} type="date" id="start" name="trip-start"
                value={ dateRange?.start ? dateRange.start : defaultStartDate }
                min="2017-01-01" max="2050-12-31" />
          </div>
          <div style={dateItem}>
            <label style={label} htmlFor="start">End date:</label>
            <input style={dropdownInputStyle} onChange={e => this._changeEndDate(e)} type="date" id="start" name="trip-start"
                value={ dateRange?.end ? dateRange.end : defaultEndDate }
                min="2017-01-01" max="2050-12-31" />
          </div>
        </div>
        <div style={dateInputContainerStyle}>
          {
            downloadBtn &&
            <Button 
              variant={'outlined'} 
              onClick={this._downloadData}
              sx={ (!loadDataBtn && !loadIndividualMapperData) ? buttonStyle : {...buttonStyle, width: '100%'}}
            >
              Downlaod
            </Button>
          }
          {
            loadDataBtn &&
            <Button 
              variant={'contained'} 
              onClick={this._callDataApi}
              size={'small'}
              sx={ (!downloadBtn && !loadIndividualMapperData) ? buttonStyle : {...buttonStyle, width: '100%'}}
            >
              Load Data
            </Button>
          }
        </div>
      </div>   
    )
  }
}

const dateInputContainerStyle = {
  width: '100%',
  padding: '0 10px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '5px'
}
const dateItem = {
  width: '100%',
}

const label = {
  display: 'inline-block',
  textAlign: 'left',
  fontSize: '12px',
  paddingLeft: '1px',
}

const dropdownInputStyle = {
  display: 'block', 
  width: '100%',
  padding: '3px 10px', 
  borderRadius: '4px', 
  border: '1px solid rgba(190, 190, 190, 0.76)',
  boxShadow: '2px 2px 3px rgba(27, 27, 27, 0.151)'
}

const buttonStyle = {
  width: '70%', 
  height: '28px',
  margin: '5px auto' 
}

// Prop Types
StyledDateRangePicker.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  mapperStartDate: PropTypes.string,
  mapperEndDate: PropTypes.string,
  downloadBtn: PropTypes.bool,
  loadDataBtn: PropTypes.bool,
  loadIndividualMapperData: PropTypes.bool,
  filterPanelData: PropTypes.bool,
  showUpdateLogLoader: PropTypes.bool,
  dateRange: PropTypes.object,
  handleDateChange: PropTypes.func,
  // points: []
}

StyledDateRangePicker.defaultProps = {
  // value: [],
  // onChange: () => null
  downloadBtn: false,
  loadDataBtn: false,
  loadIndividualMapperData: false,
  filterPanelData: false,
  showUpdateLogLoader: false,
  dateRange: null,
  handleDateChange: () => null
}

const mapStateToProps = (state) => ({
  startDate: state?.common?.startDate ?? '',
  endDate: state?.common?.endDate ?? '',
  pageName: state?.common?.pageName ?? '',
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(StyledDateRangePicker)