import React from "react";
import Select from "react-select";
import _isEqual from 'fast-deep-equal'
import '../App.css'

import { updatePointData, updatePointDataRetail } from '../Redux/Actions/mapDataActions'
import { Alert, AlertTitle, Typography, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Cancel, DeleteOutline, Send } from "@mui/icons-material";
import { BASE_API, BUYEROPTIONS, TRADELISENCEOPTIONS } from "../App.config";
import { Button } from "bootstrap";


class UpdateDataRetail extends React.PureComponent {

  

        state = {
            // 
            currentData: '',
            pTypeOption: [],
            subTypeOption: [],
            allSubTypeOption: [],
            currentSubTypeOption: [],
            // currentPtype: this.props.info.info.pType
            currentPtype: '',
            currentSubType: '',
            currentCustomerType: '',
            marketAccess: '',
            currentHatDay: '',
            showPTypeSubTypeWarning: false,
            isButtonLoading: false,
        }

        componentDidMount(){
            const { info, pTypeOptionProp, allSubTypeOptionProp, currentSubTypeOptionProp } = this.props
            // const { pTypeOption } = this.state

            // Fetching all pType
            this.setState({currentData: info})

            this.setState({
                pTypeOption: pTypeOptionProp,
            })

            
            
            this.setState({
                allSubTypeOption: allSubTypeOptionProp,
                currentSubTypeOption: currentSubTypeOptionProp
                
            })
            // Fetching all sub type
        }

        componentDidUpdate(prevProps, prevState){
            const { currentSubTypeOptionProp, pTypeOptionProp } = this.props
            const { allSubTypeOption, currentData, currentSubTypeOption } = this.state
            
            let subTypeOptionArray = []

            //if currentSubtype option for selected pType changes
            if(!_isEqual(prevProps.currentSubTypeOptionProp, currentSubTypeOptionProp)){
                this.setState({
                    currentSubTypeOption: currentSubTypeOptionProp
                    
                })
            }

            // if pType option list updates
            if(!_isEqual(prevProps.pTypeOptionProp, pTypeOptionProp)){
                this.setState({
                    pTypeOption: pTypeOptionProp,
                })
            }
            
            // if pType is empty setting subtype options empty
            if(currentSubTypeOption?.length !== prevState.currentSubTypeOption?.length && (!currentData.pType || currentData?.pType?.length < 1)){
                this.setState({
                    currentSubTypeOption: []
                })
            }

            // checkingif pType gets changed, relavent subtype options is shown in dropdown
            if(prevState.currentData?.pType !== currentData?.pType){

                const pTypeArray = currentData.pType.slice().split(',');

                allSubTypeOption.forEach((subItem)=>(
                    pTypeArray.forEach(pTypeItem=> (
                        subItem.type === pTypeItem ? subTypeOptionArray.push({value: subItem.subtype, label: subItem.subtype }) : ''
                    ))
                ))

                this.setState({
                    currentSubTypeOption: subTypeOptionArray
                })
               
            }

        }




        // checking is there are any selected value for target pType in subtype dropdown field
        validatePTypeSubType(){
            const { currentSubTypeOption, currentData } = this.state

            const currentlySelectedSybType = currentData?.subType?.split(',')

            // matching hoy many selected subtype is matched with existing pType optipn
            let matchedSubtypeCount = 0

            currentlySelectedSybType?.forEach((option) => {
                const isFound = currentSubTypeOption?.find((item) => (item?.label === option.trim()))

                if(isFound){
                   ++matchedSubtypeCount
                }

            })


            // if length is equal meaning all subtype have respective pType.
            return currentlySelectedSybType?.length === matchedSubtypeCount
        }

        // setting pType 
        _setPtype = (option, property) => {
    
            const propertyName = property.name;

            let optionValue = '';


            option.forEach(item=> (
            
                item.value === 'clear' ? optionValue = '' : optionValue += item.value +',' 
                
            )) 

            const optionValueWithoutEndComma = optionValue.substring(0,optionValue.length-1)

            this.setState({
                currentData: {
                    ...this.state.currentData,
                    [propertyName]:  optionValueWithoutEndComma
                }
            })    

        }

        _setSubType = (option, property) => {
           
            const propertyName = property.name;

            let optionValue = '';

            option.map(item=> (
              
                item.value === 'clear' ? optionValue = '' : optionValue += item.value +","
                
            )) 

            const optionValueWithoutEndComma = optionValue.substring(0,optionValue.length-1)

            this.setState({
                currentData: {
                    ...this.state.currentData,
                    [propertyName]:  optionValueWithoutEndComma
                }
            })

        }

        _setIsBuyer = (option, property) => {
            const propertyName = property.name;
            const value = option.value;

            this.setState({
                currentData: {
                    ...this.state.currentData,
                    [propertyName]:  value
                }
            })
        }

        _setIsTradeLicence = (option, property) => {
            const propertyName = property.name;
            const value = option.value;

            this.setState({
                currentData: {
                    ...this.state.currentData,
                    [propertyName]:  value
                }
            })
        }


        // post point's update form data
        _postUpdatedData = async (e) => { 
            e.preventDefault();
            this.setState({
                isButtonLoading: true
            })

            // checking if seleted subtypes have respective pType
            const shouldUpdate = this.validatePTypeSubType()

            // checking if there is any subtype that do not has selected pType in pType dropdown field
            if(!shouldUpdate){
                this.setState({
                    showPTypeSubTypeWarning: true,
                    isButtonLoading: false
                })

                return
            }

            const {info, popupToast, updateInfo, dataUpdatePanelToggler } = this.props
            const { currentData } = this.state
            const {uCode} = info
            
            const params = {
                //** */ mandatory to update
                latitude: info.latitude,
                longitude: info.longitude,

                //** */ optinal field
                business_name: currentData.business_name,
                holding_number: currentData.holding_number,
                place_name: currentData.place_name,
                road_name_number: currentData.road_name_number,
                district: currentData.district,
                sub_district: currentData.sub_district,
                union: currentData.union,
                thana: currentData.thana,
                area: currentData.area,
                sub_area: currentData.sub_area,
                super_sub_area: currentData.super_sub_area,
                postCode: currentData.postCode,
                pType: currentData.pType,
                subType: currentData.subType,

                //** */ only in retail field 
                company_name: currentData.company_name,
                zone_name: currentData.zone_name,
                territory_name: currentData.territory_name,
                route_name: currentData.route_name,
                market_name: currentData.market_name,

                // shop_name: currentData.shop_name,
                // shop_type: currentData.shop_type,
                category_of_outlet: currentData.category_of_outlet,
                market_opportunity: currentData.market_opportunity,
                owner_name: currentData.owner_name,
                owner_contact: currentData.owner_contact,
                most_sold_item: currentData.most_sold_item,
                most_expensive_item: currentData.most_expensive_item,

                is_buyer: currentData.is_buyer,
                is_trade_licence: currentData.is_trade_licence,
                daily_monthly_sale: currentData.daily_monthly_sale,

            }
      
            // updating point data
            updatePointDataRetail(uCode, params)
            .then(res=> {
                if(res?.status !== 200){
                    // stop loading button from loading state
                    this.setState({
                        isButtonLoading: false
                    })
                    popupToast(true)

                    return
                }

                // stop loading button from loading state
                this.setState({
                    isButtonLoading: false
                })
                
                popupToast(true)
                updateInfo(currentData)
                dataUpdatePanelToggler()

                this.setState({
                    showPTypeSubTypeWarning: false
                })
            })
            .catch(err => {
                popupToast(false)
                this.setState({
                    isButtonLoading: false
                })
                console.error(err)
            })
        }
        

        // Update data of all input field excep dropdown
        _updateCurrentDataState = (property, event) => {

            this.setState(prevState => ({
                currentData: {
                    ...this.state.currentData,
                    [property]: event.target.value
                }

            }))
        }


        render(){
            const { currentData, currentSubTypeOption, pTypeOption, showPTypeSubTypeWarning, isButtonLoading } = this.state
            return (
                <div>
                    <form action="" method="">
                        {/* Retail */}
                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Company Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('company_name', e)} type="text" name='shop-name' value={ currentData.company_name ?  currentData.company_name : ''} />

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Zone Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('zone_name', e)} type="text" name='shop-name' value={ currentData.zone_name ?  currentData.zone_name : ''} />
           
                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Territory Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('territory_name', e)} type="text" name='shop-name' value={ currentData.territory_name ?  currentData.territory_name : ''} />

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Market Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('market_name', e)} type="text" name='shop-name' value={ currentData.market_name ?  currentData.market_name : ''} />


                        {/* Common */}
                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Business Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('business_name', e)} type="text" name='shop-name' value={ currentData.business_name ?  currentData.business_name : ''} />
                        
                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">House No :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('holding_number', e)} type="text" name='holding-number' value={ currentData.holding_number ?  currentData.holding_number : ''} />
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="shop-name">Road no / name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('road_name_number', e)} type="text" name='road-name-number' value={ currentData.road_name_number ?  currentData.road_name_number : ''} />
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="shop-name">Place Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('place_name', e)} type="text" name='place-name' value={ currentData.place_name ?  currentData.place_name : ''} />
                       
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="district">District :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('district', e)} type="text" name='district' value={ currentData.district ? currentData.district : ''} placeholder="District"/>
                       
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="sub-district">Sub District :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('sub_district', e)} type="text" name='sub-district' value={ currentData.sub_district ? currentData.sub_district : ''} placeholder="Sub District"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="union">Union :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('union', e)} type="text" name='union' value={ currentData.union ? currentData.union : ''} placeholder="Union"/>

                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="thana">Thana :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('thana', e)} type="text" name='thana' value={ currentData.thana ? currentData.thana : ''} placeholder="Thana"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="area">Area :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('area', e)} type="text" name='area' value={ currentData.area ? currentData.area : ''} placeholder="Area"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="sub-area">Sub Area :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('sub_area', e)} type="text" name='sub-area' value={ currentData.sub_area ? currentData.sub_area : ''} placeholder="Sub Area"/>
                        
                        <Typography ml={ 1 }  htmlFor="super-sub-area">Super Sub Area</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('super_sub_area', e)} type="text" name='super-sub-area' value={ currentData.super_sub_area ? currentData.super_sub_area : ''} placeholder="Super Sub Area"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="postCode">Post Code :</Typography>
                        <input  className='updateInput' onChange={e => this._updateCurrentDataState('postCode', e)} type="text" name='postCode' value={ currentData.postCode ? currentData.postCode : ''} placeholder="Post Code"/>
                        
                        <Typography ml={ 1 }  variant={ 'body1' } htmlFor="ptype">Place Type :</Typography>
                        <Select
                            isMulti
                            name="pType"
                            options={ pTypeOption }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this._setPtype}
                            isClearable={false}
                            value={currentData?.pType?.split(',')?.map(item => ( item ? {label: item, value: item} : '')) ?? ''}

                        />
                        

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="subtype">Sub Type :</Typography>
                        <Select
                            isMulti
                            name="subType"
                            options={ currentSubTypeOption }
                            isClearable={true}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this._setSubType}
                            value={currentData?.subType?.split(',')?.map(item => ( item ? {label: item.trim(), value: item.trim()} : '')) ?? ''}
                        />

                        {/* Retail */}
                        {/* <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Shop Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('shop_name', e)} type="text" name='shop-name' value={ currentData.shop_name ?  currentData.shop_name : ''} />
                        
                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Shop Type :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('shop_type', e)} type="text" name='shop-name' value={ currentData.shop_type ?  currentData.shop_type : ''} /> */}

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Category of Outlet :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('category_of_outlet', e)} type="text" name='shop-name' value={ currentData.category_of_outlet ?  currentData.category_of_outlet : ''} />

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Market Opportunity :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('market_opportunity', e)} type="text" name='shop-name' value={ currentData.market_opportunity ?  currentData.market_opportunity : ''} />

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Owner Name :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('owner_name', e)} type="text" name='shop-name' value={ currentData.owner_name ?  currentData.owner_name : ''} />

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Owner Contact :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('owner_contact', e)} type="text" name='shop-name' value={ currentData.owner_contact ?  currentData.owner_contact : ''} />
 
                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Most Sold Item :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('most_sold_item', e)} type="text" name='shop-name' value={ currentData.most_sold_item ?  currentData.most_sold_item : ''} />

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Most Expensive Item :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('most_expensive_item', e)} type="text" name='shop-name' value={ currentData.most_expensive_item ?  currentData.most_expensive_item : ''} />
  
                        {/* <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Buyer :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('is_buyer', e)} type="text" name='shop-name' value={ currentData.is_buyer ?  currentData.is_buyer : ''} /> */}

                        <Typography ml={ 1 } variant={ 'body1' } htmlFor="shop-name">Is Buyer :</Typography>
                        <Select
                            name='is_buyer'
                            id='is_buyer'
                            options={BUYEROPTIONS}
                            label='Is Buyer'
                            onChange={this._setIsBuyer}
                            placeholder={(currentData.is_buyer === 1)? "Buyer" : "Non Buyer" }
                            defaultValue={(currentData.is_buyer === 1)? "Buyer" : "Non Buyer" }
                            isClearable={false}
                        />

                        <Typography ml={ 1 } mt={ 1 } variant={ 'body1' } htmlFor="shop-name">Trade Licence :</Typography>
                        <Select
                            name='is_trade_licence'
                            id='is_trade_licence'
                            options={TRADELISENCEOPTIONS}
                            label='Is Trade Licence'
                            onChange={this._setIsBuyer}
                            placeholder={(currentData.is_trade_licence === 1)? "Yes" : "No" }
                            defaultValue={(currentData.is_trade_licence === 1)? "Yes" : "No" }
                            isClearable={false}
                        />

                        <Typography ml={ 1 } mt={ 1 } variant={ 'body1' } htmlFor="shop-name">Monthly Sale :</Typography>
                        <input className='updateInput'  onChange={e => this._updateCurrentDataState('daily_monthly_sale', e)} type="text" name='shop-name' value={ currentData.daily_monthly_sale ?  currentData.daily_monthly_sale : ''} />


                        <div style={ STYLES.buttonContainer }>
                            <LoadingButton
                                variant={'contained'}
                                size={ 'small' }
                                loading={ isButtonLoading }
                                onClick={this._postUpdatedData}
                                sx={ STYLES.buttonStyle }
                                color={ 'text' }
                                endIcon={<Send />}
                                disabled={ currentSubTypeOption?.length <= 0 }
                            >
                                Update
                            </LoadingButton>
                            <LoadingButton
                                variant={'contained'}
                                size={ 'small' }
                                // loading={ isLoading }
                                startIcon={<Cancel />}
                                onClick={this.props.dataUpdatePanelToggler}
                                sx={ STYLES.buttonStyle }
                                color={ 'text' }
                            >
                                Close
                            </LoadingButton>
                            {/* <Button  variant='outlined' disabled={ currentSubTypeOption?.length <= 0 }  sx={STYLES.buttonStyle} onClick={this._postUpdatedData} >Submit</Button> */}
                            {/* <Button  variant='outlined'  sx={STYLES.buttonStyle} onClick={this.props.dataUpdatePanelToggler} value='Close Update Panel'>Close Update Panel</Button> */}
                        </div> 

                        {
                            showPTypeSubTypeWarning &&
                            <Alert severity="error" sx={{color: '#9b0a0a'}}>
                                <AlertTitle>Warning</AlertTitle>
                                All sub-type and their respective p-type must be selected 
                            </Alert>
                        }

                    </form>
                    <Divider sx={{ margin: '20px auto', width: '90%' }}/>
                    {/* <div style={STYLES.imageContainer}>
                        {currentData?.images ? 
                            currentData.images.map((item, index)=>       
                            <div key={item?.id} 
                                style={{display: "flex", flexDirection: 'column'}}>

                                {   
                                //  for mapper and reatail api - image_link
                                item.image_link ?    
                                    <img 
                                    onClick={(e)=> this._showImageModal(e, `${BASE_API}/${item?.image_link}`)}
                                    // key={item?.id} 
                                    style={{display: 'block', borderRadius: '5px', padding: '3px'}} width='120px' height='80px' 
                                    src={`${BASE_API}/${item?.image_link}`} 
                                    alt='shop'  
                                    /> 
                                : 
                                    // for cnl api - image_url
                                    <img 
                                        onClick={(e)=> this._showImageModal(e, `${item?.image_url}`)}
                                        // key={item?.id} 
                                        style={{display: 'block', borderRadius: '5px', padding: '3px'}} width='120px' height='80px' 
                                        src={`${item?.image_url}`} 
                                        alt='shop'  
                                    /> 
                                }
                                <Button 
                                    // variant={ 'contained' }
                                    // color={ 'text' } 
                                    // key={item?.id} 
                                    size={ 'small' }
                                    // style={ buttonStyle } 
                                    value={item?.id}
                                    onClick={(e)=> this._handleDeleteImg(e, item?.id, index)}
                                    // onClick={(e) => this._handleDeleteImg(e, item?.id)}
                                    // onChange={this._handleDeleteImg}
                                    startIcon={ <DeleteOutline /> }
                                >
                                </Button>
                                
                            </div>  
                                ) 
                                : ''}
              </div> */}
                </div>
            )
        }
}

const STYLES = {
    buttonContainer: { 
        width: '100%', 
        display: "flex", 
        alignItems: 'center', 
        margin: '20px auto',
    },
    buttonStyle: {
        padding: '4px 20px',
        margin: '0 5px 0 0',
        border: '1px solid #24242b56',
        borderRadius: '5px',
        fontWeight: 'bold',
        // color: '#202c39',
        backgroundColor: '#ffff',
        // boxShadow: '4px 4px 5px rgba(27, 27, 27, 0.151)'
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '40px'
    }
}

export default UpdateDataRetail;