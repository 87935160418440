import { PreviewOutlined } from '@mui/icons-material';
import { Divider, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { useWindowResize } from './useWindowResize';

// creating Row component of data 
const Row = ({ data, index, setSize, windowWidth, loadSinglePointForCustomPopup, selectedUcode, setSelectedUcode }) => {
  const rowRef = useRef()

  // setting the row item height. since the row item takes dynamic height in DOM.
  useEffect(() => {
    setSize(index, rowRef.current.getBoundingClientRect()?.height)
  }, [setSize, index, windowWidth]);

  const onClickListHandler = (e, uCode) => {
    setSelectedUcode( uCode )
    loadSinglePointForCustomPopup( uCode )
  }

  var parser = new DOMParser()

  return (
    <div
      ref={rowRef}
      style={{ 
        ...styles.row,
        display:  data[index]?.Message ? 'flex' : 'block',
        backgroundColor: (selectedUcode && selectedUcode === data[index]?.uCode) ? '#c5c5c5' : data[index]?.Message ? 'transparent' : '#bbd2ff',
        alignItems: 'center',
        cursor: 'text'

       }}
    > 
          { 
           data[index]?.Message ? data[index]?.Message  :  <p style={{ fontWeight: 'bolder', textAlign: 'center' }}>------------- DATA DIVIDER -------------</p>
          }

          <Divider orientation={ 'vertical' } flexItem/>
          {
            (data[index]?.uCode) &&
           <Tooltip title={ 'Open Details' }>
              <PreviewOutlined 
                color={ 'primary' }
                sx={{ cursor: 'pointer' }}
                onClick={ (e) => onClickListHandler(e, data[index]?.uCode) }
              /> 
          </Tooltip>
          }
    </div>
  );
};

const ReactWindowList = ({ tableRow, loadSinglePointForCustomPopup }) => {
  const listRef = useRef()
  const sizeMap = useRef({})
  const [ selectedUcode, setSelectedUcode ] = useState(null)
  
  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    listRef.current.resetAfterIndex(index)
  }, []);
  const getSize = index => sizeMap.current[index] || 50;
  const [windowWidth] = useWindowResize()

  return (
    <List
      ref={ listRef }
      height={ window.innerHeight-280 } 
      width={ '100%' } 
      itemCount={ tableRow.length }
      itemSize={ getSize }
      itemData={ tableRow }
    > 
      {({ data, index, style }) => (
        <div style={style}> 
          <Row
            data={data}
            index={index}
            setSize={setSize}
            windowWidth={windowWidth}
            loadSinglePointForCustomPopup={ loadSinglePointForCustomPopup }
            selectedUcode={ selectedUcode }
            setSelectedUcode={ setSelectedUcode }
          />
        </div>
      )}
    </List>
  );  
} 

const styles = {
  row: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '12.5px',
    padding: '5px 10px',
    boxSizing: 'border-box',
    borderBottom: '1px solid #222',
  },

};


export default ReactWindowList