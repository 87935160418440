// import { getAuthToken } from './authActions'
import { SOCKET } from '../../App.config'
import { setSocketDataPoints, setSocketRetailDataPoints, setUpdateDataSocketResponse } from '../Reducers/mapDataReducer'
import { getGeneratedTaskMessage } from '../Actions/commonAction'


// Activate Socket
export function activateSocket() {
  return (dispatch, getState) => {    

    // removing existing local storage data
    localStorage.removeItem('pusherTransportTLS')
    localStorage.removeItem('pusherTransportNonTLS')

    const {shouldSocketReopenOnError} = getState().auth

    // Get Auth Token
    // const token = getAuthToken()

     // // pusher logger
     window.Pusher.logToConsole = false

     window.pusher = new window.Pusher(SOCKET.API_KEY, {
       cluster: SOCKET.CLUSTER,
       wsHost: SOCKET.WS_HOST,
       wsPort: SOCKET.WS_PORT,
       wssPort: SOCKET.WSS_PORT,
       forceTLS: true, 
       enabledTransports: ['ws', 'wss'],
       useTLS: true,
     })
 
     // tracing socket status and state
     window.pusher.connection.bind('error', function (error) {
        console.error('[connection error FOUND]', error);
          deactivateSocket()

          setTimeout(() => {
            connectSocketAgain()
          }, 5000)
      })
      .bind("state_change", function (states) {
      //  states = {previous: 'oldState', current: 'newState'}
      if(shouldSocketReopenOnError && states?.current === 'disconnected'){
         console.error({ states})
         console.error('[CONNECTION DISCONNECTED]')
        connectSocketAgain()
        }
       if(shouldSocketReopenOnError && states?.current === 'failed'){
          console.error({ states})
          console.error('YOUR BROWSER HAS SOME COMPATIABILITY ISSUE WITH SOCKET DATA')
          connectSocketAgain()
        }
       if(shouldSocketReopenOnError && states?.current === 'unavailable'){
          console.error({ states})
          console.error('YOUR COMPUTER WERE IDLE OR IN SLEEP MODE. IF NOT CHECK YOUR INTERNET CONNECTION')
          connectSocketAgain()
        }
     })
    
    // Task Channel
    window.pusher.subscribe(SOCKET.RETAIL_ADD_PLACE_CHANNEL)
      .bind(SOCKET.RETAIL_ADD_PLACE_EVENT, data => {
        const transformIntoMessageTemplate = getGeneratedTaskMessage(data?.data)

        const prevSocketData = getState().mapData.socketDataPoints
        const newData = [...prevSocketData]
        newData.unshift({
          mapData: data?.data,
          message: transformIntoMessageTemplate
        }) 
        // Add Socket Data To Redux State
        dispatch( setSocketDataPoints(newData) )
      })
      .bind(SOCKET.RETAIL_UPDATE_PLACE_EVENT, updateData => {

        const prevSocketData = getState().mapData.updateDataSocketResponse
        const prevSockedDataCopy = [...prevSocketData]
        prevSockedDataCopy?.length > 0 ? prevSockedDataCopy.unshift(updateData) : prevSockedDataCopy.push(updateData)
        
        // // Add Socket Data To Redux State
        dispatch( setUpdateDataSocketResponse(prevSockedDataCopy))
      })
      .bind(SOCKET.ONLY_RETAIL_ADD_PLACE_EVENT, data => {
        const transformIntoMessageTemplate = getGeneratedTaskMessage(data?.data)

        const prevSocketData = getState().mapData.socketRetailDataPoints
        const newData = [...prevSocketData]
        newData.unshift({
          mapData: data?.data,
          message: transformIntoMessageTemplate
        }) 
        // Add Socket Data To Redux State
        dispatch( setSocketRetailDataPoints(newData) )
      })
    }
}

// connect to socket again
const connectSocketAgain = () => {
  activateSocket()
}

// Deactivate Socket
export function deactivateSocket() {
  return () => {
    if(window.pusher) {
      window.pusher.unsubscribe(SOCKET.RETAIL_ADD_PLACE_CHANNEL)
      delete window.pusher
    }
  }
}
