import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    taskList: []
}

const taskDataSlice = createSlice({
    name: 'taskData',
    initialState,
    reducers: {
        setTaskList: (state, action) => {
            state.taskList = action.payload
        }
    }
})

export const { setTaskList } = taskDataSlice.actions
export default taskDataSlice.reducer