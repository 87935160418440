import React from 'react';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// custom components
import MapGL from './MapGL';
import InformationCnl from './InformationCnl';

// Import Actions & Reducers
import { activateSocket, deactivateSocket } from '../Redux/Actions/socketAction'
import { setShouldSocketReopenOnError } from '../Redux/Reducers/authReducer'
import { setPageName } from '../Redux/Reducers/commonReducer';

// custom styles
import '../App.css'
import ErrorBoundary from './common/ErrorBoundary';
import { PAGES } from '../App.config';





class CnlWindow extends React.PureComponent {

    componentDidMount(){
        const {dispatch} = this.props

        // clearing existing pusherLocalStorage Data
        localStorage.removeItem('pusherTransportTLS')
        localStorage.removeItem('pusherTransportNonTLS')

        dispatch(dispatch => (
            dispatch(setShouldSocketReopenOnError(true)),
            dispatch(activateSocket())
        ))

        dispatch(setPageName(PAGES.CNL)); 
    }

    componentDidUpdate(prevProps, prevState){
        const {dispatch, pageName} = this.props
        //  set page name on navbar
        if(prevProps.pageName !== pageName){
            dispatch(setPageName(PAGES.CNL)); 
        }
    }

    componentWillUnmount(){
        const { dispatch } = this.props 

        dispatch(dispatch => (
            dispatch(setShouldSocketReopenOnError(false)),
            dispatch(deactivateSocket())
        ))
    }


    render() {
        return (
            <div style={{ overflow: 'hidden', position: 'relative'}}>
                
                
                <Box sx={{ flexGrow: 1, }}>
                    <Grid container spacing={2}>
                        <Grid  item xs={6}>
                            <ErrorBoundary>
                                <MapGL />
                            </ErrorBoundary>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={infoContainerStyle}>
                                <div style={{position: 'absolute', right: '0'}}> 
                                    <InformationCnl />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>

        )
    }
}

const infoContainerStyle = {
    height: `${window.innerHeight - 64}px`,   
    width: '100vw',
    zIndex: 100,
    margin: 0,
    padding: 0,
}

CnlWindow.propTypes = {
    dispatch: PropTypes.func
}

CnlWindow.defaultProps = {
    dispatch: () => null
}



// const mapStateToProps = state => {

// }

const mapStateToProps = (state) => ({
    pageName: state?.common.pageName ?? '',
})

const mapDispatchToProps = dispatch => ({dispatch})


export default connect(mapStateToProps, mapDispatchToProps)(CnlWindow);
